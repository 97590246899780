import React, { memo } from 'react';
import { Button, CircularProgress, SxProps } from '@mui/material';
import { Typography } from '@components/index';
import { FontWeight, DarkGrey, Primary, LightGrey, Spacing } from '@style/theme';

export interface TabButtonProps {
    /**
     * button content
     */
    label?: string | React.ReactNode;
    /**
     * Optional click handler
     */
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    /**
     * Icon at the start of the button
     */
    startIcon?: React.ReactNode;
    /**
     * Icon at the end of the button
     */
    endIcon?: React.ReactNode;
    /**
     * Optional, is the button selected
     */
    selected?: boolean;
    /**
     * Optional, is the button loading
     */
    loading?: boolean;
    /**
     * Optional, is the button disabled
     */
    disabled?: boolean;
    /**
     * Optional, custom styling
     */
    muiStyle?: SxProps;
}

const defaultStyle = (selected: boolean) => {
    return {
        color: selected ? Primary.MEDIUM : DarkGrey.MEDIUM,
        borderColor: selected ? Primary.MEDIUM : LightGrey.DARK,

        '& svg path': {
            fill: selected ? Primary.MEDIUM : DarkGrey.MEDIUM,
        },

        '&:not([disabled]):hover': {
            borderColor: selected ? Primary.MEDIUM : LightGrey.DARK,
        },

        '&:disabled': {
            color: LightGrey.DARK,

            '& svg path': {
                fill: LightGrey.DARK,
            },
        },
    };
};

export default memo(function TabButton({
    label = 'Button',
    onClick = () => {},
    startIcon,
    endIcon,
    selected = false,
    loading = false,
    disabled = false,
    muiStyle,
}: TabButtonProps) {
    return (
        <Button
            disabled={loading || disabled}
            onClick={onClick}
            startIcon={startIcon}
            endIcon={endIcon}
            sx={{
                ...defaultStyle(selected),
                ...muiStyle,
            }}
        >
            {loading && <CircularProgress size={18} sx={{ color: Primary.DARK, position: 'absolute' }} />}

            <Typography variant="body1" weight={FontWeight.BOLD} muiStyle={{ my: 0, mx: Spacing.SM }}>
                {label}
            </Typography>
        </Button>
    );
});
