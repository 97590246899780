import MainContent from '@layout/MainContent/MainContent.component';
import { useCallback, useState } from 'react';
import GridLayoutDemo from './GridLayout.demo';

export default function GridLayoutPage() {
    const [layout, setLayout] = useState<any[]>([]);

    const onLayoutChange = useCallback((layout: any, layouts?: any) => {
        setLayout(layout);
    }, []);

    const stringifyLayout = useCallback(() => {
        return layout.map(function (l) {
            return (
                <div className="layoutItem" key={l.i}>
                    <b>{l.i}</b>: [{l.x}, {l.y}, {l.w}, {l.h}]
                </div>
            );
        });
    }, [layout]);

    return (
        <MainContent>
            <div className="layoutJSON">
                Displayed as <code>[x, y, w, h]</code>:<div className="columns">{stringifyLayout()}</div>
            </div>
            <GridLayoutDemo onLayoutChange={onLayoutChange} />
        </MainContent>
    );
}
