import { Sizes } from '@data/types';

export const IconSize = {
    xs: 16,
    sm: 20,
    md: 32,
    lg: 48,
};

export const getIconSize = (size: Sizes) => {
    switch (size) {
        case 'xs':
            return {
                width: 16,
                minWidth: 16,
                height: 16,
            };
        case 'sm':
            return {
                width: 24,
                minWidth: 24,
                height: 24,
            };
        case 'md':
            return {
                width: 32,
                minWidth: 32,
                height: 32,
            };
        case 'lg':
            return {
                width: 48,
                minWidth: 48,
                height: 48,
            };
        default:
            return {
                width: 24,
                minWidth: 24,
                height: 24,
            };
    }
};
