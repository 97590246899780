import { IMemberModel } from '@models/IMemberModel';

export enum UsersActions {
    SET_DESIGNERS = 'SET_DESIGNERS',
    SET_CLIENTS = 'SET_CLIENTS',
}

export const setDesigners = async (dispatch: any, data: IMemberModel) => {
    dispatch({
        type: UsersActions.SET_DESIGNERS,
        payload: { data },
    });
};

export const setClients = async (dispatch: any, data: IMemberModel) => {
    dispatch({
        type: UsersActions.SET_CLIENTS,
        payload: { data },
    });
};
