import { HTMLAttributes, useEffect, memo, useCallback } from 'react';
import { Box, SxProps, Tooltip } from '@mui/material';
import { Typography } from '@components/index';
import { FontWeight, DarkGrey, LightGrey, Spacing, BorderRadius } from '@style/theme';
import { FlexCenter, FlexColumn } from '@components/wrappers/Flex/Flex.wrapper';
import { FONTS_FILETYPE, AUDIO_FILETYPE, IMAGES_FILETYPE } from '@data/defaults';
import { OutlineFont, OutlineHeadphone, OutlineFileCheck } from '@assets/icons/index';
import { useDrop } from '@hooks/useDrop';
import { Accept, useDropzone } from 'react-dropzone';
import { IFileRequestModel } from '@models/IFileModel';
import Label from '@components/wrappers/Label/Label.wrapper';
import Image from '@components/common/Image/Image.component';

const styles = {
    root: {},
    container: {
        justifyContent: 'center',

        minWidth: 280,
        padding: Spacing.MD,

        boxSizing: 'border-box',
        border: `2px dashed ${LightGrey.DARK}`,
    },
    thumbsContainer: {
        minWidth: 0,
        marginBottom: Spacing.MD,

        flexWrap: 'wrap',
        overflow: 'hidden',
    },
    thumbs: {
        position: 'relative',

        width: 84,
        height: 84,
        margin: Spacing.XXS,

        border: `2px solid ${LightGrey.DARK}`,
        borderRadius: BorderRadius.XXS,
        boxSizing: 'border-box',

        cursor: 'pointer',
        overflow: 'hidden',

        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain',
        },
    },
};

export interface DropzoneProps extends HTMLAttributes<HTMLInputElement> {
    /**
     * Setting files to be uploaded
     */
    setFiles?: (files: IFileRequestModel[]) => void;
    /**
     * Label of the input field
     */
    label?: string;
    /**
     * Width of the input field
     */
    width?: number | string;
    /**
     * Width of the input field
     */
    acceptedFileType?: 'img' | 'font' | 'music' | 'all';
    /**
     * Copy to clipboard
     */
    copyToClipboard?: boolean;
    /**
     * Custom CSS styling
     */
    muiStyle?: SxProps;
}

const getIconType = (ext: string | undefined) => {
    const extension = ext ?? '';
    if (FONTS_FILETYPE.includes(extension)) return <OutlineFont />;
    if (AUDIO_FILETYPE.includes(extension)) return <OutlineHeadphone />;
    if (IMAGES_FILETYPE.includes(extension)) return <OutlineFileCheck />;
    return <OutlineFileCheck />;
};

export default memo(function Dropzone({
    setFiles,
    label,
    width = 280,
    acceptedFileType = 'all',
    copyToClipboard = false,
    muiStyle,
}: DropzoneProps) {
    const { files, onDrop, onPaste, removeFile } = useDrop();

    const accept = useCallback((filter: 'all' | 'img' | 'music' | 'font') => {
        let accept: Accept | string[] = {};

        const files = {
            images: IMAGES_FILETYPE.map(image => `.${image}`),
            audios: AUDIO_FILETYPE.map(audio => `.${audio}`),
            fonts: FONTS_FILETYPE.map(font => `.${font}`),
        };

        switch (filter) {
            case 'img':
                accept = { 'image/*': files.images };
                break;
            case 'music':
                accept = { 'audio/*': files.audios };
                break;
            case 'font':
                accept = { 'text/*': files.fonts };
                break;
            case 'all':
            default:
                accept = {};
                break;
        }

        return accept;
    }, []);

    const { getRootProps, getInputProps, open } = useDropzone({
        accept: accept(acceptedFileType),
        onDrop,
    });

    useEffect(() => {
        if (setFiles) setFiles(files);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    useEffect(() => {
        if (copyToClipboard) {
            document.addEventListener('paste', onPaste);

            return () => document.removeEventListener('paste', onPaste);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const thumbs = files?.map((file, i) => {
        const ext = file?.file_name?.split('.').pop();

        return (
            <Tooltip title={file.file_name} placement="bottom" arrow key={i}>
                <FlexCenter sx={{ ...styles.thumbs }} onClick={() => removeFile(file)}>
                    {IMAGES_FILETYPE.includes(ext!) ? <Image src={file.file_url!} /> : getIconType(ext)}
                </FlexCenter>
            </Tooltip>
        );
    });

    useEffect(() => () => files?.forEach(file => URL.revokeObjectURL(file.file_url!)), [files]);

    return (
        <Box sx={{ ...styles.root }}>
            <Label label={label}>
                <FlexColumn sx={{ ...styles.container }} width={width}>
                    {thumbs && files.length > 0 && <FlexCenter sx={{ ...styles.thumbsContainer }}>{thumbs}</FlexCenter>}

                    <Box {...getRootProps({ className: 'dropzone' })} onClick={e => e.stopPropagation}>
                        <input {...getInputProps()} />
                        <Typography alignment="center" weight={FontWeight.BOLD} color={DarkGrey.MEDIUM} onClick={open}>
                            Drag and Drop or <span style={{ color: '#0000FF' }}>Browse</span>
                        </Typography>
                    </Box>
                </FlexColumn>
            </Label>
        </Box>
    );
});
