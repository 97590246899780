import { brandkitApi } from '@api/modules/apiBrandkit';
import { fileApi } from '@api/modules/apiFile';
import { Toast } from '@components/index';
import { IValueLabelPair } from '@data/interfaces';
import getFormData from '@helper/getFormData';
import { IBrandkitModel } from '@models/IBrandkitModel';
import { IFileRequestCollectionModel } from '@models/IFileModel';
import { ITask } from '@models/ITaskModel';
import { IUserModel } from '@models/IUserModel';
import { SelectedBrandkitActions } from '@store/selected-brandkit/selected-brandkit.action';

export enum BrandkitActions {
    SET_BRANDKIT = 'SET_BRANDKIT',
    ADD_BRANDKIT = 'ADD_BRANDKIT',
    UPDATE_BRANDKIT = 'UPDATE_BRANDKIT',
    DELETE_BRANDKIT = 'DELETE_BRANDKIT',
}

export const setUserBrandkits = async (dispatch: any, user: IUserModel) => {
    const brandkitsResponse =
        user.is_client && user.user_team
            ? await brandkitApi.getClientBrandkit(user.user_team)
            : await brandkitApi.getDesignerBrandkit();

    const filteredBrandkits = user.is_staff
        ? brandkitsResponse?.data.filter((brandkit: IBrandkitModel) => brandkit.creator_is_staff)
        : brandkitsResponse?.data.filter((brandkit: IBrandkitModel) => !brandkit.creator_is_staff);

    dispatch({ type: BrandkitActions.SET_BRANDKIT, payload: { data: filteredBrandkits } });
};

export const loadSelectedBrandkit = async (dispatch: any, navigate: any, id: number) => {
    await brandkitApi
        .getBrandkit(id)
        .then(response => {
            dispatch({
                type: SelectedBrandkitActions.SET_SELECTED_BRANDKIT,
                payload: { data: { ...response.data } },
            });
        })
        .catch(() => {
            Toast.error({ message: `No brandkit found with id: ${id}` });

            navigate('/tasks');
        });
};

export const setSelectedBrandkit = async (dispatch: any, brandkit: IBrandkitModel) => {
    dispatch({
        type: SelectedBrandkitActions.SET_SELECTED_BRANDKIT,
        payload: { data: { ...brandkit } },
    });
};

export const clearSelectedBrandkit = async (dispatch: any) => {
    dispatch({
        type: SelectedBrandkitActions.SET_SELECTED_BRANDKIT,
        payload: { data: null },
    });
};

export const upsertBrandkit = async (
    dispatch: any,
    navigate: any,
    mode: 'add' | 'edit',
    id: number,
    brandkit: IBrandkitModel,
    files: IFileRequestCollectionModel,
): Promise<boolean> => {
    const brandkitResponse =
        mode === 'add'
            ? await brandkitApi.createBrandkit(id, { ...brandkit })
            : await brandkitApi.updateBrandkit(id, { ...brandkit });

    if (brandkitResponse.data) {
        if (files.logo?.length > 0) {
            const { uploadFiles: logos } = getFormData(files.logo, 'brandkit-logo');

            await fileApi.attachToBrandkit(brandkitResponse.data.id, logos);
        }

        if (files.font?.length > 0) {
            const { uploadFiles: fonts } = getFormData(files.font, 'brandkit-font');

            await fileApi.attachToBrandkit(brandkitResponse.data.id, fonts);
        }

        if (files.music?.length > 0) {
            const { uploadFiles: music } = getFormData(files.music, 'brandkit-music');

            await fileApi.attachToBrandkit(brandkitResponse.data.id, music);
        }

        dispatch({
            type: mode === 'add' ? BrandkitActions.ADD_BRANDKIT : BrandkitActions.UPDATE_BRANDKIT,
            payload: { data: brandkitResponse.data },
        });

        Toast.success({ message: 'Brandkit saved successfully!' });

        navigate(`${brandkitResponse.data.id}`);

        return true;
    }

    return false;
};

export const setBrandkitSelections = async (teamId: number): Promise<IValueLabelPair[]> => {
    const brandkitResponse = await brandkitApi.getClientBrandkit(teamId);

    if (brandkitResponse?.data) {
        if (brandkitResponse.data.length) {
            const options = brandkitResponse.data.map((item: IBrandkitModel) => ({
                value: item.id,
                label: item.brand_name,
            }));

            return [{ value: 0, label: 'Select a brandkit' }, ...options];
        } else {
            return [{ value: 0, label: 'No brandkit available' }];
        }
    } else {
        return [{ value: 0, label: 'Select a brandkit' }];
    }
};

export const getAllBrandkits = async (dispatch: any, selectedTask: ITask | null, currentUser: IUserModel | null) => {
    if (selectedTask !== null && currentUser !== null) {
        if (currentUser.is_client) {
            const brandkitResponse = await brandkitApi.getClientBrandkit(currentUser?.user_team!);

            if (brandkitResponse?.data) {
                dispatch({ type: BrandkitActions.SET_BRANDKIT, payload: { data: brandkitResponse.data } });
            }
        }
    }
};
