import { IActionModel } from '@models/IActionModel';
import { IRefreshModel } from '@models/IRefreshModel';
import { initialState } from '@store/root-reducer';
import { RefreshActions } from './refresh.action';

export const refreshReducer = (state: IRefreshModel = initialState.refresh, action: IActionModel) => {
    switch (action.type) {
        case RefreshActions.REFRESH_TASKS: {
            return { ...state, tasks: action.payload.data };
        }
        case RefreshActions.REFRESH_FILES: {
            return { ...state, files: action.payload.data };
        }
        case RefreshActions.REFRESH_TEAMS: {
            return { ...state, teams: action.payload.data };
        }
        case RefreshActions.REFRESH_MEMBERS: {
            return { ...state, members: action.payload.data };
        }
        case RefreshActions.CLEAR_REFRESH: {
            return { ...state, files: false, tasks: false, teams: false, members: false };
        }
        default:
            return state;
    }
};
export default refreshReducer;
