import { taskApi } from '@api/modules/apiTask';
import { Toast } from '@components/index';
import { Statuses } from '@data/types';
import { IFileResponseModel } from '@models/IFileModel';
import { ITaskModel, ITask } from '@models/ITaskModel';
import { SelectedMembersActions } from '@store/selected-members/selected-members.action';
import { TaskActions } from '@store/tasks/tasks.action';

export enum SelectedTaskActions {
    SET_TASK = 'SET_TASK',
    UPDATE_TASK = 'UPDATE_TASK',
    DELETE_TASK = 'DELETE_TASK',
    CLEAR_TASK = 'CLEAR_TASK',
    UPDATE_FILES = 'UPDATE_FILES',
}

export const setSelectedTask = (dispatch: any, navigate: any, task: ITask) => {
    dispatch({ type: SelectedTaskActions.SET_TASK, payload: { data: task } });
    if (task?.id) {
        navigate('/tasks/' + task.id);
    }
};

export const clearSelectedTask = (dispatch: any) => {
    dispatch({ type: SelectedTaskActions.CLEAR_TASK });
};

export const setDefaultTask = (dispatch: any, task: ITask) => {
    dispatch({ type: SelectedTaskActions.SET_TASK, payload: { data: task } });
};

export const updateSelectedTaskStatus = (dispatch: any, task: ITaskModel, status?: Statuses) => {
    // this updates the selected task on the store
    dispatch({ type: SelectedTaskActions.SET_TASK, payload: { data: { ...task } } });

    // this updates the task list on the store
    dispatch({ type: TaskActions.UPDATE_TASK_STATUS, payload: { data: { ...task } } });

    Toast.success({ message: 'Task status updated' });
};

export const updateSelectedTaskFiles = (dispatch: any, files: IFileResponseModel[]) => {
    dispatch({ type: SelectedTaskActions.UPDATE_FILES, payload: { data: files } });
};

export const assignTask = async (
    dispatch: any,
    taskId: number,
    assignees: { assignees: (number | undefined)[] },
): Promise<boolean> => {
    const assignResponse = await taskApi.assignTask(taskId, assignees);

    const assigneesCount = assignees.assignees;

    if (assignResponse?.data) {
        dispatch({
            type: SelectedMembersActions.CLEAR_MEMBERS,
            payload: { data: [] },
        });

        dispatch({
            type: TaskActions.UPDATE_TASKS,
            payload: {
                data: {
                    ...assignResponse.data,
                    assignees_data: assigneesCount.length === 0 ? [] : assignResponse.data.assignees_data,
                },
            },
        });

        dispatch({
            type: SelectedTaskActions.SET_TASK,
            payload: {
                data: {
                    ...assignResponse.data,
                    assignees_data: assigneesCount.length === 0 ? [] : assignResponse.data.assignees_data,
                },
            },
        });

        return true;
    } else {
        return false;
    }
};
