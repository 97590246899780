import { teamApi } from '@api/modules/apiTeam';
import { userApi } from '@api/modules/apiUsers';
import { IMemberModel } from '@models/IMemberModel';
import { SelectedTeamActions } from '@store/selected-team/selected-team.action';

export enum SelectedMemberActions {
    SET_MEMBER = 'SET_MEMBER',
    UPDATE_MEMBER = 'UPDATE_MEMBER',
    CLEAR_MEMBER = 'CLEAR_MEMBER',
}

export const upsertTeamMember = async (
    dispatch: any,
    mode: 'add' | 'edit',
    userId: number,
    teamId: number,
    member: IMemberModel,
): Promise<boolean> => {
    const memberResponse =
        mode === 'add'
            ? await teamApi.addMember(userId, { ...member })
            : await userApi.updateUser(userId, { ...member });

    if (memberResponse.data) {
        const responseId = mode === 'add' ? memberResponse.data.id : member.id;

        const selectedMember = {
            id: responseId,
            ...memberResponse.data,
            user_team: teamId,
        };

        setSelectedMember(dispatch, selectedMember);

        return true;
    }

    return false;
};

export const updateUserStatus = async (
    dispatch: any,
    userId: number,
    status: { is_active: boolean },
): Promise<boolean> => {
    const userResponse = await userApi.patchUser(userId, status);

    if (userResponse?.data) {
        dispatch({
            type: SelectedMemberActions.SET_MEMBER,
            payload: { data: { ...userResponse.data } },
        });

        dispatch({
            type: SelectedTeamActions.UPDATE_MEMBERS,
            payload: { data: { ...userResponse.data } },
        });

        return true;
    }

    return false;
};

export const setSelectedMember = (dispatch: any, member: IMemberModel) => {
    dispatch({ type: SelectedMemberActions.SET_MEMBER, payload: { data: { ...member } } });
};

export const clearMember = (dispatch: any) => {
    dispatch({ type: SelectedMemberActions.CLEAR_MEMBER });
};
