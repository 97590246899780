import { clearTokenCookies } from '@utils/cookieStorage';
import { UserActions } from '@store/user/user.actions';

export const clearStorage = (dispatch: (value: { type: string }) => void) => {
    clearTokenCookies();

    localStorage.clear();

    dispatch({ type: UserActions.CLEAR_STORE });
};
