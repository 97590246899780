import { MenuButtonVariants } from '@data/types';
import { BLACK, DarkGrey, LightGrey, WHITE } from './theme';

export const getMenuVariant = (variant: MenuButtonVariants, selected?: boolean) => {
    switch (variant) {
        case 'light':
            return {
                color: DarkGrey.DARK,
                backgroundColor: selected ? LightGrey.MEDIUM : WHITE,
                '& svg path': {
                    fill: DarkGrey.DARK,
                },
                '&:hover': {
                    backgroundColor: LightGrey.MEDIUM,
                    '& .MuiButton-endIcon > svg': {
                        opacity: 1,
                    },
                },
                '&:disabled': {
                    color: DarkGrey.LIGHT,
                    '& svg path': {
                        fill: DarkGrey.LIGHT,
                    },
                },
            };
        case 'dark':
            return {
                color: DarkGrey.LIGHT,
                backgroundColor: selected ? DarkGrey.DARK : BLACK,
                '& svg path': {
                    fill: DarkGrey.LIGHT,
                },
                '&:hover': {
                    backgroundColor: DarkGrey.DARK,
                    '& .MuiButton-endIcon > svg': {
                        opacity: 1,
                    },
                },
                '&:disabled': {
                    color: DarkGrey.DARK,
                    '& svg path': {
                        fill: DarkGrey.DARK,
                    },
                },
            };
        default:
            return {};
    }
};
