import { initialState } from '@store/root-reducer';
import { SelectedBrandkitActions } from './selected-brandkit.action';
import { IBrandkitModel } from '@models/IBrandkitModel';
import { IActionModel } from '@models/IActionModel';

export const selectedBrandkitReducer = (
    state: IBrandkitModel | null = initialState.selectedTask,
    action: IActionModel,
) => {
    switch (action.type) {
        case SelectedBrandkitActions.SET_SELECTED_BRANDKIT: {
            return action.payload.data;
        }
        case SelectedBrandkitActions.UPDATE_SELECTED_BRANDKIT: {
            return state;
        }
        case SelectedBrandkitActions.DELETE_SELECTED_BRANDKIT: {
            return state;
        }
        default:
            return state;
    }
};
export default selectedBrandkitReducer;
