import { BadgeVariants } from '@data/types';
import { DarkGrey, WHITE } from './theme';

export const badgeLoaderVariant = (variant: BadgeVariants) => {
    switch (variant) {
        case 'primary':
            return {
                color: WHITE,
            };
        case 'flat':
            return {
                color: DarkGrey.DARK,
            };
        case 'stroke':
            return {
                color: DarkGrey.DARK,
            };
        default:
            return {};
    }
};
