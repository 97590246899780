import { IconProps } from '.';

export function OutlineClock({ fill = '#3F3F3F', size = 16, onClick, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => onClick}
            style={{ ...muiStyle }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00065 2.66536C5.05513 2.66536 2.66732 5.05318 2.66732 7.9987C2.66732 10.9442 5.05513 13.332 8.00065 13.332C10.9462 13.332 13.334 10.9442 13.334 7.9987C13.334 5.05318 10.9462 2.66536 8.00065 2.66536ZM1.33398 7.9987C1.33398 4.3168 4.31875 1.33203 8.00065 1.33203C11.6826 1.33203 14.6673 4.3168 14.6673 7.9987C14.6673 11.6806 11.6826 14.6654 8.00065 14.6654C4.31875 14.6654 1.33398 11.6806 1.33398 7.9987ZM8.00065 3.9987C8.36884 3.9987 8.66732 4.29717 8.66732 4.66536V7.33203H11.334C11.7022 7.33203 12.0007 7.63051 12.0007 7.9987C12.0007 8.36689 11.7022 8.66536 11.334 8.66536H8.00065C7.63246 8.66536 7.33398 8.36689 7.33398 7.9987V4.66536C7.33398 4.29717 7.63246 3.9987 8.00065 3.9987Z"
                fill={fill}
                style={{ ...muiStyle }}
            />
        </svg>
    );
}
