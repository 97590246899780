import React, { HTMLAttributes, memo } from 'react';
import { SxProps } from '@mui/material';
import { FlexColumn } from '@components/wrappers/Flex/Flex.wrapper';
import { Spacing, WHITE } from '@style/theme';
import Title from '@components/typography/Title/Title.component';

export interface SectionProps extends HTMLAttributes<HTMLDivElement> {
    title?: string;
    width?: number | string;
    height?: number | string;
    children?: React.ReactNode | undefined | null;
    muiStyle?: SxProps;
}

const style = {
    root: {
        gap: Spacing.LG,
        padding: Spacing.LG,
        top: 0,
        left: 0,
        backgroundColor: WHITE,
    },
};

export default memo(function Section({ title, width, height, children = <></>, muiStyle }: SectionProps) {
    return (
        <FlexColumn width={width} height={height} sx={{ ...style.root, ...muiStyle }}>
            <Title>{title}</Title>
            {children}
        </FlexColumn>
    );
});
