import { memo } from 'react';
import { SxProps } from '@mui/material';
import { SmallButton } from '@components/index';
import { AlignCenter } from '@components/wrappers/Flex/Flex.wrapper';
import { OutlineLink, OutlineCopy } from '@assets/icons';
import { ColorVariants } from '@data/interfaces';

export interface LinkCopyButtonProps {
    label?: string;
    invert?: boolean;
    variant?: ColorVariants;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    muiStyle?: SxProps;
}

export default memo(function LinkCopyButton({
    label,
    variant = 'info',
    invert = false,
    onClick,
    muiStyle,
}: LinkCopyButtonProps) {
    const onCopy = (e: React.MouseEvent) => {
        e.stopPropagation();
        label && navigator.clipboard.writeText(label);
    };

    return (
        <SmallButton
            label={label}
            variant={variant}
            invert={invert}
            onClick={onClick}
            startIcon={<OutlineLink size={12} />}
            endIcon={
                <AlignCenter onClick={onCopy} zIndex={99}>
                    <OutlineCopy size={12} />
                </AlignCenter>
            }
            muiStyle={{ ...muiStyle }}
        />
    );
});
