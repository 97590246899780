import { IconProps } from '.';

export function OutlineHappy({ fill = '#0D182F', size = 24, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...muiStyle }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.46447 14.1213C8.85499 13.7308 9.48815 13.7308 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213C14.5118 13.7308 15.145 13.7308 15.5355 14.1213C15.9261 14.5118 15.9261 15.145 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C8.07394 15.145 8.07394 14.5118 8.46447 14.1213Z"
                fill={fill}
            />
            <path
                d="M10.5 9.5C10.5 10.3284 9.82843 11 9 11C8.17157 11 7.5 10.3284 7.5 9.5C7.5 8.67157 8.17157 8 9 8C9.82843 8 10.5 8.67157 10.5 9.5Z"
                fill={fill}
            />
            <path
                d="M16.5 9.5C16.5 10.3284 15.8284 11 15 11C14.1716 11 13.5 10.3284 13.5 9.5C13.5 8.67157 14.1716 8 15 8C15.8284 8 16.5 8.67157 16.5 9.5Z"
                fill={fill}
            />
        </svg>
    );
}

export function OutlineSad({ fill = '#000000', size = 24, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...muiStyle }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.46477 16.2071C8.85529 16.5976 9.48846 16.5976 9.87898 16.2071C10.4416 15.6445 11.2047 15.3284 12.0003 15.3284C12.796 15.3284 13.559 15.6445 14.1216 16.2071C14.5121 16.5976 15.1453 16.5976 15.5358 16.2071C15.9264 15.8166 15.9264 15.1834 15.5358 14.7929C14.5982 13.8552 13.3264 13.3284 12.0003 13.3284C10.6742 13.3284 9.40245 13.8552 8.46477 14.7929C8.07424 15.1834 8.07424 15.8166 8.46477 16.2071Z"
                fill={fill}
            />
            <path
                d="M10.5 9.5C10.5 10.3284 9.82843 11 9 11C8.17157 11 7.5 10.3284 7.5 9.5C7.5 8.67157 8.17157 8 9 8C9.82843 8 10.5 8.67157 10.5 9.5Z"
                fill={fill}
            />
            <path
                d="M16.5 9.5C16.5 10.3284 15.8284 11 15 11C14.1716 11 13.5 10.3284 13.5 9.5C13.5 8.67157 14.1716 8 15 8C15.8284 8 16.5 8.67157 16.5 9.5Z"
                fill={fill}
            />
        </svg>
    );
}

export function OutlineNeutral({ fill = '#000000', size = 24, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...muiStyle }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                fill={fill}
            />
            <path
                d="M10.5 9.5C10.5 10.3284 9.82843 11 9 11C8.17157 11 7.5 10.3284 7.5 9.5C7.5 8.67157 8.17157 8 9 8C9.82843 8 10.5 8.67157 10.5 9.5Z"
                fill={fill}
            />
            <path
                d="M16.5 9.5C16.5 10.3284 15.8284 11 15 11C14.1716 11 13.5 10.3284 13.5 9.5C13.5 8.67157 14.1716 8 15 8C15.8284 8 16.5 8.67157 16.5 9.5Z"
                fill={fill}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 15C8 14.4477 8.44772 14 9 14L15 14C15.5523 14 16 14.4477 16 15C16 15.5523 15.5523 16 15 16L9 16C8.44772 16 8 15.5523 8 15Z"
                fill={fill}
            />
        </svg>
    );
}
