import { IVariants, IValueLabelPair, IOption } from '@data/interfaces';
import { IBrandkitModel } from '@models/IBrandkitModel';
import { IMemberModel } from '@models/IMemberModel';
import { ITaskRequest, TaskFilterCategory } from '@models/ITaskModel';
import { Statuses, SubscriptionStatus } from './types';
import { OutlineCircle, OutlineCircleCheck, OutlineCircleDash } from '@assets/icons';

export const ITEMS_PER_PAGE = 10;
export const IMAGES_FILETYPE = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'eps', 'tiff', 'psd', 'raw', 'ai'];
export const FONTS_FILETYPE = ['ttf', '.tf', 'woff', 'woff2'];
export const AUDIO_FILETYPE = ['mp3', 'wav', 'ogg', 'aac', 'flac'];
export const VIDEO_FILETYPE = ['mp4', 'webm', 'ogg', 'flv', 'avi', 'mov', 'wmv', 'mkv'];

export const DEFAULT_CLIENT_SELECTOR: IValueLabelPair = {
    value: 0,
    label: 'Select a team',
};

export const DEFAULT_BRANDKIT_SELECTOR: IValueLabelPair = {
    value: 0,
    label: 'Select a brandkit',
};

export const DEFAULT_TASK_REQUEST: ITaskRequest = {
    name: '',
    description: '',
    status: 'OPN',
    task_brand_kit: 0,
    client_priority: 'NS',
};

export const DEFAULT_MEMBER_REQUEST: IMemberModel = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    discord_id: '',
    user_role: '',
    country: '',
    time_zone: '',
    user_team: 0,
};

export const DEFAULT_TEAM_SELECTOR: IValueLabelPair[] = [
    {
        value: 0,
        label: 'Select a team',
    },
    {
        value: 1,
        label: 'All Teams',
    },
];

export const STATUSES_TO_CATEGORY: Record<Statuses, TaskFilterCategory> = {
    OHD: TaskFilterCategory.open,
    OPN: TaskFilterCategory.open,
    DEM: TaskFilterCategory.open,
    INR: TaskFilterCategory.inProgress,
    RVS: TaskFilterCategory.inProgress,
    ASN: TaskFilterCategory.inProgress,
    INN: TaskFilterCategory.inProgress,
    CPD: TaskFilterCategory.closed,
    CLD: TaskFilterCategory.closed,
};

export const SUBSCRIPTION_STATUS_LABEL: Record<SubscriptionStatus, string> = {
    [SubscriptionStatus.active]: 'Active',
    [SubscriptionStatus.trial]: 'Trials',
    [SubscriptionStatus.lead]: 'New Leads',
    [SubscriptionStatus.inactive]: 'Inactive',
};

// export const OPEN_STATUSES: Statuses[] = ['OHD', 'OPN', 'DEM'];
//
// export const IN_PROGRESS_STATUSES: Statuses[] = ['INR', 'RVS', 'ASN', 'INN'];
//
// export const CLOSED_STATUSES: Statuses[] = ['CPD', 'CLD'];

export const STATUS_VARIANTS: IVariants = {
    OPN: {
        variant: 'stroke',
        label: 'Open',
        icon: <OutlineCircle size={12} />,
    },
    ASN: {
        variant: 'purple',
        label: 'Assigned',
        icon: <OutlineCircle size={12} />,
    },
    INR: {
        variant: 'info',
        label: 'In review',
        icon: <OutlineCircle size={12} />,
    },
    RVS: {
        variant: 'turquoise',
        label: 'Revision',
        icon: <OutlineCircle size={12} />,
    },
    CPD: {
        variant: 'success',
        label: 'Completed',
        icon: <OutlineCircleCheck size={12} />,
    },
    CLD: {
        variant: 'error',
        label: 'Canceled',
        icon: <OutlineCircleDash size={12} />,
    },
    OHD: {
        variant: 'flat',
        label: 'On hold',
        icon: <OutlineCircle size={12} />,
    },
    INN: {
        variant: 'warning',
        label: 'Info needed',
        icon: <OutlineCircle size={12} />,
    },
};

export const PRIORITY_VARIANTS: IVariants = {
    NS: {
        variant: 'flat',
        label: 'Not Set',
    },
    LO: {
        variant: 'success',
        label: 'Low',
    },
    MD: {
        variant: 'warning',
        label: 'Medium',
    },
    HI: {
        variant: 'error',
        label: 'High',
    },
};

export const TASK_STATUS_OPTIONS: IOption[] = [
    { value: 'OPN', label: 'Open', variant: 'stroke' },
    { value: 'ASN', label: 'Assigned', variant: 'purple' },
    { value: 'INR', label: 'In review', variant: 'info' },
    { value: 'RVS', label: 'Revision', variant: 'turquoise' },
    { value: 'CPD', label: 'Completed', variant: 'success' },
    { value: 'CLD', label: 'Canceled', variant: 'error' },
    { value: 'OHD', label: 'On hold', variant: 'flat' },
    { value: 'INN', label: 'Info needed', variant: 'warning' },
];

export const CLIENT_TASK_STATUS_OPTIONS: IOption[] = [
    { value: 'OPN', label: 'Open', variant: 'stroke' },
    { value: 'RVS', label: 'Revision', variant: 'info' },
    { value: 'CPD', label: 'Completed', variant: 'success' },
    { value: 'CLD', label: 'Cancelled', variant: 'error' },
];

export const ADMIN_DESIGNER_TASK_STATUS_OPTIONS: IOption[] = [
    { value: 'OPN', label: 'Open', variant: 'stroke' },
    { value: 'ASN', label: 'Assigned', variant: 'purple' },
    { value: 'INR', label: 'In review', variant: 'info' },
    { value: 'RVS', label: 'Revision', variant: 'turquoise' },
    { value: 'CPD', label: 'Completed', variant: 'success' },
    { value: 'CLD', label: 'Canceled', variant: 'error' },
    { value: 'OHD', label: 'On hold', variant: 'flat' },
    { value: 'INN', label: 'Info needed', variant: 'warning' },
];

export const DESIGNER_TASK_STATUS_OPTIONS: IOption[] = [
    { value: 'INR', label: 'In Review', variant: 'info' },
    { value: 'INN', label: 'Info needed', variant: 'warning' },
];

export const TASK_PRIORITY_OPTIONS: IOption[] = [
    { value: 'NS', label: 'Not Set', variant: 'flat' },
    { value: 'LO', label: 'Low', variant: 'success' },
    { value: 'MD', label: 'Medium', variant: 'warning' },
    { value: 'HI', label: 'High', variant: 'error' },
];

export const DEFAULT_BRANDKIT: IBrandkitModel = {
    brand_name: '',
    brand_urls: {
        data: [],
    },
    audience: '',
    fonts: {
        data: [],
    },
    industry: 'AM',
    colors: {
        data: [],
    },
    top_competitors: {
        data: [],
    },
    music_references: {
        data: [],
    },
    catchphrases: {
        data: [],
    },
    text_copies: {
        data: [],
    },
    creative_references: {
        data: [],
    },
    top_products: {
        data: [],
    },
    brand_kit_created_on: 0,
};
