import { initialState } from '@store/root-reducer';
import { SelectedMembersActions } from './selected-members.action';
import { IMemberModel } from '@models/IMemberModel';
import { IActionModel } from '@models/IActionModel';

export const selectedMembersReducer = (state: IMemberModel[] = initialState.selectedMembers, action: IActionModel) => {
    switch (action.type) {
        case SelectedMembersActions.SET_MEMBERS: {
            return [...action.payload.data];
        }
        case SelectedMembersActions.UPDATE_MEMBERS: {
            const members: IMemberModel[] = [...state];

            const index = members.findIndex(member => member.id === action.payload.data.id);

            members[index] = action.payload.data;

            return members;
        }
        case SelectedMembersActions.CLEAR_MEMBERS: {
            return [];
        }
        default:
            return state;
    }
};
export default selectedMembersReducer;
