import MainContent from '@layout/MainContent/MainContent.component';
import { Box, CircularProgress, LinearProgress } from '@mui/material';
import { Spacing } from '@style/theme';
import { LogoSparkleLg } from '@assets/logos/logo-collection';
import Image from '@components/common/Image/Image.component';

const style = {
    root: {
        height: '100%',
        width: '100%',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    linear: {
        height: 2,
        mt: Spacing.LG,
        width: '20%',
    },
    circular: {
        width: 40,
        height: 40,
        mt: Spacing.LG,
    },
};

function LoadingPage({ linearProgress = true }: { linearProgress?: boolean }) {
    if (linearProgress === false)
        return (
            <Box sx={{ ...style.root }}>
                <CircularProgress sx={{ ...style.circular }} />
            </Box>
        );

    return (
        <MainContent muiStyle={{ height: '100vh' }}>
            <Box sx={{ ...style.root }}>
                <Image src={LogoSparkleLg} width={254} />

                <LinearProgress sx={{ ...style.linear }} />
            </Box>
        </MainContent>
    );
}

export default LoadingPage;
