import { initialState } from '@store/root-reducer';
import { BrandkitActions } from '@store/brandkit/brandkit.actions';
import { IBrandkitModel } from '@models/IBrandkitModel';
import { IActionModel } from '@models/IActionModel';

export const brandkitReducer = (state: IBrandkitModel[] = initialState.brandkits, action: IActionModel) => {
    switch (action.type) {
        case BrandkitActions.SET_BRANDKIT: {
            return [...action.payload.data];
        }
        case BrandkitActions.ADD_BRANDKIT: {
            const brandkits: IBrandkitModel[] = [...state];

            brandkits.push(action.payload.data);

            return brandkits;
        }
        case BrandkitActions.UPDATE_BRANDKIT: {
            const brandkits: IBrandkitModel[] = [...state];

            const index = brandkits.findIndex(brandkit => brandkit.id === action.payload.data.id);

            brandkits[index] = action.payload.data;

            return brandkits;
        }
        case BrandkitActions.DELETE_BRANDKIT: {
            return state;
        }
        default:
            return state;
    }
};
export default brandkitReducer;
