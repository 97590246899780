import { initialState } from '@store/root-reducer';
import { SelectedTeamActions } from './selected-team.action';
import { ITeam } from '@models/ITeamModel';
import { IActionModel } from '@models/IActionModel';

export const selectedTeamReducer = (state: ITeam | null = initialState.selectedTeam, action: IActionModel) => {
    switch (action.type) {
        case SelectedTeamActions.SET_TEAM: {
            return { ...action.payload.data };
        }
        case SelectedTeamActions.UPDATE_TEAM: {
            return state;
        }
        case SelectedTeamActions.CLEAR_TEAM: {
            return null;
        }
        case SelectedTeamActions.UPDATE_MEMBERS: {
            const members = state!.members!;

            const index = members.findIndex(member => member.id === action.payload.data.id);

            members[index] = action.payload.data;

            state!.members = members;

            return { ...state };
        }
        default:
            return state;
    }
};
export default selectedTeamReducer;
