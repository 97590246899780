import { SmallButtonVariants } from '@data/types';
import { Blue, Border, DarkGrey, Green, LightGrey, Primary, Purple, Red, Turquoise, WHITE, Yellow } from './theme';

export const smallButtonVariant = (variant: SmallButtonVariants, invert: boolean) => {
    switch (variant) {
        case 'primary':
            return {
                color: WHITE,
                backgroundColor: Primary.DARK,
                '& svg path': {
                    fill: WHITE,
                },
                '&:hover': {
                    backgroundColor: Primary.DARK,
                },
            };
        case 'flat':
            return {
                color: DarkGrey.DARK,
                backgroundColor: LightGrey.MEDIUM,
                '& svg path': {
                    fill: DarkGrey.DARK,
                },
                '&:hover': {
                    backgroundColor: LightGrey.MEDIUM,
                },
            };
        case 'stroke':
            return {
                color: DarkGrey.DARK,
                backgroundColor: WHITE,
                border: `1px solid ${Border.MEDIUM}`,
                '& svg path': {
                    fill: DarkGrey.DARK,
                },
                '&:hover': {
                    backgroundColor: WHITE,
                },
            };
        case 'info':
            return {
                color: invert ? Blue.MEDIUM : WHITE,
                background: invert ? Blue.LIGHT : Blue.MEDIUM,

                '& svg path': {
                    fill: invert ? Blue.MEDIUM : WHITE,
                },
                '&:hover': {
                    background: invert ? Blue.LIGHT : Blue.MEDIUM,
                },
            };
        case 'success':
            return {
                color: invert ? Green.MEDIUM : WHITE,
                background: invert ? Green.LIGHT : Green.MEDIUM,

                '& svg path': {
                    fill: invert ? Green.MEDIUM : WHITE,
                },
                '&:hover': {
                    background: invert ? Green.LIGHT : Green.MEDIUM,
                },
            };
        case 'warning':
            return {
                color: invert ? Yellow.MEDIUM : WHITE,
                background: invert ? Yellow.LIGHT : Yellow.MEDIUM,

                '& svg path': {
                    fill: invert ? Yellow.MEDIUM : WHITE,
                },
                '&:hover': {
                    background: invert ? Yellow.LIGHT : Yellow.MEDIUM,
                },
            };
        case 'error':
            return {
                color: invert ? Red.MEDIUM : WHITE,
                background: invert ? Red.LIGHT : Red.MEDIUM,

                '& svg path': {
                    fill: invert ? Red.MEDIUM : WHITE,
                },
                '&:hover': {
                    background: invert ? Red.LIGHT : Red.MEDIUM,
                },
            };
        case 'ghost':
            return {
                color: DarkGrey.DARK,
                backgroundColor: WHITE,
                border: 0,
                '& svg path': {
                    fill: DarkGrey.DARK,
                },
                '&:hover': {
                    backgroundColor: WHITE,
                },
                '&:disabled': {
                    '& svg path': {
                        fill: LightGrey.DARK,
                    },
                },
            };
        case 'purple':
            return {
                color: invert ? Purple.MEDIUM : WHITE,
                background: invert ? Purple.LIGHT : Purple.MEDIUM,

                '& svg path': {
                    fill: invert ? Purple.MEDIUM : WHITE,
                },
                '&:hover': {
                    background: invert ? Purple.LIGHT : Purple.MEDIUM,
                },
            };
        case 'turquoise':
            return {
                color: invert ? Turquoise.MEDIUM : WHITE,
                background: invert ? Turquoise.LIGHT : Turquoise.MEDIUM,

                '& svg path': {
                    fill: invert ? Turquoise.MEDIUM : WHITE,
                },
                '&:hover': {
                    background: invert ? Turquoise.LIGHT : Turquoise.MEDIUM,
                },
            };
        default:
            return {};
    }
};
