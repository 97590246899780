import { memo } from 'react';
import { SxProps } from '@mui/material';
import { Typography } from '@components/index';
import { AlignCenter, FlexColumn } from '@components/wrappers/Flex/Flex.wrapper';
import { DarkGrey, LightGrey, Spacing } from '@style/theme';

export interface HintProps {
    /**
     * Start icon of the input field
     */
    startIcon?: React.ReactNode;
    /**
     * Label of the input field
     */
    label?: string;
    /**
     * Value of the input field
     */
    description?: string;
    /**
     * Width
     */
    width?: number | string;
    /**
     * height
     */
    height?: number | string;
    /**
     * Custom styling
     */
    muiStyle?: SxProps;
}

export default memo(function Hint({ startIcon, label, description, width, height, muiStyle }: HintProps) {
    return (
        <FlexColumn width={width} height={height} padding={Spacing.MD} bgcolor={LightGrey.LIGHT} sx={{ ...muiStyle }}>
            <AlignCenter gap={Spacing.SM} mb={Spacing.XS}>
                {startIcon}

                <Typography color={DarkGrey.DARK} prewrap={true}>
                    {label}
                </Typography>
            </AlignCenter>

            <Typography color={DarkGrey.MEDIUM} prewrap={true}>
                {description}
            </Typography>
        </FlexColumn>
    );
});
