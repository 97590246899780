import { memo, useCallback, useEffect, useState } from 'react';
import { teamApi } from '@api/modules/apiTeam';
import { Typography } from '@components/index';
import { useSelector } from 'react-redux';
import { $isDesignerAdminOrSupervisor } from '@store/user/user.selectors';
import { IValueLabelPair } from '@data/interfaces';
import { IClient } from '@models/IClientModel';
import { OptionProps } from '@components/ui/SingleSelectList/SingleSelectList.component';
import { MenuItem } from '@mui/material';
import { AlignCenter } from '@components/wrappers/Flex/Flex.wrapper';
import { Spacing } from '@style/theme';
import DropdownSingleSelect from '@components/ui/DropdownSingleSelect/DropdownSingleSelect.component';

export interface ClientSelectorProps {
    clientTeam?: number;
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
    callback?: (value: any) => Promise<void>;
    disabled?: boolean;
}

const OptionsRender = (props: OptionProps) => {
    const { options, onOptionsClick } = props;

    return options?.map(option => (
        <MenuItem key={option.value} onClick={() => onOptionsClick?.(option)}>
            <AlignCenter gap={Spacing.SM}>
                <Typography>&#8288;{option.label}</Typography>
            </AlignCenter>
        </MenuItem>
    ));
};

export default memo(function ClientSelector({ clientTeam, setLoading, callback, disabled }: ClientSelectorProps) {
    const isDesignerAdminOrSupervisor = useSelector($isDesignerAdminOrSupervisor);

    const [teams, setTeams] = useState<IValueLabelPair[]>([]);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            if (isDesignerAdminOrSupervisor) getAllClientTeams();
        }

        return function cleanup() {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAllClientTeams = async () => {
        const getAllClientTeamResponse = await teamApi.getAllClientTeams();

        if (getAllClientTeamResponse?.data) {
            const options = getAllClientTeamResponse.data.results.map((client: IClient) => ({
                value: client.id,
                label: client.name,
            }));

            setTeams([...teams, ...options]);
            clientTeam && handleSelectChange(clientTeam.toString());
        }
    };

    const handleSelectChange = useCallback(
        async (value: string) => {
            setLoading?.(true);

            const id = value;

            await callback?.(id);

            setLoading?.(false);
        },
        [callback, setLoading],
    );

    return (
        <DropdownSingleSelect
            label="Client"
            defaultValue={clientTeam?.toString()}
            options={teams}
            selectRender={OptionsRender}
            onChange={value => handleSelectChange(value)}
            placeholder="Select Client Team"
            searchable={true}
            searchPlaceholder="Search for client team"
            selectWidth="100%"
            selectHeight={240}
            disabled={disabled}
            required
        />
    );
});
