import { IconProps } from '.';

export function FilledCheckboxEmpty({ fill = '#0D182F', size = 24, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...muiStyle }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 4C2 2.89543 2.89543 2 4 2H20C21.1046 2 22 2.89543 22 4V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V4Z"
                fill={fill}
                style={{ ...muiStyle }}
            />
        </svg>
    );
}

export function FilledCheckboxClose({ fill = '#0D182F', size = 24, onClick, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
            style={{ ...muiStyle, cursor: onClick ? 'pointer' : 'default' }}
        >
            <path fill={fill} d="M0 16c0-8.837 7.163-16 16-16s16 7.163 16 16-7.163 16-16 16-16-7.163-16-16z"></path>
            <path
                fill="#ffffff"
                d="M10.069 10.069c0.29-0.289 0.689-0.468 1.131-0.468s0.842 0.179 1.131 0.468l3.669 3.669 3.669-3.669c0.291-0.301 0.699-0.488 1.151-0.488 0.884 0 1.6 0.716 1.6 1.6 0 0.452-0.187 0.86-0.488 1.15l-3.669 3.669 3.669 3.669c0.278 0.287 0.449 0.68 0.449 1.112 0 0.884-0.716 1.6-1.6 1.6-0.432 0-0.824-0.171-1.112-0.45l-3.668-3.668-3.669 3.669c-0.287 0.278-0.68 0.449-1.112 0.449-0.884 0-1.6-0.716-1.6-1.6 0-0.432 0.171-0.824 0.45-1.112l3.668-3.668-3.669-3.669c-0.289-0.29-0.468-0.689-0.468-1.131s0.179-0.842 0.468-1.131v0z"
            ></path>
        </svg>
    );
}

export function OutlineCircleCheckList({ fill = '#0D182F', size = 20, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...muiStyle }}
        >
            <path
                fill={fill}
                style={{ ...muiStyle }}
                d="M3.2 16c0-7.069 5.731-12.8 12.8-12.8s12.8 5.731 12.8 12.8v0c0 7.069-5.731 12.8-12.8 12.8s-12.8-5.731-12.8-12.8v0zM16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM23.629 12.224c0.246-0.28 0.396-0.649 0.396-1.053 0-0.884-0.716-1.6-1.6-1.6-0.506 0-0.958 0.235-1.251 0.602l-0.002 0.003-6.88 8.254-3.56-3.562c-0.287-0.278-0.679-0.449-1.112-0.449-0.884 0-1.6 0.716-1.6 1.6 0 0.432 0.171 0.824 0.45 1.112l6.038 6.038 9.122-10.944z"
            ></path>
        </svg>
    );
}

export function OutlineCircleCheck({ fill = '#0D182F', size = 24, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...muiStyle }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                fill={fill}
                style={{ ...muiStyle }}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.7071 9.29289C16.0976 9.68342 16.0976 10.3166 15.7071 10.7071L11.7071 14.7071C11.3166 15.0976 10.6834 15.0976 10.2929 14.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929C8.68342 10.9024 9.31658 10.9024 9.70711 11.2929L11 12.5858L14.2929 9.29289C14.6834 8.90237 15.3166 8.90237 15.7071 9.29289Z"
                fill={fill}
                style={{ ...muiStyle }}
            />
        </svg>
    );
}

export function OutlineCheck({ fill = '#0D182F', size = 24, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...muiStyle }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M31.364 5.245c0.849 0.848 0.849 2.224 0 3.073l-18.436 18.436c-0.848 0.848-2.224 0.848-3.073 0l-9.218-9.218c-0.849-0.849-0.849-2.224 0-3.073s2.224-0.848 3.073 0l7.682 7.682 16.9-16.9c0.848-0.848 2.224-0.848 3.073 0z"
                fill={fill}
            />
        </svg>
    );
}

export function OutlineClose({ fill = '#0D182F', size = 24, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...muiStyle }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.975 0.975c0.578-0.577 1.376-0.935 2.257-0.935s1.679 0.357 2.257 0.935l10.512 10.512 10.512-10.512c0.581-0.601 1.395-0.975 2.296-0.975 1.763 0 3.192 1.429 3.192 3.192 0 0.901-0.373 1.715-0.974 2.295l-0.001 0.001-10.512 10.512 10.512 10.512c0.554 0.573 0.896 1.356 0.896 2.218 0 1.763-1.429 3.192-3.192 3.192-0.862 0-1.644-0.342-2.219-0.897l0.001 0.001-10.512-10.512-10.512 10.512c-0.581 0.601-1.395 0.975-2.296 0.975-1.763 0-3.192-1.429-3.192-3.192 0-0.901 0.373-1.715 0.974-2.295l0.001-0.001 10.512-10.512-10.512-10.512c-0.577-0.578-0.935-1.376-0.935-2.257s0.357-1.679 0.935-2.257v0z"
                fill={fill}
            />
        </svg>
    );
}

export function OutlineCircleDash({ fill = '#0D182F', size = 24, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...muiStyle }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill={fill}
                d="M16 3.2c-7.069 0-12.8 5.731-12.8 12.8s5.731 12.8 12.8 12.8v0c7.069 0 12.8-5.731 12.8-12.8s-5.731-12.8-12.8-12.8v0zM0 16c0-8.837 7.163-16 16-16s16 7.163 16 16-7.163 16-16 16-16-7.163-16-16z"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill={fill}
                d="M6.4 16c0-0.884 0.716-1.6 1.6-1.6v0h16c0.884 0 1.6 0.716 1.6 1.6s-0.716 1.6-1.6 1.6v0h-16c-0.884 0-1.6-0.716-1.6-1.6v0z"
            ></path>
        </svg>
    );
}
