import { IconProps } from '.';

export function OutlinePlusCircle({ fill = '#0D182F', size = 20, muiStyle }: IconProps) {
    return (
        <svg width={size} height={size} style={muiStyle} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path
                fill={fill}
                d="M16 3.2c-7.069 0-12.8 5.731-12.8 12.8s5.731 12.8 12.8 12.8v0c7.069 0 12.8-5.731 12.8-12.8s-5.731-12.8-12.8-12.8v0zM0 16c0-8.837 7.163-16 16-16s16 7.163 16 16-7.163 16-16 16-16-7.163-16-16zM16 8c0.884 0 1.6 0.716 1.6 1.6v0 4.8h4.8c0.884 0 1.6 0.716 1.6 1.6s-0.716 1.6-1.6 1.6v0h-4.8v4.8c0 0.884-0.716 1.6-1.6 1.6s-1.6-0.716-1.6-1.6v0-4.8h-4.8c-0.884 0-1.6-0.716-1.6-1.6s0.716-1.6 1.6-1.6v0h4.8v-4.8c0-0.884 0.716-1.6 1.6-1.6v0z"
            ></path>
        </svg>
    );
}

export function OutlinePlusSquare({ fill = '#3F3F3F', size = 40, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...muiStyle }}
        >
            <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" fill="white" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20 13C20.5523 13 21 13.4477 21 14V19H26C26.5523 19 27 19.4477 27 20C27 20.5523 26.5523 21 26 21H21V26C21 26.5523 20.5523 27 20 27C19.4477 27 19 26.5523 19 26V21H14C13.4477 21 13 20.5523 13 20C13 19.4477 13.4477 19 14 19H19V14C19 13.4477 19.4477 13 20 13Z"
                fill={fill}
            />
            <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" stroke="#E5E7EB" />
        </svg>
    );
}

export function OutlineSwatchesPallete({ fill = '#0D182F', size = 20, muiStyle }: IconProps) {
    return (
        <svg width={size} height={size} style={{ ...muiStyle }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path
                fill={fill}
                d="M9.028 3.321l-5.509 20.562c-0.085 0.306-0.134 0.657-0.134 1.020 0 2.177 1.765 3.942 3.942 3.942 1.814 0 3.343-1.226 3.801-2.894l0.007-0.028 5.509-20.562-7.616-2.040zM6.174 1.788c0.285-1.038 1.22-1.788 2.33-1.788 0.222 0 0.436 0.030 0.64 0.086l-0.017-0.004 9.049 2.425c1.037 0.285 1.786 1.22 1.786 2.33 0 0.222-0.030 0.436-0.086 0.64l0.004-0.017-1.763 6.58 6.175-2.878c0.298-0.142 0.648-0.225 1.018-0.225 0.962 0 1.792 0.563 2.18 1.378l0.006 0.015 3.959 8.49c0.134 0.287 0.205 0.587 0.222 0.885 0.055 0.154 0.087 0.331 0.087 0.516v0 9.369c-0.001 1.332-1.081 2.411-2.413 2.411h-21.949c-0.020 0-0.043 0-0.067 0-3.923 0-7.104-3.18-7.104-7.104 0-0.651 0.088-1.282 0.252-1.881l-0.012 0.050 5.7-21.277zM15.66 28.846h12.952v-6.039l-12.952 6.039zM14.39 25.959l13.896-6.481-3.333-7.143-7.9 3.682-2.663 9.943zM5.749 25.062c0-0.871 0.706-1.577 1.577-1.577v0h0.003c0.871 0 1.577 0.706 1.577 1.577v0 0.003c0 0.871-0.706 1.577-1.577 1.577v0h-0.003c-0.871 0-1.577-0.706-1.577-1.577v0-0.003z"
            ></path>
        </svg>
    );
}

export function OutlineFont({ fill = '#0D182F', size = 20, muiStyle }: IconProps) {
    return (
        <svg width={size} height={size} style={{ ...muiStyle }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path
                fill={fill}
                d="M9.6 4.8c0 0 0 0 0 0 0.661 0 1.229 0.401 1.473 0.974l0.004 0.010 8 19.2c0.078 0.182 0.123 0.394 0.123 0.616 0 0.884-0.716 1.6-1.6 1.6-0.661 0-1.229-0.401-1.473-0.974l-0.004-0.010-2.256-5.416h-8.534l-2.256 5.416c-0.248 0.583-0.815 0.984-1.477 0.984-0.884 0-1.6-0.716-1.6-1.6 0-0.222 0.045-0.434 0.127-0.626l-0.004 0.010 8-19.2c0.248-0.583 0.815-0.984 1.477-0.984 0 0 0 0 0 0v0zM6.667 17.6h5.866l-2.933-7.040-2.933 7.040zM25.6 17.6c-1.767 0-3.2 1.433-3.2 3.2s1.433 3.2 3.2 3.2v0c1.767 0 3.2-1.433 3.2-3.2s-1.433-3.2-3.2-3.2v0zM28.942 15.341c-0.951-0.591-2.105-0.941-3.34-0.941-3.535 0-6.4 2.865-6.4 6.4s2.865 6.4 6.4 6.4c1.236 0 2.389-0.35 3.368-0.957l-0.027 0.016c0.258 0.559 0.813 0.94 1.458 0.94 0.883 0 1.6-0.716 1.6-1.599v-9.6c-0-0.883-0.717-1.599-1.6-1.599-0.645 0-1.2 0.381-1.453 0.93l-0.004 0.010z"
            ></path>
        </svg>
    );
}

export function OutlineCopy({ fill = '#0D182F', size = 24, onClick, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            style={{ ...muiStyle }}
            onClick={() => onClick}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 32 32"
        >
            <path
                fill={fill}
                d="M9.6 3.2c0-1.767 1.433-3.2 3.2-3.2v0h16c1.767 0 3.2 1.433 3.2 3.2v0 16c0 1.767-1.433 3.2-3.2 3.2v0h-6.4v6.4c0 1.767-1.433 3.2-3.2 3.2v0h-16c-1.767 0-3.2-1.433-3.2-3.2v0-16c0-1.767 1.433-3.2 3.2-3.2v0h6.4v-6.4zM12.8 9.6h6.4c1.767 0 3.2 1.433 3.2 3.2v0 6.4h6.4v-16h-16v6.4zM19.2 20.798v-7.998h-16v16h16v-8.002z"
            ></path>
        </svg>
    );
}

export function OutlineEdit({ fill = '#8D8D8D', size = 24, onClick, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => onClick}
            style={{ ...muiStyle }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.5858 3.99985C15.3668 3.2188 16.6332 3.2188 17.4142 3.99985L20 6.58564C20.781 7.36669 20.781 8.63302 20 9.41406L16.7073 12.7067L10.4142 18.9998H20C20.5523 18.9998 21 19.4476 21 19.9998C21 20.5521 20.5523 20.9998 20 20.9998H4C3.44772 20.9998 3 20.5521 3 19.9998V15.9998C3 15.7346 3.10536 15.4803 3.29289 15.2927L11.2924 7.29327C11.2925 7.2931 11.2922 7.29345 11.2924 7.29327C11.2925 7.2931 11.2932 7.29239 11.2934 7.29221L14.5858 3.99985ZM12 9.41406L5 16.4141V18.9998H7.58579L14.5858 11.9998L12 9.41406ZM16 10.5856L13.4142 7.99985L16 5.41406L18.5858 7.99985L16 10.5856Z"
                fill={fill}
                style={{ ...muiStyle }}
            />
        </svg>
    );
}

export function OutlineEditPencil({ fill = '#8D8D8D', size = 20, onClick, muiStyle }: IconProps) {
    return (
        <svg width={size} height={size} style={{ ...muiStyle }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path
                fill={fill}
                d="M20.597 1.409c0.643-0.643 1.532-1.041 2.514-1.041s1.87 0.398 2.514 1.041l4.597 4.597c0.643 0.643 1.041 1.532 1.041 2.514s-0.398 1.87-1.041 2.514l-17.042 17.042h17.042c0.982 0 1.778 0.796 1.778 1.778s-0.796 1.778-1.778 1.778v0h-28.444c-0.982 0-1.778-0.796-1.778-1.778v0-7.111c0-0.491 0.199-0.935 0.521-1.257l20.076-20.076zM16 11.034l-12.444 12.444v4.597h4.597l12.444-12.444-4.597-4.597zM23.111 13.118l-4.597-4.597 4.597-4.597 4.597 4.597-4.597 4.597z"
            ></path>
        </svg>
    );
}

export function OutlinePaperClip({ fill = '#8D8D8D', size = 24, onClick, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            onClick={() => onClick}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.1475 5.27727C16.4877 3.61754 13.7968 3.61754 12.137 5.27727L5.24275 12.1716C4.85223 12.5621 4.21906 12.5621 3.82854 12.1716C3.43801 11.781 3.43801 11.1479 3.82854 10.7573L10.7228 3.86305C13.1636 1.42228 17.1209 1.42228 19.5617 3.86305C22.0024 6.30383 22.0024 10.2611 19.5617 12.7019L11.6067 20.6568C9.84935 22.4142 7.00011 22.4142 5.24275 20.6568C3.48539 18.8995 3.48539 16.0502 5.24275 14.2929L13.1977 6.33793C14.2716 5.26398 16.0128 5.26398 17.0868 6.33793C18.1607 7.41187 18.1607 9.15307 17.0868 10.227L10.1925 17.1213C9.80197 17.5118 9.16881 17.5118 8.77829 17.1213C8.38776 16.7308 8.38776 16.0976 8.77829 15.7071L15.6726 8.8128C15.9655 8.51991 15.9655 8.04503 15.6726 7.75214C15.3797 7.45925 14.9048 7.45925 14.6119 7.75214L6.65696 15.7071C5.68065 16.6834 5.68065 18.2663 6.65696 19.2426C7.63328 20.2189 9.21619 20.2189 10.1925 19.2426L18.1475 11.2877C19.8072 9.62795 19.8072 6.93699 18.1475 5.27727Z"
                fill={fill}
                style={{ ...muiStyle }}
            />
        </svg>
    );
}
