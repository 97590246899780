import { ButtonVariants } from '@data/types';
import { DarkGrey, Primary } from './theme';

export const buttonLoaderVariant = (variant: ButtonVariants) => {
    switch (variant) {
        case 'primary':
            return {
                color: Primary.DARK,
            };
        case 'flat':
            return {
                color: DarkGrey.DARK,
            };
        case 'stroke':
            return {
                color: DarkGrey.DARK,
            };
        default:
            return {};
    }
};
