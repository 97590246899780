import { Sizes } from '@data/types';

export const badgeSize = (size: Extract<Sizes, 'sm' | 'md' | 'lg'>) => {
    switch (size) {
        case 'sm':
            return {
                width: 24,
                minWidth: 24,
                height: 24,
                borderRadius: '6px',
            };
        case 'md':
            return {
                width: 40,
                minWidth: 40,
                height: 40,
            };
        case 'lg':
            return {
                width: 60,
                minWidth: 60,
                height: 60,
            };
        default:
            return {};
    }
};
