import { IconProps } from '.';

export function OutlineMapPin({ fill = '#3F3F3F', size = 16, onClick, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() => onClick}
            style={{ ...muiStyle }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.0013 2.66536C6.93807 2.66536 5.91986 3.08316 5.17026 3.82451C4.42092 4.56562 4.0013 5.56921 4.0013 6.61408C4.0013 8.44839 5.03991 10.1401 6.16864 11.4239C6.72455 12.0562 7.28223 12.5665 7.70113 12.9187C7.81219 13.012 7.91314 13.094 8.0013 13.1639C8.08946 13.094 8.19041 13.012 8.30148 12.9187C8.72037 12.5665 9.27806 12.0562 9.83397 11.4239C10.9627 10.1401 12.0013 8.44839 12.0013 6.61408C12.0013 5.56921 11.5817 4.56562 10.8323 3.82451C10.0827 3.08316 9.06453 2.66536 8.0013 2.66536ZM8.0013 13.9987C7.62183 14.5468 7.62165 14.5467 7.62145 14.5466L7.61968 14.5453L7.61584 14.5427L7.60318 14.5337C7.59255 14.5262 7.57762 14.5156 7.55865 14.5019C7.52073 14.4745 7.46667 14.4349 7.39878 14.3836C7.26306 14.281 7.07167 14.1314 6.84314 13.9393C6.38704 13.5559 5.77806 12.9989 5.1673 12.3043C3.96269 10.9342 2.66797 8.93362 2.66797 6.61408C2.66797 5.2108 3.23168 3.86651 4.23268 2.87651C5.23342 1.88676 6.58918 1.33203 8.0013 1.33203C9.41343 1.33203 10.7692 1.88676 11.7699 2.87651C12.7709 3.86651 13.3346 5.2108 13.3346 6.61408C13.3346 8.93362 12.0399 10.9342 10.8353 12.3043C10.2245 12.9989 9.61556 13.5559 9.15946 13.9393C8.93094 14.1314 8.73954 14.281 8.60382 14.3836C8.53593 14.4349 8.48187 14.4745 8.44395 14.5019C8.42499 14.5156 8.41005 14.5262 8.39943 14.5337L8.38676 14.5427L8.38292 14.5453L8.38163 14.5462C8.38144 14.5464 8.38077 14.5468 8.0013 13.9987ZM8.0013 13.9987L8.38077 14.5468C8.15248 14.7049 7.84975 14.7046 7.62145 14.5466L8.0013 13.9987ZM8.0013 5.33203C7.63311 5.33203 7.33464 5.63051 7.33464 5.9987C7.33464 6.36689 7.63311 6.66536 8.0013 6.66536C8.36949 6.66536 8.66797 6.36689 8.66797 5.9987C8.66797 5.63051 8.36949 5.33203 8.0013 5.33203ZM6.0013 5.9987C6.0013 4.89413 6.89673 3.9987 8.0013 3.9987C9.10587 3.9987 10.0013 4.89413 10.0013 5.9987C10.0013 7.10327 9.10587 7.9987 8.0013 7.9987C6.89673 7.9987 6.0013 7.10327 6.0013 5.9987Z"
                fill={fill}
                style={{ ...muiStyle }}
            />
        </svg>
    );
}
