import React, { memo } from 'react';
import { SxProps } from '@mui/material';
import { DarkGrey, FontWeight } from '@style/theme';
import { Typography } from '@components/index';
import { FontVariants } from '@data/types';

interface SubtitleProps {
    ref?: React.RefObject<HTMLParagraphElement>;
    variant?: Extract<FontVariants, 'body1' | 'body2' | 'caption'>;
    children: string | React.ReactNode;
    color?: string;
    muiStyle?: SxProps;
}

export default memo(function Subtitle(props: SubtitleProps) {
    const { ref, variant, children, color = DarkGrey.MEDIUM, muiStyle } = props;

    if (!children) return null;

    return (
        <Typography ref={ref} variant={variant} weight={FontWeight.BOLD} color={color} muiStyle={{ ...muiStyle }}>
            {children}
        </Typography>
    );
});
