import { ITeam } from '@models/ITeamModel';

export enum SelectedTeamActions {
    SET_TEAM = 'SET_TEAM',
    UPDATE_TEAM = 'UPDATE_TEAM',
    UPDATE_MEMBERS = 'UPDATE_MEMBERS',
    CLEAR_TEAM = 'CLEAR_TEAM',
}

export const setSelectedTeam = (dispatch: any, team: ITeam) => {
    dispatch({ type: SelectedTeamActions.SET_TEAM, payload: { data: { ...team } } });
};

export const clearTeam = (dispatch: any) => {
    dispatch({ type: SelectedTeamActions.CLEAR_TEAM });
};
