import { HTMLAttributes, useEffect, memo, useCallback } from 'react';
import { Box, SxProps, Tooltip } from '@mui/material';
import { SmallButton, Typography, Badge } from '@components/index';
import { FONTS_FILETYPE, AUDIO_FILETYPE, IMAGES_FILETYPE } from '@data/defaults';
import { OutlineFont, OutlineHeadphone, OutlineFileCheck, OutlinePaperClip } from '@assets/icons';
import { FontWeight, DarkGrey, Spacing } from '@style/theme';
import { FlexBox } from '@components/wrappers/Flex/Flex.wrapper';
import { useDropzone } from 'react-dropzone';
import _ from 'lodash';
import { IFileRequestModel } from '@models/IFileModel';
import Scroller from '@layout/Scroller/Scroller';

export interface FileUploadProps extends HTMLAttributes<HTMLInputElement> {
    /**
     * Setting files to be uploaded
     */
    setFiles: React.Dispatch<React.SetStateAction<IFileRequestModel[]>>;
    /**
     * Files
     */
    files: IFileRequestModel[];
    /**
     * Label of the input field
     */
    label?: string;
    /**
     * Width of the input field
     */
    width?: number | string;
    /**
     * Width of the input field
     */
    acceptedFileType?: 'img' | 'pdf' | 'font' | 'music' | 'all';
    /**
     * Copy to clipboard
     */
    copyToClipboard?: boolean;
    /**
     * Custom CSS styling
     */
    muiStyle?: SxProps;
}

const style = {
    root: {},
    thumbs: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: Spacing.XS,
        padding: Spacing.XS,
        width: '100%',
        height: 38,
    },
};

const getIconType = (ext: string | undefined) => {
    const extension = ext ?? '';
    if (FONTS_FILETYPE.includes(extension)) return <OutlineFont size={12} />;
    if (AUDIO_FILETYPE.includes(extension)) return <OutlineHeadphone size={12} />;
    if (IMAGES_FILETYPE.includes(extension)) return <OutlineFileCheck size={12} />;
    return <OutlineFileCheck />;
};

export default memo(function FileUpload({
    setFiles,
    files,
    label,
    width = 280,
    acceptedFileType = 'all',
    copyToClipboard = false,
    muiStyle,
}: FileUploadProps) {
    const uniqueName = (name: string) => {
        const file_name = name?.split('.')[0];

        const ext = name?.split('.')[1];
        const uid = Math.floor(1000 + Math.random() * 9000);

        return `${file_name}_${uid}.${ext}`;
    };

    const onDrop = useCallback(acceptedFiles => {
        _.forEach(acceptedFiles, file => {
            const reader = new FileReader();

            const file_name = uniqueName(file.name);

            const file_size_in_bytes = file.size;
            const last_modified = file.lastModified;

            reader.onload = () => {
                const file_url = reader.result as string;

                setFiles(previousFiles => [
                    ...previousFiles,
                    {
                        file,
                        file_name,
                        file_url,
                        file_size_in_bytes,
                        last_modified,
                    },
                ]);
            };
            reader.readAsDataURL(file);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        noDrag: true,
        noClick: true,
    });

    const onPaste = useCallback((e: any) => {
        const items = e.clipboardData?.items;

        const item = items?.[0];
        const file = item?.getAsFile();

        if (file) {
            const reader = new FileReader();

            const file_name = uniqueName(file.name);

            const file_size_in_bytes = file.size;
            const last_modified = file.lastModified;

            reader.onload = () => {
                const file_url = reader.result as string;

                if (file) {
                    setFiles(previousFiles => [
                        ...previousFiles,
                        {
                            file,
                            file_name,
                            file_url,
                            file_size_in_bytes,
                            last_modified,
                        },
                    ]);
                }
            };

            reader.readAsDataURL(file!);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (copyToClipboard) {
            document.addEventListener('paste', onPaste);

            return () => {
                document.removeEventListener('paste', onPaste);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const removeFile = (toDelete: IFileRequestModel) =>
        setFiles(files => files.filter(f => f.file_name !== toDelete.file_name));

    const thumbs = files?.map((file, i) => {
        const ext = file?.file_name?.split('.').pop();

        return (
            <SmallButton
                key={i}
                label={file?.file_name}
                variant="stroke"
                endIcon={getIconType(ext)}
                onClick={() => removeFile(file)}
                muiStyle={{ maxWidth: 220 }}
            />
        );
    });

    useEffect(
        () => () => files?.forEach(file => URL.revokeObjectURL(file.file_url!)),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [files],
    );

    return (
        <Box width={width} sx={{ ...muiStyle }}>
            {label && (
                <Typography
                    variant="body1"
                    weight={FontWeight.BOLD}
                    color={DarkGrey.DARK}
                    muiStyle={{ mb: Spacing.MD }}
                >
                    {label}
                </Typography>
            )}

            <FlexBox width="auto" height="auto" alignItems="center" justifyContent="space-between">
                <Box {...getRootProps({ className: 'dropzone' })} onClick={e => e.stopPropagation}>
                    <input {...getInputProps()} />
                    <Tooltip title="Attach file" placement="top" arrow>
                        <Box>
                            <Badge variant="stroke" icon={<OutlinePaperClip size={18} />} onClick={open} />
                        </Box>
                    </Tooltip>
                </Box>
                {thumbs && files.length > 0 && <Scroller sx={{ ...style.thumbs }}> {thumbs} </Scroller>}
            </FlexBox>
        </Box>
    );
});
