import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { Box, Menu, PopoverOrigin } from '@mui/material';
import { Spacing, BorderRadius, LightGrey } from '@style/theme';
import Tooltip from '../Tooltip/Tooltip.wrapper';

const ANCHOR_ORIGIN: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
};

const TRANSFORM_ORIGIN: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'left',
};

const styles = {
    root: {},
    menu: {
        mt: Spacing.MD,
    },
    paper: {
        borderRadius: BorderRadius.XS,
    },
    option: {
        alignItems: 'center',
        padding: Spacing.XS,
        borderRadius: BorderRadius.XS,
        cursor: 'pointer',
        boxSizing: 'border-box',
        overflow: 'hidden',
        '&:hover, &.selected': {
            background: LightGrey.LIGHT,
        },
    },
};

export interface PopupProps {
    trigger: React.ReactNode;
    tooltip?: string;
    width?: number | string;
    padding?: number;
    transformOrigin?: PopoverOrigin;
    anchorOrigin?: PopoverOrigin;
    children?: React.ReactNode;
    closeOnSelect?: boolean;
    onClose?: () => void;
    disabled?: boolean;
    isLoading?: boolean;
}

export default memo(function Popover({
    trigger,
    tooltip,
    width,
    padding = Spacing.LG,
    transformOrigin = TRANSFORM_ORIGIN,
    anchorOrigin = ANCHOR_ORIGIN,
    children,
    closeOnSelect = true,
    onClose,
    disabled = false,
    isLoading = false,
}: PopupProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const triggerRef = useRef<HTMLDivElement>(null);
    const open = Boolean(anchorEl);

    const Trigger: React.ReactNode = useMemo(
        () => (
            <div
                ref={triggerRef}
                onClick={e => setAnchorEl(e.currentTarget)}
                style={{ width: width, pointerEvents: disabled ? 'none' : 'auto' }}
            >
                {trigger}
            </div>
        ),
        [triggerRef, trigger, width, disabled],
    );

    const triggerWidth = triggerRef.current?.getBoundingClientRect().width;

    const handleClose = useCallback(() => {
        onClose?.();
        setAnchorEl(null);
    }, [onClose]);

    return (
        <>
            <Tooltip tooltip={tooltip}>{Trigger}</Tooltip>

            <Menu
                anchorEl={anchorEl}
                open={open}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                onClose={handleClose}
                sx={{
                    ...styles.menu,
                    width: triggerWidth,
                    '.MuiPaper-root': { padding: padding, ...styles.paper, width: triggerWidth },
                }}
            >
                <Box onClick={closeOnSelect ? handleClose : undefined}>{children}</Box>
            </Menu>
        </>
    );
});
