import React, { memo, useMemo } from 'react';
import { SxProps } from '@mui/material';
import { DarkGrey, FontWeight, Red, Spacing } from '@style/theme';
import { Typography } from '@components/index';
import { FlexBox } from '@components/wrappers/Flex/Flex.wrapper';
import { FontVariants } from '@data/types';

export interface LabelProps {
    label?: string;
    variant?: FontVariants;
    weight?: FontWeight;
    color?: string;
    width?: number | string;
    alignment?: 'row' | 'column';
    required?: boolean;
    prewrap?: boolean;
    children: string | React.ReactNode;
    muiStyle?: SxProps;
}

const style = {
    root: { alignItems: 'flex-start', position: 'relative' },
    label: { mb: Spacing.MD },
};

export default memo(function AppLabel({
    label,
    variant = 'body1',
    weight = FontWeight.BOLD,
    color = DarkGrey.DARK,
    width = '100%',
    alignment = 'column',
    required,
    prewrap,
    children,
    muiStyle,
}: LabelProps) {
    const styles = useMemo(() => ({ ...style.root, ...muiStyle } as SxProps), [muiStyle]);

    if (!label) return <>{children}</>;

    return (
        <FlexBox width={width} flexDirection={alignment} muiStyle={{ ...styles }}>
            <Typography
                variant={variant}
                weight={weight}
                color={color}
                prewrap={prewrap}
                muiStyle={{ ...style.label, width: width }}
            >
                {label} {required && <span style={{ color: Red.MEDIUM }}>*</span>}
            </Typography>

            {children}
        </FlexBox>
    );
});
