import { memo } from 'react';
import { Box, SxProps } from '@mui/material';
import Tooltip from '@components/wrappers/Tooltip/Tooltip.wrapper';

export interface ImageProps {
    src: string;
    width?: string | number;
    height?: string | number;
    tooltip?: string;
    onClick?: () => void;
    muiStyle?: SxProps;
}

export default memo(function Image({ src, width, height, tooltip, onClick, muiStyle }: ImageProps) {
    return (
        <Tooltip tooltip={tooltip}>
            <Box
                component="img"
                src={src}
                width={width}
                height={height}
                alt={`alt-${src}`}
                onClick={onClick}
                sx={{ pointer: onClick ? 'pointer' : 'default', ...muiStyle }}
            />
        </Tooltip>
    );
});
