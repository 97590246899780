import React, { memo } from 'react';
import { SxProps } from '@mui/material';
import { DarkGrey, FontWeight } from '@style/theme';
import { Typography } from '@components/index';
import { FontVariants } from '@data/types';

interface TitleProps {
    ref?: React.RefObject<HTMLParagraphElement>;
    variant?: Extract<FontVariants, 'h1' | 'h2' | 'h3'>;
    children: string | React.ReactNode;
    color?: string;
    prewrap?: boolean;
    muiStyle?: SxProps;
}

export default memo(function Title(props: TitleProps) {
    const { ref, variant = 'h1', children, color = DarkGrey.DARK, prewrap = false, muiStyle } = props;

    if (!children) return null;

    return (
        <Typography
            ref={ref}
            variant={variant}
            weight={FontWeight.BOLD}
            color={color}
            prewrap={prewrap}
            muiStyle={{ ...muiStyle }}
        >
            {children}
        </Typography>
    );
});
