import React from 'react';
import { SxProps } from '@mui/material';
import { Panel } from '@layout/index';

export interface ContentProps {
    /**
     * Optional, child content
     */
    children?: React.ReactNode | undefined | null;
    /**
     * Optional, custom styling
     */
    muiStyle?: SxProps;
}

export default function MainContent({ children = <></>, muiStyle }: ContentProps) {
    return (
        <Panel width="100%" muiStyle={{ ...muiStyle }}>
            {children}
        </Panel>
    );
}
