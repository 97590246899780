import { Box, SxProps } from '@mui/material';
import { Typography } from '@components/index';
import { Border, FontWeight, LightGrey } from '@style/theme';
import { HTMLAttributes, useMemo } from 'react';
import Tooltip from '@components/wrappers/Tooltip/Tooltip.wrapper';
import { FlexCenter } from '@components/wrappers/Flex/Flex.wrapper';
import { Sizes } from '@data/types';
import { getIconSize } from '@style/getIconSize';

export interface CountProps extends HTMLAttributes<HTMLDivElement> {
    count: number;
    round?: boolean;
    size?: Sizes;
    tooltip?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    muiStyle?: SxProps;
    color?: string;
}

const style = (size: Sizes, round: boolean) => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            boxSizing: 'border-box',
            background: LightGrey.LIGHT,
            border: `1px solid ${Border.MEDIUM}`,
            ...getIconSize(size),
            ...(round && { borderRadius: '50%' }),
        },
    };
};

export default function Count({ count, round = true, size = 'sm', tooltip, onClick, muiStyle, color }: CountProps) {
    const styles = useMemo(() => ({ ...style(size, round).root, ...muiStyle }) as SxProps, [size, round, muiStyle]);

    return (
        <Tooltip tooltip={tooltip} placement="top" arrow={true}>
            <Box onClick={onClick} sx={{ cursor: onClick ? 'pointer' : 'default', ...styles }}>
                <FlexCenter width="100%" height="100%">
                    <Typography color={color} variant="caption" weight={FontWeight.BOLD}>
                        {count}
                    </Typography>
                </FlexCenter>
            </Box>
        </Tooltip>
    );
}
