import { memo } from 'react';
import { Navigate } from 'react-router-dom';
import { FlexCenter } from '@components/wrappers/Flex/Flex.wrapper';
import Sidebar from '@layout/Sidebar/Sidebar.component';

import { LightGrey, Spacing } from '@style/theme';

const styles = {
    root: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: LightGrey.MEDIUM,
    },
    inner: {
        width: '98%',
        height: '98%',
        gap: Spacing.LG,
    },
};

const RenderApp = (children: React.ReactNode) => (
    <FlexCenter sx={{ ...styles.root }}>
        <FlexCenter sx={{ ...styles.inner }}>
            <Sidebar />
            {children}
        </FlexCenter>
    </FlexCenter>
);

interface PrivateRouteProps {
    isLoggedIn?: boolean;
    permitted?: boolean;
    redirectPath?: string;
    children: React.ReactNode;
}

export default memo(function AuthRoute({
    isLoggedIn = false,
    permitted = false,
    redirectPath,
    children,
}: PrivateRouteProps) {
    if (!permitted) return <Navigate to={redirectPath || '/'} replace />;

    if (isLoggedIn) return RenderApp(children);
    else return <Navigate to="/login" replace />;
});
