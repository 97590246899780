import { initialState } from '@store/root-reducer';
import { SelectedMemberActions } from './selected-member.action';
import { IMemberModel } from '@models/IMemberModel';
import { IActionModel } from '@models/IActionModel';

export const selectedMemberReducer = (
    state: IMemberModel | null = initialState.selectedMember,
    action: IActionModel,
) => {
    switch (action.type) {
        case SelectedMemberActions.SET_MEMBER: {
            return { ...action.payload.data };
        }
        case SelectedMemberActions.UPDATE_MEMBER: {
            return state;
        }
        case SelectedMemberActions.CLEAR_MEMBER: {
            return null;
        }
        default:
            return state;
    }
};
export default selectedMemberReducer;
