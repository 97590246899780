import { Axis } from '@data/types';
import { createTheme } from '@mui/material';

export const WHITE = '#FFFFFF';
export const BLACK = '#000000';

export enum Link {
    DARK = '#3f51b5',
}

export enum LightGrey {
    LIGHT = '#F9FAFB',
    MEDIUM = '#F4F5F7',
    DARK = '#E5E7EB',
}

export enum DarkGrey {
    LIGHT = '#D0D0D0',
    MEDIUM = '#8D8D8D',
    DARK = '#3F3F3F',
}

export enum Primary {
    HIGHLIGHT = '#FCF5F0',
    LIGHT = '#FAD9C6',
    MEDIUM = '#F19058',
    DARK = '#EF8141',
}

export enum Green {
    LIGHT = '#EAF6E7',
    MEDIUM = '#2CAA14',
}

export enum Yellow {
    LIGHT = '#FFF8E6',
    MEDIUM = '#FFB70A',
}

export enum Blue {
    LIGHT = '#E5F0FD',
    MEDIUM = '#006DF0',
}

export enum Red {
    LIGHT = '#FBECE5',
    MEDIUM = '#D74100',
}

export enum Purple {
    LIGHT = '#F8F6FE',
    MEDIUM = '#7D55E4',
    HIGHLIGHT = '#CFC0F5',
}

export enum Turquoise {
    LIGHT = '#E9F9F8',
    MEDIUM = '#57BFB9',
}

export enum Border {
    LIGHT = '#F3F3F5',
    MEDIUM = '#E5E7EB',
}

export enum FontWeight {
    LIGHT = 300,
    REGULAR = 400,
    MEDIUM = 500,
    SEMIBOLD = 600,
    BOLD = 700,
}

export enum Spacing {
    XXS = 2,
    XS = 4,
    SM = 8,
    MD = 10,
    LG = 20,
    XL = 32,
    XXL = 42,
}

export enum BorderRadius {
    XXS = 1,
    XS = 2,
    SM = 4,
    MD = 8,
    LG = 10,
    XL = 20,
}

export const BorderStyles = {
    DEFAULT: {
        boxSizing: 'border-box',
        border: `1px solid ${LightGrey.MEDIUM}`,
    },
};

export const Scroll = (axis: Axis): any => {
    const scrollbar = {
        '&::-webkit-scrollbar': {
            width: 4,
            height: 4,
        },
        '&::-webkit-scrollbar-track': {
            background: WHITE,
            boxSize: 'border-box',
            border: `1px solid ${LightGrey.MEDIUM}`,
            borderRadius: BorderRadius.XS,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: Primary.DARK,
            borderRadius: BorderRadius.XS,
        },
    };
    switch (axis) {
        case 'x':
            return {
                overflowX: 'auto !important',
                overflowY: 'hidden',
                ...scrollbar,
            };
        case 'y':
            return {
                overflowX: 'hidden',
                overflowY: 'auto !important',
                ...scrollbar,
            };
        case 'xy':
            return {
                overflowX: 'auto !important',
                overflowY: 'auto !important',
                ...scrollbar,
            };
        default:
            return {
                overflowX: 'hidden',
                overflowY: 'hidden',
            };
    }
};
export const APP_THEME = createTheme({
    spacing: 1,
    palette: {
        primary: {
            main: Primary.MEDIUM,
            light: Primary.LIGHT,
            dark: Primary.DARK,
            contrastText: Primary.HIGHLIGHT,
        },
    },
    typography: {
        h1: {
            fontSize: '1.875rem',
            lineHeight: '2.5rem',
        },
        h2: {
            fontSize: '1.125rem',
            lineHeight: '1.5rem',
        },
        h3: {
            fontSize: '1rem',
            lineHeight: '1.5rem',
        },
        body1: {
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
        },
        body2: {
            fontSize: '0.75rem',
            lineHeight: '1rem',
        },
        caption: {
            fontSize: '0.625rem',
            lineHeight: '1rem',
        },
        overline: {
            fontSize: '0.625rem',
            lineHeight: '1rem',
            letterSpacing: '0.025rem',
        },
    },
    components: {
        // Material-UI Button
        MuiButton: {
            defaultProps: {
                disableRipple: true, // No more ripple, on the whole application 💣!
            },
            styleOverrides: {
                root: {
                    transition: 'none',
                    textTransform: 'none',

                    borderRadius: 8,
                    minWidth: 40,
                    height: 40,

                    '& .MuiButton-startIcon': {
                        margin: '0 0.125rem 0 0.25rem',
                    },

                    '& .MuiButton-endIcon': {
                        margin: '0 0.25rem 0 0.125rem',
                    },
                },
            },
        },
        // Material-UI Accordion
        MuiAccordion: {
            styleOverrides: {
                root: {
                    border: 0,
                    boxShadow: 'none',
                },
            },
        },
        // Material-UI Paper
        MuiPaper: {
            styleOverrides: {
                root: { ...Scroll('y') },
            },
        },
        // Material-UI InputBase
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '& textarea': { ...Scroll('y') },
                },
            },
        },
        // Material-UI Card
        MuiCard: {
            styleOverrides: {
                root: {
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: Spacing.MD,
                    boxShadow: 'none',
                    borderRadius: BorderRadius.SM,
                },
            },
        },
        // Material-UI Toolbar
        MuiToolbar: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            },
        },
        // Material-UI Pagination
        MuiPaginationItem: {
            styleOverrides: {
                root: { borderRadius: BorderRadius.SM },
            },
        },
    },
});
