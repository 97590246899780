import { initialState } from '@store/root-reducer';
import { SelectedTaskActions } from './selected-task.action';
import { ITask } from '@models/ITaskModel';
import { IActionModel } from '@models/IActionModel';

export const selectedTaskReducer = (state: ITask | null = initialState.selectedTask, action: IActionModel) => {
    switch (action.type) {
        case SelectedTaskActions.SET_TASK: {
            if (action.payload.data === null) return null;

            return { ...action.payload.data };
        }
        case SelectedTaskActions.UPDATE_TASK: {
            return state;
        }
        case SelectedTaskActions.DELETE_TASK: {
            return state;
        }
        case SelectedTaskActions.CLEAR_TASK: {
            return null;
        }
        case SelectedTaskActions.UPDATE_FILES: {
            return { ...state, files: action.payload.data, total_files: action.payload.data.length };
        }
        default:
            return state;
    }
};
export default selectedTaskReducer;
