import React, { memo } from 'react';
import { Box, SxProps, Tooltip } from '@mui/material';
import { toBreakline } from '@helper/toBreakline';

export interface TooltipProps {
    tooltip?: React.ReactNode;
    placement?: 'top' | 'bottom' | 'left' | 'right';
    arrow?: boolean;
    children: string | React.ReactNode;
    sx?: SxProps;
}

export default memo(function AppTooltip({ tooltip, children, placement = 'top', arrow = true, sx }: TooltipProps) {
    if (!tooltip) return <>{children}</>;
    const title = typeof tooltip === 'string' ? toBreakline(tooltip) : tooltip

    return (
        <Tooltip title={title} placement={placement} arrow={arrow} sx={{ ...sx }}>
            <Box>{children}</Box>
        </Tooltip>
    );
});
