import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { CircularProgress, MenuItem, Select, SelectChangeEvent, SxProps } from '@mui/material';
import { Typography } from '@components/index';
import { BorderRadius, Spacing, WHITE } from '@style/theme';
import { ChevronDown } from '@assets/icons';
import { IValueLabelPair } from '@data/interfaces';
import { FlexDirections } from '@data/types';
import { FlexColumn } from '@components/wrappers/Flex/Flex.wrapper';
import Label from '@components/wrappers/Label/Label.wrapper';

export interface InputDropdownProps {
    label?: string;
    variant?: 'body1' | 'body2';
    value?: IValueLabelPair;
    selections: IValueLabelPair[];
    placeholder?: string;
    width?: number | string;
    height?: number;
    alignment?: FlexDirections;
    loading?: boolean;
    disabled?: boolean;
    required?: boolean;
    onChange: (e: SelectChangeEvent) => void;
    muiStyle?: SxProps;
}

const style = {
    root: {},
    selector: {
        height: 40,
        padding: Spacing.MD,

        boxSizing: 'border-box',
        borderRadius: BorderRadius.XS,

        bgcolor: WHITE,

        '& .MuiSelect-select': {
            display: 'flex',
            alignItems: 'center',

            zIndex: 1,
            padding: '0 !important',
        },
    },
};

export default memo(function InputDropdown({
    label,
    variant = 'body1',
    value,
    selections,
    onChange = () => {},
    width = 100,
    height = 200,
    alignment = 'column',
    loading = false,
    disabled = false,
    required = false,
}: InputDropdownProps) {
    const [selected, setSelected] = useState<IValueLabelPair>(selections[0]);

    const opacity = useMemo(() => (disabled ? 0.5 : 1), [disabled]);
    const pointerEvents = useMemo(() => (disabled ? 'none' : 'auto'), [disabled]);

    const Icon = useMemo(() => (loading ? <CircularProgress size={18} /> : <ChevronDown size={18} />), [loading]);

    useEffect(() => {
        if (value) {
            setSelected(value);
        }
    }, [value]);

    const onChangeHandler = useCallback(
        (e: SelectChangeEvent) => {
            setSelected({ ...selected, value: e.target.value });
            onChange(e);
        },
        [selected, onChange],
    );

    return (
        <FlexColumn width={width} muiStyle={{ opacity: opacity, pointerEvents: pointerEvents }}>
            <Label label={label} variant={variant} width={width} alignment={alignment} required={required}>
                <Select
                    defaultValue=""
                    value={selected.value.toString()}
                    onChange={onChangeHandler}
                    IconComponent={() => Icon}
                    disabled={loading ?? disabled}
                    required={required}
                    inputProps={{ 'aria-label': 'Without label' }}
                    sx={{
                        ...style.selector,
                        width: width,
                        '& svg': {
                            width: variant === 'body1' ? 18 : 12,
                            right: 12,
                            position: 'absolute',
                        },
                    }}
                    MenuProps={{ sx: { height: height } }}
                >
                    {selections?.map(selection => (
                        <MenuItem key={selection?.value} value={selection?.value} disabled={selection?.value === 0}>
                            <Typography variant={variant}>{selection?.label}</Typography>
                        </MenuItem>
                    ))}
                </Select>
            </Label>
        </FlexColumn>
    );
});
