import { memo, useCallback, useEffect, useState } from 'react';
import { Divider, MenuItem, Popover, SxProps } from '@mui/material';
import { Button, Typography } from '@components/index';
import { useDispatch, useSelector } from 'react-redux';
import { userApi } from '@api/modules/apiUsers';
import { IUserModel } from '@models/IUserModel';
import { BorderRadius, FontWeight, Spacing } from '@style/theme';
import { FlexColumn } from '@components/wrappers/Flex/Flex.wrapper';
import { OutlineLogin } from '@assets/icons';
import { useNavigate } from 'react-router';
import { UserActions, setUser } from '@store/user/user.actions';
import { $selectedTeam } from '@store/selected-team/selected-team.selector';
import Avatar from '@components/user/Avatar/Avatar';
import { useUser } from '@hooks/useUser';

export interface UserSelectorProps {
    muiStyle?: SxProps;
}

interface IUserSelection {
    id: string;
    name: string;
    roleId: number;
    role: string;
    email: string;
    profile_pic?: string;
}

export default memo(function UserSelector({ muiStyle }: UserSelectorProps) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { current_user, is_staff } = useUser();

    const selectedTeam = useSelector($selectedTeam);

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const [designerStaff, setDesignerStaff] = useState<IUserSelection[]>([]);
    const [clientStaff, setClientStaff] = useState<IUserSelection[]>([]);

    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            if (is_staff) getAllStaffs();
        }

        return function cleanup() {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current_user, selectedTeam]);

    const onLogin = useCallback(
        async (email: string) => {
            setIsLoggingIn(previousState => true);

            /* Set loading */
            dispatch({ type: UserActions.IS_LOADING, payload: { data: true } });

            const loginResponse = await userApi.login({ email, password: 'test' });

            if (loginResponse?.data) {
                await setUser(dispatch, loginResponse.data);
                navigate('/tasks');
            }

            dispatch({ type: UserActions.IS_LOADING, payload: { data: false } });

            setIsLoggingIn(previousState => false);
        },
        [dispatch, navigate],
    );

    const getUserRoleName = (isClient: boolean, roleId: number) => {
        switch (roleId) {
            case 1:
                return isClient ? 'Client Admin' : 'Designer  Admin';
            case 2:
                return 'Designer Supervisor';
            case 6:
                return 'Success Manager';
            case 3:
            default:
                return 'Member';
        }
    };

    const getAllStaffs = async () => {
        const apiCall = await userApi.getStaffs();

        if (apiCall?.data) {
            const allStaff = apiCall.data.filter((user: IUserModel) => user.is_staff === true);

            const designerStaff = allStaff.filter(
                (user: IUserModel) => user.is_designer === true && user.is_active === true,
            );
            const clientStaff = allStaff.filter(
                (user: IUserModel) => user.is_client === true && user.is_active === true,
            );

            const designerToOptions = designerStaff
                .map((user: IUserModel) => {
                    return {
                        id: user.id,
                        name: user.first_name + ' ' + user.last_name,
                        roleId: user.user_role,
                        role: getUserRoleName(user.is_client!, user.user_role!),
                        email: user.email,
                        profile_pic: user.profile_pic,
                    };
                })
                .sort((a: IUserSelection, b: IUserSelection) => {
                    return a.roleId - b.roleId;
                });

            setDesignerStaff(designerToOptions);

            const clientToOptions = clientStaff
                .map((user: IUserModel) => {
                    return {
                        id: user.id,
                        name: user.first_name + ' ' + user.last_name,
                        roleId: user.user_role,
                        role: getUserRoleName(user.is_client!, user.user_role!),
                        email: user.email,
                        profile_pic: user.profile_pic,
                    };
                })
                .sort((a: IUserSelection, b: IUserSelection) => {
                    return a.roleId - b.roleId;
                });

            setClientStaff(clientToOptions);
        }
    };

    const RenderMenuItem = (user: IUserSelection, i: number) => (
        <MenuItem
            key={user + '-' + i}
            onClick={() => {
                onLogin(user.email);
                setAnchorEl(null);
            }}
            sx={{ borderRadius: BorderRadius.XXS }}
        >
            <Avatar src={user?.profile_pic} round={true} size="md" />
            <FlexColumn>
                <Typography weight={FontWeight.BOLD}>
                    {user.name} ({user.role})
                </Typography>
                <Typography variant="body2">{user?.email}</Typography>
            </FlexColumn>
        </MenuItem>
    );

    return (
        <>
            {is_staff && (
                <Button
                    label="Switch User"
                    startIcon={<OutlineLogin size={14} />}
                    variant={'stroke'}
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                        setAnchorEl(event.currentTarget);
                    }}
                    loading={isLoggingIn}
                    muiStyle={{ width: 160 }}
                />
            )}

            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={() => {
                    setAnchorEl(null);
                }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <FlexColumn padding={Spacing.MD} gap={Spacing.SM}>
                    <Typography variant="h2" weight={FontWeight.BOLD} muiStyle={{ padding: Spacing.MD }}>
                        Designers
                    </Typography>
                    {designerStaff.map((user, i) => RenderMenuItem(user, i))}
                    <Divider />
                    <Typography variant="h2" weight={FontWeight.BOLD} muiStyle={{ padding: Spacing.MD }}>
                        Clients
                    </Typography>
                    {clientStaff.map((user, i) => RenderMenuItem(user, i))}
                </FlexColumn>
            </Popover>
        </>
    );
});
