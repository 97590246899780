import React, { HTMLAttributes, memo, useEffect } from 'react';
import { Box, SxProps, Tooltip } from '@mui/material';
import { Button } from '@components/index';
import { useDropzone } from 'react-dropzone';
import { useDrop } from '@hooks/useDrop';
import { FlexCenter, FlexColumnCenter, FlexColumn } from '@components/wrappers/Flex/Flex.wrapper';
import { OutlineFont, OutlineHeadphone, OutlineFileCheck } from '@assets/icons/index';
import { FONTS_FILETYPE, AUDIO_FILETYPE, IMAGES_FILETYPE } from '@data/defaults';
import { LightGrey, Spacing, BorderRadius } from '@style/theme';
import { IFileRequestModel } from '@models/IFileModel';

export interface ImageUploadProps extends HTMLAttributes<HTMLInputElement> {
    /**
     * Setting files to be uploaded
     */
    setFiles: React.Dispatch<React.SetStateAction<IFileRequestModel[]>>;
    /**
     * Single file upload
     */
    singleFile?: boolean;
    /**
     * Label of the input field
     */
    label?: string;
    /**
     * Width of the input field
     */
    width?: number | string;
    /**
     * Width of the input field
     */
    acceptedFileType?: 'img' | 'pdf' | 'font' | 'music' | 'all';
    /**
     * Direction of the items
     */
    alignment?: 'row' | 'col';
    /**
     * Custom CSS styling
     */
    muiStyle?: SxProps;
}

const getIconType = (ext: string | undefined) => {
    const extension = ext ?? '';
    if (FONTS_FILETYPE.includes(extension)) return <OutlineFont />;
    if (AUDIO_FILETYPE.includes(extension)) return <OutlineHeadphone />;
    if (IMAGES_FILETYPE.includes(extension)) return <OutlineFileCheck />;
    return <OutlineFileCheck />;
};

const thumbnailStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',

    margin: Spacing.XXS,
    width: 84,
    height: 84,

    borderRadius: BorderRadius.XXS,
    boxSizing: 'border-box',

    cursor: 'pointer',
    overflow: 'hidden',

    '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
};

export default memo(function ImageUpload({ setFiles, singleFile = false, width = 280, muiStyle }: ImageUploadProps) {
    const { files, onDrop, removeFile } = useDrop(singleFile);

    const { getRootProps, getInputProps, open } = useDropzone({
        accept: {
            'image/*': IMAGES_FILETYPE,
        },
        onDrop,
        noDrag: true,
        noClick: true,
        maxFiles: 1,
    });

    const thumbs = files?.map((file, i) => {
        const ext = file?.file_name?.split('.').pop();

        return (
            <Box key={file.file_name} sx={{ ...thumbnailStyle }} onClick={() => removeFile(file)}>
                {IMAGES_FILETYPE.includes(ext!) ? (
                    <Box>
                        <img src={file.file_url} alt={`alt-${file.file_name}`} />
                    </Box>
                ) : (
                    getIconType(ext)
                )}
            </Box>
        );
    });

    useEffect(() => {
        if (setFiles) {
            setFiles(files);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    return (
        <FlexColumn gap={Spacing.MD}>
            <FlexColumnCenter minWidth={280} padding={Spacing.MD} boxSizing="border-box" width={width}>
                <FlexCenter
                    width={128}
                    height={128}
                    flexWrap="wrap"
                    minWidth={0}
                    overflow="hidden"
                    border={`2px dashed ${LightGrey.DARK}`}
                    mb={Spacing.MD}
                >
                    {thumbs}
                </FlexCenter>

                <Box {...getRootProps({ className: 'dropzone' })} onClick={e => e.stopPropagation}>
                    <input {...getInputProps()} />
                    <FlexColumnCenter gap={Spacing.XXS}>
                        <Tooltip title="At least 256x256 pixels PNG ot JPG file">
                            <Box>
                                <Button variant="stroke" label="Upload Image" onClick={open} />
                            </Box>
                        </Tooltip>
                    </FlexColumnCenter>
                </Box>
            </FlexColumnCenter>
        </FlexColumn>
    );
});
