import React, { memo, useEffect, useMemo } from 'react';
import { Box, SxProps } from '@mui/material';
import { Typography } from '@components/index';
import { BorderRadius, FontWeight, Primary } from '@style/theme';
import { FlexCenter } from '@components/wrappers/Flex/Flex.wrapper';

export interface OverlayProps {
    open?: boolean;
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    timeout?: number;
    children?: string | React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    muiStyle?: SxProps;
}

const style = {
    root: {
        display: 'inline-block',
        position: 'relative',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
    },
    overlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        borderRadius: BorderRadius.SM,
        overflow: 'hidden',
        backgroundColor: Primary.HIGHLIGHT,
    },
};

export default memo(function Overlay({ open, setOpen, timeout = 2000, children, onClick, muiStyle }: OverlayProps) {
    const styles = useMemo(() => ({ ...style.root, ...muiStyle } as SxProps), [muiStyle]);

    useEffect(() => {
        let timer = setTimeout(() => {
            if (setOpen) setOpen(previousState => false);
        }, timeout);

        return () => {
            clearTimeout(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <Box onClick={onClick} sx={{ ...styles }}>
            {children}

            {open && (
                <FlexCenter muiStyle={{ ...style.overlay }}>
                    <Typography variant="body1" weight={FontWeight.BOLD} color={Primary.DARK}>
                        Copied!
                    </Typography>
                </FlexCenter>
            )}
        </Box>
    );
});
