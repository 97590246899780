import { IMemberModel } from '@models/IMemberModel';
import { ITeam, ITeamRequest, ITeamResponse } from '@models/ITeamModel';
import { ApiService, IRequestState } from '@api/api';
import { IClientResponse } from '@models/IClientModel';

export class TeamApi extends ApiService {
    createTeam = async (data: ITeamRequest): Promise<IRequestState<ITeam>> => {
        return this.$post({
            url: `teams/`,
            data,
        });
    };

    updateTeam = async (teamId: number, data: ITeamRequest): Promise<IRequestState<ITeam>> => {
        return this.$put({
            url: `teams/${teamId}/`,
            data,
        });
    };

    getTeam = async (teamId: number): Promise<IRequestState<ITeamResponse>> => {
        return this.$get({
            url: `teams/${teamId}/`,
        });
    };

    getAllTeams = async (): Promise<IRequestState<ITeamResponse>> => {
        // adding '?limit=2000' as a query param, this is a temporary fix to be able to display 100 items per request.
        return this.$get({
            url: `teams/?limit=7000`,
        });
    };

    getAllClientTeams = async (): Promise<IRequestState<IClientResponse>> => {
        // adding '?limit=2000' as a query param, this is a temporary fix to be able to display 100 items per request.
        return this.$get({
            url: `teams/client/?limit=7000`,
        });
    };

    addMember = async (teamId: number, data: IMemberModel): Promise<IRequestState<IMemberModel>> => {
        return this.$post({
            url: `teams/${teamId}/members/`,
            data,
        });
    };
}

export const teamApi = new TeamApi();
