import React, { ReactNode, memo, useMemo } from 'react';
import { Button, Badge, CircularProgress, SxProps } from '@mui/material';
import { Red } from '@style/theme';
import { BadgeVariants, ButtonTypes } from '@data/types';
import { badgeSize } from '@style/getBadgeSize';
import { badgeVariant } from '@style/getBadgeVariant';
import { badgeLoaderVariant } from '@style/getBadgeLoaderVariant';
import Tooltip from '@components/wrappers/Tooltip/Tooltip.wrapper';

export interface BadgeProps {
    icon?: React.ReactNode;
    type?: ButtonTypes;
    variant?: BadgeVariants;
    count?: number;
    invert?: boolean;
    size?: 'sm' | 'md' | 'lg';
    round?: boolean;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    disabled?: boolean;
    loading?: boolean;
    tooltip?: React.ReactNode;
    muiStyle?: SxProps;
}

type RenderBadgeProps = {
    icon?: ReactNode;
    count?: number
}

const RenderBadge = memo<RenderBadgeProps>(({icon, count}) => {
    if (count === 0) return <Badge sx={{ '& .notification span': { background: Red.MEDIUM } }}>{icon}</Badge>;

    return (
        <Badge variant={'dot'} sx={{ '& .notification span': { background: Red.MEDIUM } }}>
            {icon}
        </Badge>
    );
})

export default memo(function AppBadge({
    icon = 'Button',
    type = 'button',
    count = 0,
    variant = 'primary',
    invert = false,
    size = 'md',
    round,
    onClick = () => {},
    disabled = false,
    loading,
    tooltip,
    muiStyle,
}: BadgeProps) {
    const pointerEvents = useMemo(() => (disabled ? 'none' : 'auto'), [disabled]);

    return (
        <Tooltip tooltip={tooltip} placement="top" arrow={true}>
            <Button
                onClick={onClick}
                type={type}
                disabled={loading ?? disabled}
                sx={{
                    pointerEvents: pointerEvents,
                    borderRadius: round ? '50%' : 'none',
                    ...badgeSize(size),
                    ...badgeVariant(variant, invert),
                    ...muiStyle,
                }}
            >
                {loading ? (
                    <CircularProgress
                        color="warning"
                        size={14}
                        sx={{ ...badgeLoaderVariant(variant), position: 'absolute' }}
                    />
                ) : (
                    <RenderBadge icon={icon} count={count} />
                )}
            </Button>
        </Tooltip>
    );
});
