import React, { memo } from 'react';
import { Box, BoxProps, SxProps } from '@mui/material';

interface FlexProps extends BoxProps {
    children?: React.ReactNode;
    muiStyle?: SxProps;
}

export const AlignStart = memo<FlexProps>(
    React.forwardRef((props, ref) => {
        const { children, muiStyle, ...rest } = props;
        return (
            <Box display="flex" alignItems="flex-start" sx={{ ...muiStyle }} {...rest} ref={ref}>
                {children}
            </Box>
        );
    }),
);

export const AlignCenter = memo<FlexProps>(
    React.forwardRef((props, ref) => {
        const { children, muiStyle, ...rest } = props;
        return (
            <Box display="flex" alignItems="center" sx={{ ...muiStyle }} {...rest} ref={ref}>
                {children}
            </Box>
        );
    }),
);

export const AlignEnd = memo<FlexProps>(
    React.forwardRef((props, ref) => {
        const { children, muiStyle, ...rest } = props;
        return (
            <Box display="flex" alignItems="flex-end" sx={{ ...muiStyle }} {...rest} ref={ref}>
                {children}
            </Box>
        );
    }),
);

export const AlignSpaceBetween = memo<FlexProps>(
    React.forwardRef((props, ref) => {
        const { children, muiStyle, ...rest } = props;
        return (
            <Box display="flex" alignItems="space-between" sx={{ ...muiStyle }} {...rest} ref={ref}>
                {children}
            </Box>
        );
    }),
);

export const AlignSpaceAround = memo<FlexProps>(
    React.forwardRef((props, ref) => {
        const { children, muiStyle, ...rest } = props;
        return (
            <Box display="flex" alignItems="space-around" sx={{ ...muiStyle }} {...rest} ref={ref}>
                {children}
            </Box>
        );
    }),
);

export const AlignSpaceEvenly = memo<FlexProps>(
    React.forwardRef((props, ref) => {
        const { children, muiStyle, ...rest } = props;
        return (
            <Box display="flex" alignItems="space-evenly" sx={{ ...muiStyle }} {...rest} ref={ref}>
                {children}
            </Box>
        );
    }),
);

export const FlexBox = memo<FlexProps>(
    React.forwardRef((props, ref) => {
        const { children, muiStyle, ...rest } = props;
        return (
            <Box display="flex" sx={{ ...muiStyle }} {...rest} ref={ref}>
                {children}
            </Box>
        );
    }),
);

export const FlexColumn = memo<FlexProps>(
    React.forwardRef((props, ref) => {
        const { children, muiStyle, ...rest } = props;
        return (
            <Box display="flex" flexDirection="column" sx={{ ...muiStyle }} {...rest} ref={ref}>
                {children}
            </Box>
        );
    }),
);

export const FlexCenter = memo<FlexProps>(
    React.forwardRef((props, ref) => {
        const { children, muiStyle, ...rest } = props;
        return (
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ ...muiStyle }} {...rest} ref={ref}>
                {children}
            </Box>
        );
    }),
);

export const FlexColumnCenter = memo<FlexProps>(
    React.forwardRef((props, ref) => {
        const { children, muiStyle, ...rest } = props;
        return (
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                sx={{ ...muiStyle }}
                {...rest}
                ref={ref}
            >
                {children}
            </Box>
        );
    }),
);

export const JustifyCenter = memo<FlexProps>(
    React.forwardRef((props, ref) => {
        const { children, muiStyle, ...rest } = props;
        return (
            <Box display="flex" justifyContent="center" sx={{ ...muiStyle }} {...rest} ref={ref}>
                {children}
            </Box>
        );
    }),
);

export const JustifyEnd = memo<FlexProps>(
    React.forwardRef((props, ref) => {
        const { children, muiStyle, ...rest } = props;
        return (
            <Box display="flex" justifyContent="flex-end" sx={{ ...muiStyle }} {...rest} ref={ref}>
                {children}
            </Box>
        );
    }),
);

export const SpaceBetween = memo<FlexProps>(
    React.forwardRef((props, ref) => {
        const { children, muiStyle, ...rest } = props;
        return (
            <Box display="flex" justifyContent="space-between" sx={{ ...muiStyle }} {...rest} ref={ref}>
                {children}
            </Box>
        );
    }),
);

export const SpaceAround = memo<FlexProps>(
    React.forwardRef((props, ref) => {
        const { children, muiStyle, ...rest } = props;
        return (
            <Box display="flex" justifyContent="space-around" sx={{ ...muiStyle }} {...rest} ref={ref}>
                {children}
            </Box>
        );
    }),
);

export const SpaceEvenly = memo<FlexProps>(
    React.forwardRef((props, ref) => {
        const { children, muiStyle, ...rest } = props;
        return (
            <Box display="flex" justifyContent="space-evenly" sx={{ ...muiStyle }} {...rest} ref={ref}>
                {children}
            </Box>
        );
    }),
);

export const JustifyStart = memo<FlexProps>(
    React.forwardRef((props, ref) => {
        const { children, muiStyle, ...rest } = props;
        return (
            <Box display="flex" justifyContent="flex-start" sx={{ ...muiStyle }} {...rest} ref={ref}>
                {children}
            </Box>
        );
    }),
);
