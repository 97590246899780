import { memo } from 'react';
import { FlexBox } from '@components/wrappers/Flex/Flex.wrapper';
import NavInner from './NavInner/NavInner.component';

const style = {
    root: {
        position: 'relative',
        height: '100%',
    },
};

export default memo(function Sidebar() {
    return (
        <FlexBox muiStyle={{ ...style.root }}>
            <NavInner />
        </FlexBox>
    );
});
