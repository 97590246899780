import React, { ChangeEvent, memo, useMemo } from 'react';
import { InputBase, SxProps } from '@mui/material';
import { Border, BorderRadius, DarkGrey, Red, Spacing } from '@style/theme';
import { FlexDirections, InputTypes } from '@data/types';
import { FlexColumn } from '@components/wrappers/Flex/Flex.wrapper';
import Label from '@components/wrappers/Label/Label.wrapper';
import Overline from '@components/typography/Overline/Overline.component';

export interface InputProps {
    name?: string;
    label?: string;
    value?: string;
    type?: InputTypes;
    required?: boolean;
    error?: string;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    width?: number | string;
    height?: number | string;
    maxLength?: number;
    placeholder?: string;
    alignment?: FlexDirections;
    disabled?: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key?: any) => void;
    muiStyle?: SxProps;
}

const style = {
    root: {},
    input: {
        padding: Spacing.MD,
        borderRadius: BorderRadius.XS,
        border: `1px solid ${Border.MEDIUM}`,
    },
    length: {
        position: 'absolute',
        bottom: 0,
        right: 10,
    },
};

export default memo(function Input({
    name,
    label,
    value,
    type,
    required,
    error,
    startIcon,
    endIcon,
    width = '100%',
    height = 40,
    maxLength,
    placeholder = 'Input Placeholder',
    alignment = 'column',
    disabled = false,
    onChange,
    muiStyle,
}: InputProps) {
    const length = useMemo(() => value?.length || 0, [value]);
    const hasExceededMaxLength = useMemo(() => (maxLength ? length >= maxLength : false), [length, maxLength]);

    const lengthTextColor = useMemo(
        () => (hasExceededMaxLength ? Red.MEDIUM : DarkGrey.MEDIUM),
        [hasExceededMaxLength],
    );

    return (
        <FlexColumn width={width} muiStyle={{ ...style.root }}>
            <Label label={label} width={width} alignment={alignment} required={required}>
                <InputBase
                    name={name}
                    value={value}
                    type={type}
                    required={required}
                    onChange={onChange}
                    placeholder={placeholder}
                    disabled={disabled}
                    startAdornment={startIcon}
                    endAdornment={endIcon}
                    inputProps={{ autoComplete: 'new-password' }}
                    sx={{ width: width, height: height, ...style.input, ...muiStyle }}
                />

                {maxLength && (
                    <Overline color={lengthTextColor} muiStyle={{ ...style.length }}>
                        {length}/{maxLength}
                    </Overline>
                )}
            </Label>

            {error && <Overline color={Red.MEDIUM}>{error}</Overline>}
        </FlexColumn>
    );
});
