import { MenuButtonVariants } from '@data/types';
import { DarkGrey, LightGrey } from './theme';

export const getMenuLoaderVariant = (variant: MenuButtonVariants) => {
    switch (variant) {
        case 'light':
            return {
                color: DarkGrey.DARK,
            };
        case 'dark':
            return {
                color: LightGrey.LIGHT,
            };
        default:
            return {};
    }
};
