import React, { memo, useCallback, useState } from 'react';
import { SxProps, Tabs } from '@mui/material';
import { Tab } from '@components/index';

export interface TabButtonGroupProps {
    /**
     * button content
     */
    tabs?: any[];
    /**
     * Optional default Selected tab
     */
    defaultSelected?: number;
    /**
     * Optional, is the button selected
     */
    selected?: boolean;
    /**
     * Optional, custom styling
     */
    muiStyle?: SxProps;
}

export default memo(function TabButtonGroup({ tabs, defaultSelected = 0, muiStyle }: TabButtonGroupProps) {
    const [clickedId, setClickedId] = useState<number>(defaultSelected);

    const onChangeTabs = useCallback((e: React.SyntheticEvent, selectedId: number) => {
        setClickedId(selectedId);
    }, []);

    return (
        <Tabs value={clickedId === undefined ? false : clickedId} onChange={onChangeTabs} sx={{ ...muiStyle }}>
            {tabs?.map((tab, i) => (
                <Tab
                    key={'tab-button-' + i}
                    label={tab.label}
                    startIcon={tab.startIcon}
                    endIcon={tab.endIcon}
                    onClick={e => {
                        if (tab.onClick) {
                            tab.onClick(e);
                        }
                        onChangeTabs(e, i);
                    }}
                    loading={tab.loading}
                    disabled={tab.disabled}
                    muiStyle={{ ...tab.muiStyle }}
                />
            ))}
        </Tabs>
    );
});
