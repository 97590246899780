import { IMemberModel } from '@models/IMemberModel';

export enum SelectedMembersActions {
    SET_MEMBERS = 'SET_MEMBERS',
    UPDATE_MEMBERS = 'UPDATE_MEMBERS',
    CLEAR_MEMBERS = 'CLEAR_MEMBERS',
}

export const setSelectedDesigners = (dispatch: any, members: IMemberModel[]) => {
    dispatch({ type: SelectedMembersActions.SET_MEMBERS, payload: { data: [...members] } });
};

export const clearMembers = (dispatch: any) => {
    dispatch({ type: SelectedMembersActions.CLEAR_MEMBERS, payload: { data: [] } });
};
