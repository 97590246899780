import { IconProps } from '.';

export function OutlineData({ fill = '#3F3F3F', size = 20, muiStyle }: IconProps) {
    return (
        <svg width={size} height={size} style={{ ...muiStyle }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path
                fill={fill}
                d="M9.253 4.93c1.634-0.818 4.018-1.374 6.747-1.374s5.111 0.556 6.747 1.374c1.742 0.871 2.142 1.744 2.142 2.181s-0.4 1.31-2.142 2.181c-1.634 0.818-4.018 1.374-6.747 1.374s-5.111-0.556-6.747-1.374c-1.742-0.871-2.142-1.744-2.142-2.181s0.4-1.31 2.142-2.181zM24.889 12.178v3.824c0 0.437-0.4 1.308-2.142 2.18-1.634 0.818-4.018 1.374-6.747 1.374s-5.111-0.556-6.747-1.374c-1.742-0.871-2.142-1.744-2.142-2.181v-3.822l-0.054-0.029c0.177 0.103 0.358 0.201 0.606 0.324 2.226 1.113 5.177 1.749 8.338 1.749s6.112-0.636 8.338-1.749l0.065-0.030c0.183-0.093 0.364-0.192 0.486-0.265zM28.444 15.998v-8.887c0-2.508-1.988-4.302-4.107-5.362-2.226-1.113-5.177-1.749-8.338-1.749s-6.112 0.636-8.338 1.749c-2.119 1.060-4.107 2.855-4.107 5.362v17.778c0 2.508 1.988 4.302 4.107 5.362 2.226 1.113 5.177 1.749 8.338 1.749s6.112-0.636 8.338-1.749c2.119-1.060 4.107-2.855 4.107-5.362v-8.891zM24.889 21.067v3.822c0 0.437-0.4 1.31-2.142 2.181-1.634 0.818-4.018 1.374-6.747 1.374s-5.111-0.556-6.747-1.374c-1.742-0.871-2.142-1.744-2.142-2.181v-3.822l-0.054-0.029c0.177 0.103 0.358 0.201 0.605 0.324 2.226 1.113 5.177 1.749 8.338 1.749s6.112-0.636 8.338-1.749l0.064-0.030c0.183-0.093 0.364-0.192 0.487-0.265z"
            />
        </svg>
    );
}
