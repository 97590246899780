import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastOptions } from 'react-toastify';
import { FlexColumn } from '@components/wrappers/Flex/Flex.wrapper';
import { DarkGrey, Spacing } from '@style/theme';
import { Typography } from '@components/index';
import Title from '@components/typography/Title/Title.component';

export interface ToastProps {
    /**
     * title
     */
    title?: string | React.ReactNode;
    /**
     * toast content
     */
    message?: string | React.ReactNode;
    /**
     *  position of the toast
     */
    position?: ToastOptions['position'];
    /**
     *  type of toast
     */
    type?: ToastOptions['type'];
    /**
     *  auto close timer
     */
    autoClose?: ToastOptions['autoClose'];
    /**
     *  should close on click
     */
    closeOnClick?: ToastOptions['closeOnClick'];
    /**
     * should hide progress bar
     */
    hideProgressBar?: ToastOptions['hideProgressBar'];
}

interface DefaultToastProps {
    title?: string;
    message: string | any[];
}

export const ErrorCodes = {
    400: 'Bad Request:',
    401: 'Unauthorized:',
    403: 'Forbidden:',
    404: 'Data not found:',
    500: 'Internal Server Error:',
};

export const CustomToast = ({
    title,
    message = 'This is a toast message',
    type = 'info',
    position = 'bottom-right',
    autoClose = 3000,
    closeOnClick = true,
    hideProgressBar = false,
}: ToastProps) => {
    const TOAST_OPTIONS: ToastOptions = {
        type: type,
        position: position,
        autoClose: autoClose,
        hideProgressBar: hideProgressBar,
        closeOnClick: closeOnClick,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
            padding: 10,
        },
    };

    return toast(
        <>
            <FlexColumn gap={Spacing.SM}>
                <Title variant="h3">{title}</Title>
                <Typography color={DarkGrey.MEDIUM} prewrap={true}>
                    {message}
                </Typography>
            </FlexColumn>
        </>,
        TOAST_OPTIONS,
    );
};

export const Toast = {
    success: ({ message, title }: DefaultToastProps) => {
        CustomToast({ title, message, type: 'success' });
    },
    info: ({ message, title }: DefaultToastProps) => {
        CustomToast({ title, message, type: 'info' });
    },
    warning: ({ message, title }: DefaultToastProps) => {
        CustomToast({ title, message, type: 'warning' });
    },
    error: ({ message, title }: DefaultToastProps) => {
        if (message) {
            if (typeof message === 'string') {
                CustomToast({ title, message, type: 'error' });
                return;
            }
            if (Array.isArray(message)) {
                message.forEach((error: { [key: string]: string }) => {
                    const errorMessage = Object.values(error)[0];
                    const errorCode = Object.keys(error)[0];

                    const errorTitleFormatted = errorCode
                        .split('_')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ');

                    CustomToast({ title, message: `${errorTitleFormatted}: ${errorMessage}`, type: 'error' });
                });
            }
        }
    },
};

export default Toast;
