import React, { memo } from 'react';
import { SxProps } from '@mui/material';
import { Typography } from '@components/index';
import { BorderRadius, FontWeight, LightGrey, Spacing, WHITE } from '@style/theme';
import { FlexColumnCenter } from '@components/wrappers/Flex/Flex.wrapper';

export interface CustomCardProps {
    /**
     * Optional, child content
     */
    children?: string | React.ReactNode;
    /**
     * Optional, section title
     */
    title?: string;
    /**
     * Optional, width
     */
    maxWidth?: string | number;
    /**
     * Optional, on click
     */
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    /**
     * Optional, custom styling
     */
    muiStyle?: SxProps;
}

export default memo(function CustomCard({ title, maxWidth = 100, onClick, children, muiStyle }: CustomCardProps) {
    return (
        <FlexColumnCenter
            onClick={onClick}
            width="100%"
            maxWidth={maxWidth}
            gap={Spacing.LG}
            padding={Spacing.XL}
            borderRadius={BorderRadius.XS}
            border={`1px solid ${LightGrey.DARK}`}
            bgcolor={WHITE}
            boxShadow="0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%)"
            sx={{ ...muiStyle }}
        >
            {title && (
                <Typography variant="h1" weight={FontWeight.BOLD} muiStyle={{ mb: Spacing.LG }}>
                    {title}
                </Typography>
            )}
            {children}
        </FlexColumnCenter>
    );
});
