import { initialState } from '@store/root-reducer';
import { IActionModel } from '@models/IActionModel';
import { IUsersModel } from '@models/IUsersModel';
import { UsersActions } from '@store/users/users.actions';

export const usersReducer = (state: IUsersModel = initialState.users, action: IActionModel) => {
    switch (action.type) {
        case UsersActions.SET_DESIGNERS: {
            return { ...state, designers: action.payload.data };
        }
        case UsersActions.SET_CLIENTS: {
            return { ...state, clients: action.payload.data };
        }
        default:
            return state;
    }
};
export default usersReducer;
