import React from 'react';

export const toBreakline = (str: string): React.ReactNode => {
    return str.split('\n').map((part, index) => (
        <span key={index}>
            {part}
            <br />
        </span>
    ));
};
