import { memo, useCallback } from 'react';
import Popover from '@components/wrappers/Popover/Popover.wrapper';
import { Typography, UserInfo } from '@components/index';
import { Spacing } from '@style/theme';
import { OutlineUserCircle } from '@assets/icons';
import Avatar from '@components/user/Avatar/Avatar';
import { useUser } from '@hooks/useUser';
import { MenuItem } from '@mui/material';
import { AlignCenter } from '@components/wrappers/Flex/Flex.wrapper';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { isInIframe } from '@helper/isInIframe';
import { clearStorage } from '@utils/clearStorage';

interface PopoverUserProps {
    callback?: () => void;
}

export default memo(function PopoverUser({ callback }: PopoverUserProps) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { full_name, profile_pic } = useUser();

    const handleLogout = useCallback(() => {
        clearStorage(dispatch);
        navigate('/login');
    }, [dispatch, navigate]);

    return (
        <Popover
            label={full_name}
            triggerButton="menu"
            startIcon={<Avatar src={profile_pic} fallbackIcon={<OutlineUserCircle />} />}
            padding={Spacing.MD}
            transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <UserInfo />

            {!isInIframe() && (
                <MenuItem onClick={handleLogout}>
                    <AlignCenter gap={Spacing.SM}>
                        <Typography variant="body2">Logout</Typography>
                    </AlignCenter>
                </MenuItem>
            )}
        </Popover>
    );
});
