import React, { memo, useMemo } from 'react';
import { Button, CircularProgress, SxProps } from '@mui/material';
import { FontWeight, Spacing } from '@style/theme';
import Tooltip from '@components/wrappers/Tooltip/Tooltip.wrapper';
import { Typography } from '@components/index';
import { ButtonVariants } from '@data/types';
import { buttonVariant } from '@style/getButtonVariant';
import { buttonLoaderVariant } from '@style/getButtonLoaderVariant';

export interface ButtonProps {
    ref?: React.Ref<HTMLButtonElement>;
    label: string | React.ReactNode;
    type?: 'button' | 'submit' | 'reset';
    variant?: ButtonVariants;
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    loading?: boolean;
    disabled?: boolean;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    tooltip?: string;
    width?: string | number;
    muiStyle?: SxProps;
}

const style = {
    root: {},
    loader: { position: 'absolute' },
    label: { my: 0, mx: Spacing.SM },
};

export default memo(function AppButton({
    ref,
    label,
    type = 'button',
    variant = 'primary',
    onClick,
    loading = false,
    disabled = false,
    startIcon,
    endIcon,
    tooltip,
    width,
    muiStyle,
}: ButtonProps) {
    const isString = useMemo(() => typeof label === 'string', [label]);

    return (
        <Tooltip tooltip={tooltip} placement="top" arrow={true}>
            <Button
                ref={ref}
                type={type}
                disabled={loading || disabled}
                onClick={onClick}
                startIcon={startIcon}
                endIcon={endIcon}
                sx={{ width: width, ...buttonVariant(variant), ...muiStyle }}
            >
                {loading && (
                    <CircularProgress size={18} sx={{ ...buttonLoaderVariant(variant), ...style.loader } as SxProps} />
                )}

                {isString ? (
                    <Typography variant="body1" weight={FontWeight.BOLD} muiStyle={{ ...style.label }}>
                        {label}
                    </Typography>
                ) : (
                    <>{label}</>
                )}
            </Button>
        </Tooltip>
    );
});
