import { memo } from 'react';
import { AlignCenter, FlexColumn } from '@components/wrappers/Flex/Flex.wrapper';
import { Typography } from '@components/index';
import { FontWeight, Spacing } from '@style/theme';
import Avatar from '@components/user/Avatar/Avatar';
import { OutlineUserCircle } from '@assets/icons';
import { useUser } from '@hooks/useUser';

export interface UserInfoProps {
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const style = {
    root: {
        gap: Spacing.MD,
        padding: Spacing.MD,
    },
};

export default memo(function UserInfo({ onClick = () => {} }: UserInfoProps) {
    const { full_name, profile_pic, user_email } = useUser();

    return (
        <AlignCenter onClick={onClick} muiStyle={{ ...style.root }}>
            <Avatar src={profile_pic} fallbackIcon={<OutlineUserCircle size={32} />} round={true} size="md" />
            <FlexColumn>
                <Typography weight={FontWeight.BOLD}>{full_name}</Typography>
                <Typography variant="body2">{user_email}</Typography>
            </FlexColumn>
        </AlignCenter>
    );
});
