import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import _ from 'lodash';
import Cookies from 'js-cookie';
import { Toast } from '@components/index';

/*eslint-disable-next-line*/
export interface IRequestState<T> {
    data?: any;
    status?: number;
    error?: any;
    time?: string;
}

type ApiCallOptions = {
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
    url: string;

    headers?: Record<string, string>;
    data?: any;
    abortKey?: string;
    timeout?: number;
};

export const createSuccess = <T extends any>(data: T): IRequestState<T> => {
    return {
        status: 200,
        data,
    };
};

export const createError = <T extends any>(error: any): IRequestState<T> => {
    return {
        error,
    };
};

export const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
    /*
     *  Axios Request Instance
     */
    axiosInstance.interceptors.request.use(
        async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
            const token = Cookies.get('access_token');

            const noToken = _.isEmpty(token);

            if (noToken) {
                return config;
            } else {
                config.headers['Authorization'] = `Bearer ${token}`;
            }

            return config;
        },
        (error: AxiosError): Promise<AxiosError> => {
            return Promise.reject(error);
        },
    );
    /*
     *  Axios Response Instance
     */
    axiosInstance.interceptors.response.use(
        (response: AxiosResponse): AxiosResponse => {
            return response;
        },
        async function (error: AxiosError): Promise<AxiosError | AxiosRequestConfig> {
            if (error.response) {
                // This keeps the original request
                const originalRequest = error.config;

                if (
                    error.response.status === 401 &&
                    error.response?.data?.error?.details === 'Authentication credentials were not provided.'
                ) {
                    const storedToken = Cookies.get('refresh_token');

                    try {
                        /** Dynamic setting of API depending on the docker. */
                        const apiCall = await axios.post(`${process.env.REACT_APP_BASE_URL}token/refresh/`, {
                            refresh: storedToken,
                        });

                        Cookies.set('access_token', apiCall.data.access);

                        originalRequest.headers['Authorization'] = `Bearer ${apiCall.data.access}`;

                        return axios(originalRequest);
                    } catch (_error: any) {
                        Cookies.remove('access_token');
                        Cookies.remove('refresh_token');

                        window.location.href = '/login';

                        return Promise.resolve(_error);
                    }
                }
            }
            return Promise.reject(error);
        },
    );

    return axiosInstance;
};

export class ApiService {
    private async callApi({ method, url, data, headers }: ApiCallOptions): Promise<IRequestState<any>> {
        return api({
            method,
            url,
            headers,
            data,
        })
            .then((response: AxiosResponse) => {
                return createSuccess(response.data);
            })
            .catch((error: AxiosError) => {
                if (error.response?.data?.error?.details) {
                    Toast.error({ message: error.response.data.error.details });
                }
                return createError(error.response?.data);
            });
    }

    serializeQueryParams<T extends Record<string, any>>(obj?: T, prefix?: string) {
        const str: string[] = [];

        for (const p in obj) {
            if (obj.hasOwnProperty(p)) {
                const key = prefix ? prefix + '[' + p + ']' : p;
                const value = obj[p] as string | number | boolean;

                if (value !== null) {
                    if (typeof value === 'object') {
                        if (Object.keys(value).length) {
                            str.push(encodeURIComponent(key) + '=' + encodeURIComponent((value as string[]).join(',')));
                        }
                    } else {
                        str.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
                    }
                }
            }
        }

        return str.filter(s => s !== '').join('&');
    }

    $post(options: Omit<ApiCallOptions, 'method'>) {
        return this.callApi({
            ...options,
            method: 'POST',
        });
    }
    $get(options: Omit<ApiCallOptions, 'method'>) {
        return this.callApi({
            ...options,
            method: 'GET',
        });
    }
    $put(options: Omit<ApiCallOptions, 'method'>) {
        return this.callApi({
            ...options,
            method: 'PUT',
        });
    }
    $patch(options: Omit<ApiCallOptions, 'method'>) {
        return this.callApi({
            ...options,
            method: 'PATCH',
        });
    }
    $delete(options: Omit<ApiCallOptions, 'method'>) {
        return this.callApi({
            ...options,
            method: 'DELETE',
        });
    }
}

const api = setupInterceptorsTo(
    axios.create({
        /** Dynamic setting of API depending on the docker. */
        baseURL: process.env.REACT_APP_BASE_URL,

        headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
        },
    }),
);

export default api;
