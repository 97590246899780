import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DEFAULT_BRANDKIT_SELECTOR, DEFAULT_TASK_REQUEST } from '@data/defaults';
import { ITaskRequest, ITask } from '@models/ITaskModel';
import { upsertTask } from '@store/tasks/tasks.action';
import { Dropzone, Input, InputDropdown, TextArea } from '@components/index';
import { IValueLabelPair } from '@data/interfaces';
import { Spacing } from '@style/theme';
import { useDispatch, useSelector } from 'react-redux';
import PriorityLabelGroupComponent from './PriorityLabelGroup/PriorityLabelGroup.component';
import ClientSelector from './ClientSelector/ClientSelector.component';
import { $selectRefreshTasks } from '@store/refresh/refresh.selector';
import { IFileRequestModel } from '@models/IFileModel';
import { setBrandkitSelections } from '@store/brandkit/brandkit.actions';
import { shouldRefreshTasks } from '@store/refresh/refresh.action';
import Modal from '@components/wrappers/Modal/Modal.wrapper';
import { FlexBox, FlexColumn } from '@components/wrappers/Flex/Flex.wrapper';
import Label from '@components/wrappers/Label/Label.wrapper';
import { useUser } from '@hooks/useUser';
import Scroller from '@layout/Scroller/Scroller';
import { useNavigate } from 'react-router';

export interface TaskRequestModalProps {
    title: string;
    task?: ITask | null;
    onClose?: () => void;
}

const MAX_LENGTH = 100;

const style = {
    root: {},
    scroll: {},
    wrapper: {
        gap: Spacing.LG,
        padding: Spacing.LG,
        pb: 0,
    },
    column: {
        flex: 1,
        gap: Spacing.LG,
    },
};

export default memo(function TaskRequestModal({
    title = 'Create Task Modal Component',
    task,
    onClose,
}: TaskRequestModalProps) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const submitRef = useRef<HTMLButtonElement>(null);

    const { current_user, is_client, is_designer_admin, is_designer_supervisor, permissions } = useUser();

    const canAddTask = useMemo(
        () => permissions?.includes('can_add_tasks') || permissions?.includes('can_add_tasks_for_clients'),
        [permissions],
    );

    const mode = useMemo(() => (task ? 'edit' : 'add'), [task]);

    const reloadTasks = useSelector($selectRefreshTasks);

    const [loadTask, setLoadTask] = useState(false);
    const [taskRequest, setTaskRequest] = useState<ITaskRequest>(task ?? DEFAULT_TASK_REQUEST);

    const [loadBrandkit, setLoadBrandkit] = useState(false);
    const [brandkitOptions, setBrandkitOptions] = useState<IValueLabelPair[]>([DEFAULT_BRANDKIT_SELECTOR]);

    // const [selectedTeam, setSelectedTeam] = useState<number>(0);
    const [selectedBrandkit, setSelectedBrandkit] = useState<number>(brandkitOptions[0].value as number);

    const [files, setFiles] = useState<IFileRequestModel[]>([]);

    const [error, setError] = useState<string | undefined>(undefined);

    const modalHeight = useMemo(() => {
        if (is_client) {
            if (error) return 596;
            return 576;
        } else {
            if (error) return 698;
            return 668;
        }
    }, [is_client, error]);

    useEffect(() => {
        if (canAddTask && current_user && current_user.is_client && current_user.user_team) {
            getBrandkitSelections(current_user?.user_team);
        }

        if (task) {
            setTaskRequest(task);

            if (canAddTask && current_user && current_user.is_designer && task.task_client_team) {
                getBrandkitSelections(task?.task_client_team);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [task, current_user, canAddTask]);

    const getBrandkitSelections = async (teamId: number) => {
        setTaskRequest(previousTaskRequest => ({
            ...previousTaskRequest,
            task_client_team: teamId,
            client_team_id: teamId,
        }));

        const brandkits = await setBrandkitSelections(teamId);
        setBrandkitOptions(brandkits);
    };

    const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;

        if (name === 'name') value.length > MAX_LENGTH ? setError('Too long title') : setError(undefined);

        setTaskRequest(previousTaskRequest => ({ ...previousTaskRequest, [name]: value }));
    }, []);

    const onSubmit = useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            const task_brand_kit = selectedBrandkit === 0 ? undefined : selectedBrandkit;

            const request = {
                ...taskRequest,
                task_brand_kit,
                custom_input: {},
                file_types_allowed: { data: ['graphics'] },
            };

            setLoadTask(true);

            const response = await upsertTask(dispatch, mode, request, files, navigate);

            if (response) {
                shouldRefreshTasks(dispatch, reloadTasks);
                onClose?.();
            }

            setLoadTask(false);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [taskRequest, selectedBrandkit, files, dispatch, onClose],
    );

    const handleCancel = useCallback(() => onClose?.(), [onClose]);

    return (
        <Modal
            title={title}
            titleVariant="h3"
            width="70%"
            confirmButton={{
                label: 'Save',
                loading: loadTask,
                type: 'submit',
                width: '25%',
                onClick: () => submitRef?.current?.click(),
            }}
            cancelButton={{
                label: 'Back',
                variant: 'flat',
                width: '25%',
                onClick: () => handleCancel(),
            }}
            buttonAlignment="end"
        >
            <Scroller height={modalHeight} maxHeight={modalHeight}>
                <form onSubmit={onSubmit} autoComplete="off">
                    <FlexBox muiStyle={{ ...style.wrapper }}>
                        <FlexColumn muiStyle={{ ...style.column }}>
                            {(is_designer_admin || is_designer_supervisor) && (
                                <ClientSelector
                                    clientTeam={taskRequest?.task_client_team}
                                    setLoading={setLoadBrandkit}
                                    callback={getBrandkitSelections}
                                    disabled={mode === 'edit'}
                                />
                            )}

                            <Input
                                label="Name of your request"
                                value={taskRequest?.name}
                                name="name"
                                placeholder="Give a name"
                                onChange={onChange}
                                required
                                maxLength={MAX_LENGTH}
                                error={error}
                            />

                            <TextArea
                                label="Description"
                                value={taskRequest?.description}
                                name="description"
                                placeholder="Type the description of the task"
                                width="100%"
                                onChange={onChange}
                                required
                            />

                            <Dropzone
                                label="Attachments"
                                setFiles={dropzoneFiles => setFiles(previousFiles => dropzoneFiles)}
                                width="100%"
                            />
                        </FlexColumn>
                        <FlexColumn muiStyle={{ ...style.column }}>
                            <InputDropdown
                                label="Brand kit"
                                value={
                                    brandkitOptions.find(brandkit => brandkit.value === task?.task_brand_kit) ??
                                    brandkitOptions[0]
                                }
                                selections={brandkitOptions}
                                placeholder="Select the brand kit"
                                width="100%"
                                onChange={e => setSelectedBrandkit(parseInt(e.target.value))}
                                disabled={loadBrandkit}
                            />
                            <Label label="Priority">
                                <PriorityLabelGroupComponent
                                    priority={taskRequest.client_priority}
                                    onClick={(_, value) =>
                                        setTaskRequest({
                                            ...taskRequest,
                                            client_priority: value,
                                        })
                                    }
                                />
                            </Label>
                        </FlexColumn>
                    </FlexBox>

                    {/* invisible button to trigger onSubmit form event */}
                    <button ref={submitRef} type="submit" style={{ display: 'none' }} />
                </form>
            </Scroller>
        </Modal>
    );
});
