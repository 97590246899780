import React, { memo } from 'react';
import { Button, CircularProgress, SxProps } from '@mui/material';
import { Typography } from '@components/index';
import { Spacing, BorderRadius } from '@style/theme';
import { smallButtonVariant } from '@style/getSmallButtonVariant';
import Tooltip from '@components/wrappers/Tooltip/Tooltip.wrapper';
import { SmallButtonVariants } from '@data/types';

export interface SmallButtonProps {
    label?: string | React.ReactNode;
    variant?: SmallButtonVariants;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    invert?: boolean;
    width?: number | string;
    loading?: boolean;
    disabled?: boolean;
    tooltip?: string;
    muiStyle?: SxProps;
}

const style = {
    root: {
        height: 24,
        px: Spacing.MD,
        py: Spacing.XS,

        boxSize: 'border-box',
        borderRadius: BorderRadius.XXS,
        border: `1px solid transparent`,
    },
    label: {
        my: 0,
        mx: Spacing.SM,
    },
};

export default memo(function SmallButton({
    label = 'Label',
    variant = 'primary',
    invert = false,
    width,
    onClick = () => {},
    startIcon,
    endIcon,
    loading = false,
    disabled = false,
    tooltip,
    muiStyle,
}: SmallButtonProps) {
    return (
        <Tooltip tooltip={tooltip} placement="top" arrow={true}>
            <Button
                disabled={loading || disabled}
                onClick={onClick}
                startIcon={loading ? <CircularProgress size={12} /> : startIcon}
                endIcon={endIcon}
                sx={{ ...style.root, ...smallButtonVariant(variant, invert), ...muiStyle, width: width }}
            >
                <Typography variant="body2" muiStyle={{ ...style.label }}>
                    {label}
                </Typography>
            </Button>
        </Tooltip>
    );
});
