import { IconProps } from '.';

export function OutlineFolder({ fill = '#0D182F', size = 20, muiStyle }: IconProps) {
    return (
        <svg width={size} height={size} style={{ ...muiStyle }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path
                fill={fill}
                d="M3.2 1.6h8.938c0.884 0 1.683 0.358 2.262 0.938l2.262 2.262h12.138c1.767 0 3.2 1.433 3.2 3.2v19.2c0 1.767-1.433 3.2-3.2 3.2h-25.6c-1.767 0-3.2-1.433-3.2-3.2v-22.4c0-1.767 1.433-3.2 3.2-3.2zM3.2 8v19.2h25.6v-19.2h-25.6z"
            ></path>
        </svg>
    );
}

export function OutlineFileBlank({ fill = '#0D182F', size = 24, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...muiStyle }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.9981 2L6 2C4.89543 2 4 2.89543 4 4L4 20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V8.79187C20 8.28803 19.8098 7.80275 19.4675 7.43305L15.0306 2.64119C14.6521 2.23239 14.1202 2 13.5631 2L13.0019 2C13.0012 2 13.0006 2 13 2C12.9994 2 12.9988 2 12.9981 2ZM17.2668 8L14 8V4.47187L17.2668 8ZM18 10L18 20H6L6 4L12 4V8C12 9.10457 12.8954 10 14 10L18 10Z"
                fill={fill}
            />
        </svg>
    );
}

export function OutlineFileCheck({ fill = '#0D182F', size = 20, muiStyle }: IconProps) {
    return (
        <svg width={size} height={size} style={{ ...muiStyle }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path
                fill={fill}
                d="M17.597 0h-11.197c-1.767 0-3.2 1.433-3.2 3.2v0 25.6c0 1.767 1.433 3.2 3.2 3.2v0h19.2c1.767 0 3.2-1.433 3.2-3.2v0-17.933c-0-0.841-0.324-1.605-0.855-2.176l0.002 0.002-7.098-7.667c-0.586-0.632-1.421-1.026-2.348-1.026-0 0-0.001 0-0.001 0h-0.898c-0.001 0-0.002 0-0.003 0s-0.002 0-0.003 0h0zM24.427 9.6h-5.227v-5.645l5.227 5.645zM25.6 12.8v16h-19.2v-25.6h9.6v6.4c0 1.767 1.433 3.2 3.2 3.2v0h6.4zM21.931 16.469c0.289 0.29 0.468 0.689 0.468 1.131s-0.179 0.842-0.468 1.131l-6.4 6.4c-0.29 0.289-0.689 0.468-1.131 0.468s-0.842-0.179-1.131-0.468l-3.2-3.2c-0.301-0.291-0.488-0.699-0.488-1.151 0-0.884 0.716-1.6 1.6-1.6 0.452 0 0.86 0.187 1.15 0.488l2.069 2.069 5.269-5.269c0.29-0.289 0.689-0.468 1.131-0.468s0.842 0.179 1.131 0.468v0z"
            ></path>
        </svg>
    );
}

export function OutlineCloudDownload({ fill = '#0D182F', size = 24, muiStyle }: IconProps) {
    return (
        <svg width={size} height={size} style={{ ...muiStyle }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path
                fill={fill}
                d="M6.707 10.77c1.866-3.27 5.33-5.438 9.301-5.438 4.973 0 9.151 3.401 10.336 8.003l0.016 0.074c3.214 0.516 5.639 3.27 5.639 6.589 0 3.679-2.978 6.662-6.655 6.669h-17.337c-0.002 0-0.004 0-0.006 0-4.419 0-8.002-3.582-8.002-8.002 0-3.962 2.88-7.252 6.66-7.89l0.047-0.007zM16.009 7.998c-0.001 0-0.002 0-0.003 0-3.178 0-5.923 1.853-7.215 4.537l-0.021 0.048c-0.206 0.429-0.624 0.727-1.113 0.76l-0.004 0c-2.798 0.182-4.998 2.496-4.998 5.324 0 2.946 2.388 5.334 5.334 5.334 0.006 0 0.013 0 0.019-0h17.336c2.194-0.020 3.966-1.804 3.966-4.001 0-2.21-1.791-4.001-4.001-4.001-0.012 0-0.025 0-0.037 0h0.002c-0.007 0-0.016 0-0.024 0-0.67 0-1.224-0.494-1.319-1.137l-0.001-0.007c-0.58-3.899-3.905-6.857-7.92-6.857h-0zM14.676 15.823l-1.927 1.286c-0.208 0.14-0.464 0.224-0.74 0.224-0.737 0-1.334-0.597-1.334-1.334 0-0.461 0.234-0.867 0.589-1.107l0.005-0.003 4.001-2.667c0.208-0.14 0.464-0.224 0.74-0.224s0.532 0.084 0.745 0.227l-0.005-0.003 4.001 2.667c0.36 0.243 0.594 0.649 0.594 1.11 0 0.737-0.597 1.334-1.334 1.334-0.276 0-0.532-0.084-0.745-0.227l0.005 0.003-1.927-1.286v5.51c0 0.737-0.597 1.334-1.334 1.334s-1.334-0.597-1.334-1.334v0-5.51z"
            ></path>
        </svg>
    );
}
