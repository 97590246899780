import { Priorities, Statuses, SubscriptionStatus } from '@data/types';
import { IFileResponseModel } from './IFileModel';

export interface ITaskModel {
    filters: ITaskFilters;
    data: ITaskResponse;
    shouldNotify: boolean;
}

export enum TaskFilterCategory {
    open = 'open',
    closed = 'closed',
    inProgress = 'in_progress',
}

export interface ITaskFilters {
    limit: number;
    page: number;
    sort_by: string;
    client_team_ids: number[];
    client_ids: number[];
    designer_ids: number[];
    status: Statuses[];
    category: TaskFilterCategory;
    subscription_status: SubscriptionStatus;
    searchTerm: string;
}

export interface ITaskResponse {
    limit?: number;
    next?: string;
    previous?: string;
    results: {
        total_opened_task?: number;
        total_closed_task?: number;
        total_in_progress_task?: number;
        data: ITask[];
    };
    total_count?: number;
    total_page?: number;
}

export interface ITaskRequest {
    name: string; // name of task
    description: string; // description of task
    status: Statuses; // status of task
    client_team_id?: number; // client team id
    task_client_team?: number; // client team id
    client_priority: Priorities; // client priority
    task_brand_kit?: number; // brand kit id

    // not used yet.
    custom_input?: object;
    file_types_allowed?: { data: string[] };
}

export interface TaskAsignee {
    fullname: string;
    profile_pic?: string;
}

export type MediaType = 'image' | 'video' | 'carousel';
export type VideoType = 'video_long' | 'video_medium' | 'video_short';

export type MediaFiles = {
    aspect_ratio?: number;
    duration?: number;
    hash?: string;
    height?: number;
    url: string;
    type?: VideoType;
};

export interface IAdInspiration {
    id: string;
    brand_name?: string;
    brand_page?: {
        logo: string;
    };
    headline?: string;
    ad_copy?: string;
    added_at?: string;
    created_at?: string;
    call_to_action?: string;
    media: {
        media_files: MediaFiles[];
        type: MediaType;
    };
    target?: {
        url?: string;
    };
}

export interface ITask {
    id: number;
    name: string;
    description: string;
    status: Statuses;
    client_team_id?: number;
    client_priority: Priorities;
    task_brand_kit?: number;

    files?: IFileResponseModel[];

    task_client_team?: number;
    task_assignees?: number[];
    task_created_by?: number;
    task_created_on?: number;
    task_followers?: number[];
    task_service?: number;
    task_updated_at?: number;
    task_updated_by?: number;
    task_due_date?: number;

    team_name?: string;
    designer_priority?: Priorities;

    assignee_teams?: number[];
    assignees_data?: TaskAsignee[];

    creator_name?: string;
    creator_email?: string;
    creator_is_staff?: boolean;

    client_last_update?: string;
    client_last_update_in_hours?: string;

    total_comments?: number;
    total_unread_comments?: number;
    total_files?: number;
    is_tagged?: boolean;
    is_fake?: boolean;

    // ad inspiration
    ads_inspiration_ads: IAdInspiration[];

    // not used yet.
    custom_input?: object; //fields depend on input_fields defined in Service that client is subscribed to
    file_types_allowed?: { data: string[] }; //array of file types allowed for this Task
}
