import { useCallback, useEffect, useRef } from 'react';
import createObserver from '@utils/createObserver';

type Properties<T> = keyof T;

export const MADGICX_MESSAGES = {
    GET_TASK_ID: 'GET_TASK_ID',
    HAS_SPARKLE_SUBSCRIPTION: 'HAS_SPARKLE_SUBSCRIPTION',
};

// IMPORTANT SECURITY INFORMATION
// Read more -> https://developer.mozilla.org/en-US/docs/Web/API/Window/postMessage#notes
const IFRAME = {
    SAME_ORIGIN: '/', // This default restricts the message to same-origin targets only.
    MADGICX: 'https://app.madgicx.com',
    STAGING: 'https://staging-devx-2.k8s.madgicx.com',
    LOCALHOST: 'http://localhost:3000',
} as const;

const ALLOWED_ORIGINS = Object.values(IFRAME);

const messagesObserver = createObserver;

const sendMessage = (iframe: HTMLIFrameElement, data: any, targetOrigin = '/') => {
    iframe.contentWindow?.postMessage(data, targetOrigin);
};

export const usePostMessages = () => {
    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (!ALLOWED_ORIGINS.includes(event.origin as any)) return;

            messagesObserver.dispatch(event.origin, event);
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);
};

/**
 * Subscribe to messages from a specific iframe.
 * NOTICE!!! Prior to using this hook, make sure to use `usePostMessages` to subscribe to post messages for the whole project.
 * @param iframeKey - The key of the target iframe.
 * @param callback - The callback function for handling messages.
 */
export const useIframeMessages = (
    iframeKey: Properties<typeof IFRAME>,
    callback?: (event: MessageEvent) => void,
    deps = [] as any[],
) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        const { current: callbackFn } = callbackRef;

        const handler = (...args: any[]) => {
            // @ts-ignore
            callbackFn?.(...args);
        };

        messagesObserver.subscribe(IFRAME[iframeKey], handler);

        return () => {
            messagesObserver.unsubscribe(IFRAME[iframeKey], handler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    // Sends a message to the associated iframe.
    const postMessage = useCallback((data: any) => {
        const iframe = iframeRef.current;

        if (iframe) {
            sendMessage(iframe, data, IFRAME[iframeKey]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [postMessage, iframeRef] as const;
};
