import { memo, useEffect } from 'react';
import { Pagination, SxProps } from '@mui/material';
import { Typography } from '@components/index';
import { DarkGrey, Spacing } from '@style/theme';
import { ITEMS_PER_PAGE } from '@data/defaults';
import { AlignCenter } from '@components/wrappers/Flex/Flex.wrapper';

interface AppPaginationProps {
    /**
     * Current page number
     */
    page?: number;
    /**
     * React state setter for the page number
     */
    setPage?: React.Dispatch<React.SetStateAction<number>>;
    /**
     * Function to be called when the page is changed
     */
    onChange?: (e: React.ChangeEvent<unknown>, page: number) => void;
    /**
     * Total number of items
     */
    itemCount?: number;
    /**
     * Name of the collection
     */
    collectionName?: string;
    /**
     * Custom styles to be applied to the pagination
     */
    muiStyle?: SxProps;
}

export default memo(function AppPagination({
    itemCount = 10,
    page = 1,
    onChange = () => {},
    setPage,
    collectionName,
    muiStyle,
}: AppPaginationProps) {
    const numberOfPages = Math.ceil(itemCount / ITEMS_PER_PAGE);
    const itemCountEnd = page * ITEMS_PER_PAGE;

    /*
     * clean up code - resets the page to 1
     */
    useEffect(() => {
        return () => {
            setPage?.(1);
        };
    }, [itemCount]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <AlignCenter padding={Spacing.LG} sx={{ ...muiStyle }}>
            <Pagination
                size="small"
                page={page}
                defaultPage={1}
                siblingCount={1}
                boundaryCount={0}
                onChange={onChange}
                count={numberOfPages}
                showFirstButton
                showLastButton
                sx={{
                    '& > ul': {
                        flexWrap: 'nowrap',
                        '& > li  > button': {
                            margin: 0,
                        },
                    },
                }}
            />
            <Typography variant="body2" color={DarkGrey.MEDIUM}>{`${
                itemCountEnd > itemCount ? itemCount : itemCountEnd
            } of ${itemCount} ${collectionName}`}</Typography>
        </AlignCenter>
    );
});
