import { IconProps } from '.';

export function OutlineBell({ fill = '#0D182F', size = 24, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...muiStyle }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 3.99994C8.68628 3.99995 6 6.68624 6 9.99994V13.7224C6 14.1653 5.82404 14.5901 5.51082 14.9033L4.81612 14.2086L5.51082 14.9033L5 15.4142L5 16H19V15.4142L18.4892 14.9033C18.176 14.5901 18 14.1653 18 13.7223V9.99994C18 6.68623 15.3137 3.99993 12 3.99994ZM4 9.99994C4 5.58167 7.58171 1.99995 12 1.99994C16.4183 1.99993 20 5.58165 20 9.99994V13.5857L20.4142 14C20.7893 14.375 21 14.8837 21 15.4142V16C21 17.1045 20.1046 18 19 18H16V18C16 20.2091 14.2091 22 12 22C9.79086 22 8 20.2091 8 18V18H5C3.89543 18 3 17.1045 3 16V15.4142C3 14.8837 3.21071 14.375 3.58579 14L4.22555 14.6397L3.58579 14L4 13.5857V9.99994ZM10 18C10 19.1046 10.8954 20 12 20C13.1046 20 14 19.1046 14 18H10Z"
                fill={fill}
            />
        </svg>
    );
}

export function OutlinePaperPlane({ fill = '#0D182F', size = 24, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...muiStyle }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.9007 2.59963C20.4353 2.12747 21.8727 3.56481 21.4005 5.09936L16.5361 20.9085C16.0055 22.633 13.6426 22.8286 12.8357 21.2147L9.48561 14.5145L2.78538 11.1644C1.17155 10.3575 1.3671 7.9946 3.09163 7.46398L18.9007 2.59963ZM19.4889 4.51119L3.67981 9.37554L10.0334 12.5523L14.4467 8.13907C14.8372 7.74854 15.4704 7.74854 15.8609 8.13907C16.2514 8.52959 16.2514 9.16275 15.8609 9.55328L11.4477 13.9665L14.6246 20.3203L19.4889 4.51119Z"
                fill={fill}
            />
        </svg>
    );
}

export function OutlineChat({ fill = '#0D182F', size = 24, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...muiStyle }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 5.661c0-1.767 1.433-3.2 3.2-3.2v0h25.6c1.767 0 3.2 1.433 3.2 3.2v0 16c0 1.767-1.433 3.2-3.2 3.2v0h-18.64l-4.96 3.97c-2.096 1.678-5.2 0.184-5.2-2.498v-20.672zM28.8 5.661h-25.6v20.672l4.962-3.971c0.542-0.437 1.24-0.701 1.998-0.701 0.001 0 0.001 0 0.002 0h18.638v-16z"
                fill={fill}
            />
        </svg>
    );
}
