import React, { memo } from 'react';
import { Box, SxProps } from '@mui/material';
import { Badge } from '@components/index';
import { Border, BorderRadius, Spacing } from '@style/theme';
import { FlexCenter } from '@components/wrappers/Flex/Flex.wrapper';
import { OutlineDownload } from '@assets/icons';
import Preview from '@components/common/Preview/Preview';
import Tooltip from '@components/wrappers/Tooltip/Tooltip.wrapper';
import Label from '@components/wrappers/Label/Label.wrapper';

export interface DisplayImageProps {
    file: { name: string; url: string; extension: string };
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    muiStyle?: SxProps;
}

const styles = {
    root: {
        padding: Spacing.MD,
        cursor: 'pointer',
        '&:hover': {
            '& .MuiButton-root': {
                transition: 'all 200ms ease',
                opacity: '1 !important',
            },
        },
    },
    label: {
        width: 100,
    },
    imageWrapper: {
        position: 'relative',

        width: 100,
        maxHeight: 100,
        mt: Spacing.MD,
        padding: Spacing.LG,

        border: `1px solid ${Border.MEDIUM}`,
        borderRadius: BorderRadius.XXS,
    },
    downloadBadge: {
        position: 'absolute',
        magin: '0 auto',
        opacity: 0,
    },
};

export default memo(function DisplayImage({ file, onClick, muiStyle }: DisplayImageProps) {
    return (
        <Box onClick={onClick} sx={{ ...styles.root, ...muiStyle }}>
            <Tooltip tooltip={file.name}>
                <Label label={file.name} width={140}>
                    <FlexCenter muiStyle={{ ...styles.imageWrapper }}>
                        <Preview url={file.url} extension={file.extension} size={100} />
                        <Badge
                            variant="menu"
                            disabled
                            icon={<OutlineDownload />}
                            muiStyle={{ ...styles.downloadBadge }}
                        />
                    </FlexCenter>
                </Label>
            </Tooltip>
        </Box>
    );
});
