export type Sizes = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type Alignments = 'left' | 'center' | 'right';
export type TextAlignments = Alignments | 'inherit' | 'justify';
export type FlexDirections = 'row' | 'column';

export type Axis = 'x' | 'y' | 'xy';

export type Statuses = 'OPN' | 'ASN' | 'INR' | 'RVS' | 'CPD' | 'CLD' | 'OHD' | 'INN' | 'DEM';
export enum SubscriptionStatus {
    active = 'active',
    inactive = 'inactive',
    trial = 'trial',
    lead = 'lead',
}

export type DesignerStatuses = Extract<Statuses, 'INR' | 'INN'>;
export type ClientStatuses = Extract<Statuses, 'OPN' | 'RVS' | 'CPD' | 'CLD' | 'OHD'>;

export type Priorities = 'NS' | 'HI' | 'MD' | 'LO';

export type FontVariants =
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline';

export type ButtonVariants = 'primary' | 'flat' | 'stroke' | 'menu';
export type ButtonTypes = 'button' | 'submit' | 'reset';

export type SmallButtonVariants =
    | ButtonVariants
    | 'info'
    | 'warning'
    | 'error'
    | 'success'
    | 'ghost'
    | 'purple'
    | 'turquoise';

export type MenuButtonVariants = 'light' | 'dark';
export type PopoverTriggerButtonTypes = 'button' | 'badge' | 'menu';

export type BadgeVariants = SmallButtonVariants;

export type PopoverVariants = Exclude<ButtonVariants, 'menu'>;

export type InputTypes = 'text' | 'password' | 'email';

export type Industries =
    | 'AM'
    | 'AG'
    | 'AE'
    | 'AB'
    | 'BC'
    | 'CI'
    | 'ED'
    | 'FN'
    | 'FB'
    | 'HL'
    | 'LG'
    | 'MN'
    | 'MH'
    | 'NP'
    | 'OT'
    | 'PG'
    | 'RE'
    | 'ST'
    | 'SR'
    | 'SP'
    | 'TT';
