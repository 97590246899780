import React, { memo, useCallback, useMemo, useState } from 'react';
import { Box, Menu, PopoverOrigin } from '@mui/material';
import { Badge, Button, MenuButton } from '@components/index';
import { Spacing, BorderRadius, LightGrey } from '@style/theme';
import { PopoverTriggerButtonTypes, PopoverVariants } from '@data/types';
import Title from '@components/typography/Title/Title.component';

const ANCHOR_ORIGIN: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
};

const TRANSFORM_ORIGIN: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'right',
};

const styles = {
    root: {},
    menu: {
        mt: Spacing.MD,
    },
    paper: {
        borderRadius: BorderRadius.XS,
    },
    option: {
        alignItems: 'center',
        padding: Spacing.XS,
        borderRadius: BorderRadius.XS,
        cursor: 'pointer',
        boxSizing: 'border-box',
        overflow: 'hidden',
        '&:hover, &.selected': {
            background: LightGrey.LIGHT,
        },
    },
};

export interface PopoverProps {
    title?: string | React.ReactNode;
    label?: string | React.ReactNode;
    variant?: PopoverVariants;
    startIcon?: React.ReactNode;
    triggerButton?: PopoverTriggerButtonTypes;
    tooltip?: string;
    width?: number;
    padding?: number;
    transformOrigin?: PopoverOrigin;
    anchorOrigin?: PopoverOrigin;
    children?: React.ReactNode;
    closeOnSelect?: boolean;
    onClose?: () => void;
    disabled?: boolean;
    isLoading?: boolean;
}

export default memo(function Popover({
    title,
    label = '...',
    variant = 'primary',
    startIcon,
    triggerButton = 'button',
    tooltip,
    width,
    padding = Spacing.LG,
    transformOrigin = TRANSFORM_ORIGIN,
    anchorOrigin = ANCHOR_ORIGIN,
    children,
    closeOnSelect = true,
    onClose,
    disabled = false,
    isLoading = false,
}: PopoverProps) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const titleType = useMemo(() => typeof title, [title]);

    const RenderButton = (type: PopoverTriggerButtonTypes) => {
        switch (type) {
            case 'badge':
                return (
                    <Badge
                        icon={label}
                        size="sm"
                        loading={isLoading}
                        variant={variant}
                        tooltip={tooltip}
                        invert={true}
                        disabled={disabled}
                        onClick={e => setAnchorEl(e.currentTarget)}
                    />
                );
            case 'menu':
                return (
                    <MenuButton
                        label={label}
                        loading={isLoading}
                        startIcon={startIcon}
                        tooltip={tooltip}
                        disabled={disabled}
                        onClick={e => setAnchorEl(e.currentTarget)}
                        muiStyle={{ maxWidth: 160 }}
                    />
                );
            case 'button':
            default:
                return (
                    <Button
                        label={label}
                        loading={isLoading}
                        variant={variant}
                        startIcon={startIcon}
                        tooltip={tooltip}
                        disabled={disabled}
                        onClick={e => setAnchorEl(e.currentTarget)}
                    />
                );
        }
    };

    const handleClose = useCallback(() => {
        onClose?.();
        setAnchorEl(null);
    }, [onClose]);

    return (
        <>
            {RenderButton(triggerButton)}

            <Menu
                anchorEl={anchorEl}
                open={open}
                anchorOrigin={anchorOrigin}
                transformOrigin={transformOrigin}
                onClose={handleClose}
                sx={{ ...styles.menu, '.MuiPaper-root': { width: width, padding: padding, ...styles.paper } }}
            >
                {titleType === 'string' ? <Title variant="h3">{title}</Title> : <Box>{title}</Box>}

                <Box onClick={closeOnSelect ? handleClose : undefined}>{children}</Box>
            </Menu>
        </>
    );
});
