import { memo, useMemo } from 'react';
import { Box, Modal, SxProps } from '@mui/material';
import { Badge, Button } from '@components/index';
import { ChevronLeft, OutlineClose } from '@assets/icons';
import { BorderRadius, LightGrey, Spacing, WHITE } from '@style/theme';
import { FlexBox } from '@components/wrappers/Flex/Flex.wrapper';
import { ButtonProps } from '@components/common/Button/Button.component';
import Title from '@components/typography/Title/Title.component';
import { SpaceBetween } from '@components/wrappers/Flex/Flex.wrapper';
import { FontVariants } from '@data/types';

type ALIGNMENT = 'start' | 'center' | 'end';
export interface ModalProps {
    title: string;
    titleVariant?: Extract<FontVariants, 'h1' | 'h2' | 'h3'>;
    width?: number | string;
    children: React.ReactNode;
    cancelButton?: ButtonProps;
    confirmButton?: ButtonProps;
    buttonAlignment?: ALIGNMENT;
    buttonRender?: React.ReactNode;
    onClose?: () => void;
    muiStyle?: SxProps;
}

const style = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 360,
        maxHeight: '80%',
        overflow: 'hidden',
        backgroundColor: WHITE,
        borderRadius: BorderRadius.SM,
        boxShadow: LightGrey.MEDIUM,
    },
    title: {
        alignItems: 'center',
        padding: Spacing.LG,
    },
};

const getAlignment = (alignment: ALIGNMENT) => {
    switch (alignment) {
        case 'start':
            return { justifyContent: 'flex-start' };
        case 'end':
            return { justifyContent: 'flex-end' };
        default:
            return { justifyContent: 'center' };
    }
};

const DefaultButtonRender = (
    cancelButton: ButtonProps,
    confirmButton: ButtonProps,
    alignment: { justifyContent: string },
) => (
    <FlexBox gap={Spacing.MD} padding={Spacing.LG} {...alignment}>
        <Button
            label={cancelButton?.label}
            type={cancelButton?.type}
            variant={cancelButton?.variant}
            startIcon={<ChevronLeft size={14} />}
            width={cancelButton?.width}
            onClick={e => cancelButton?.onClick?.(e)}
        />
        <Button
            label={confirmButton?.label}
            type={confirmButton?.type}
            variant={confirmButton?.variant}
            loading={confirmButton?.loading}
            width={confirmButton?.width}
            onClick={async e => confirmButton?.onClick?.(e)}
        />
    </FlexBox>
);

export default memo(function CustomModal({
    title = 'Modal Component',
    titleVariant = 'h1',
    width,
    children,
    cancelButton = { label: 'Cancel', onClick: () => {} },
    confirmButton = { label: 'Confirm', onClick: () => {} },
    buttonAlignment = 'center',
    buttonRender = DefaultButtonRender(cancelButton, confirmButton, getAlignment(buttonAlignment)),
    onClose,
    muiStyle,
}: ModalProps) {
    const styles = useMemo(() => ({ width: width, ...style.root, ...muiStyle }) as SxProps, [muiStyle, width]);

    return (
        <Modal open={true} onClose={onClose}>
            <Box sx={{ ...styles }}>
                <SpaceBetween muiStyle={{ ...style.title }}>
                    <Title variant={titleVariant}>{title}</Title>

                    {onClose && <Badge variant="ghost" size="sm" icon={<OutlineClose size={10} />} onClick={onClose} />}
                </SpaceBetween>

                {children}

                {buttonRender}
            </Box>
        </Modal>
    );
});
