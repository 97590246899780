import { IconProps } from '.';

export function OutlineHeadphone({ fill = '#0D182F', size = 20, muiStyle }: IconProps) {
    return (
        <svg width={size} height={size} style={{ ...muiStyle }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path
                fill={fill}
                d="M3.299 16h4.701c1.767 0 3.2 1.433 3.2 3.2v0 8c0 1.767-1.433 3.2-3.2 3.2v0h-4.8c-1.767 0-3.2-1.433-3.2-3.2v0-9.6c0-8.837 7.163-16 16-16s16 7.163 16 16v9.6c0 1.767-1.433 3.2-3.2 3.2v0h-4.8c-1.767 0-3.2-1.433-3.2-3.2v0-8c0-1.767 1.433-3.2 3.2-3.2v0h4.701c-0.824-6.347-6.196-11.198-12.701-11.198s-11.877 4.852-12.694 11.134l-0.007 0.064zM28.8 19.2h-4.8v8h4.8v-8zM3.2 19.2v8h4.8v-8h-4.8z"
            ></path>
        </svg>
    );
}

export function OutlineImage({ fill = '#0D182F', size = 20, muiStyle }: IconProps) {
    return (
        <svg width={size} height={size} style={{ ...muiStyle }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path
                fill={fill}
                d="M0 6.4v19.2c0 1.767 1.433 3.2 3.2 3.2h25.6c1.767 0 3.2-1.433 3.2-3.2v-19.2c0-1.767-1.433-3.2-3.2-3.2h-25.6c-1.767 0-3.2 1.433-3.2 3.2zM3.2 24.592l7.982-9.314 5.671 6.805c0.587 0.701 1.468 1.146 2.454 1.146 0.883 0 1.683-0.358 2.262-0.937l2.298-2.298 4.933 6.165v-0.56h-25.6v-1.008zM28.8 21.040l-2.434-3.043c-0.591-0.735-1.491-1.201-2.499-1.201-0.884 0-1.684 0.358-2.263 0.937l-2.298 2.298-5.666-6.8c-0.591-0.706-1.473-1.151-2.458-1.151-0.97 0-1.839 0.432-2.426 1.113l-0.004 0.004-5.554 6.478v-13.275h25.6v14.64zM17.6 12v0c0 1.767 1.433 3.2 3.2 3.2s3.2-1.433 3.2-3.2v0c0-1.767-1.433-3.2-3.2-3.2s-3.2 1.433-3.2 3.2z"
            ></path>
        </svg>
    );
}
