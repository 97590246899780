import React, { memo, useMemo, useState } from 'react';
import { Card, SxProps } from '@mui/material';
import { Typography } from '@components/index';
import { DarkGrey, FontWeight, LightGrey, Spacing } from '@style/theme';
import { OutlineCopy } from '@assets/icons';
import Overlay from './Overlay/Overlay.component';
import Title from '@components/typography/Title/Title.component';

export interface TextCardProps {
    content?: string | React.ReactNode;
    title?: string;
    width?: string | number;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    muiStyle?: SxProps;
}

const style = {
    root: {},
    card: {
        background: LightGrey.LIGHT,
    },
    icon: {
        position: 'absolute',
        bottom: 10,
        right: 10,
    },
};

export default memo(function TextCard({ title, width = 100, onClick, content, muiStyle }: TextCardProps) {
    const [open, setOpen] = useState<boolean>(false);

    const pointer = useMemo(() => (onClick ? 'pointer' : 'default'), [onClick]);

    return (
        <Overlay
            open={open}
            setOpen={setOpen}
            onClick={() => setOpen(previousState => true)}
            muiStyle={{ ...muiStyle }}
        >
            <Card onClick={onClick} sx={{ cursor: pointer, ...style.card }}>
                <Title>{title}</Title>

                {content && (
                    <Typography weight={FontWeight.BOLD} prewrap={true} muiStyle={{ pr: Spacing.LG }}>
                        {content}
                    </Typography>
                )}

                <OutlineCopy size={16} fill={DarkGrey.LIGHT} muiStyle={{ ...style.icon } as React.CSSProperties} />
            </Card>
        </Overlay>
    );
});
