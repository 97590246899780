export enum RefreshActions {
    REFRESH_TASKS = 'REFRESH_TASKS',
    REFRESH_FILES = 'REFRESH_FILES',
    REFRESH_TEAMS = 'REFRESH_TEAMS',
    REFRESH_MEMBERS = 'REFRESH_MEMBERS',
    CLEAR_REFRESH = 'CLEAR_REFRESH',
}

export const shouldRefreshTasks = (dispatch: any, state: boolean) => {
    dispatch({ type: RefreshActions.REFRESH_TASKS, payload: { data: !state } });
};

export const shouldRefreshFiles = (dispatch: any, state: boolean) => {
    dispatch({ type: RefreshActions.REFRESH_FILES, payload: { data: !state } });
};

export const shouldRefreshTeams = (dispatch: any, state: boolean) => {
    dispatch({ type: RefreshActions.REFRESH_TEAMS, payload: { data: !state } });
};

export const shouldRefreshMembers = (dispatch: any, state: boolean) => {
    dispatch({ type: RefreshActions.REFRESH_MEMBERS, payload: { data: !state } });
};

export const clearAllRefresh = (dispatch: any) => {
    dispatch({ type: RefreshActions.CLEAR_REFRESH });
};
