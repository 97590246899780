import { getPermissions } from '@helper/getPermissions';
import { $currentUser } from '@store/user/user.selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useUser = () => {
    const current_user = useSelector($currentUser);

    const permissions = useMemo(() => (current_user ? getPermissions(current_user) : null), [current_user]);

    const full_name = useMemo(() => current_user?.first_name + ' ' + current_user?.last_name, [current_user]);
    const profile_pic = useMemo(() => current_user?.profile_pic, [current_user]);
    const user_email = useMemo(() => current_user?.email, [current_user]);

    const is_client = useMemo(() => current_user?.is_client, [current_user]);

    const is_client_admin = useMemo(
        () => current_user?.is_client && current_user?.permission_level === 'LVL1',
        [current_user],
    );
    const is_client_manager = useMemo(
        () => current_user?.is_client && current_user?.permission_level === 'LVL2',
        [current_user],
    );
    const is_client_member = useMemo(
        () => current_user?.is_client && current_user?.permission_level === 'LVL3',
        [current_user],
    );

    const is_designer = useMemo(() => current_user?.is_designer, [current_user]);

    const is_designer_admin = useMemo(
        () => current_user?.is_designer && current_user?.permission_level === 'LVL1',
        [current_user],
    );
    const is_designer_supervisor = useMemo(
        () => current_user?.is_designer && current_user?.permission_level === 'LVL2',
        [current_user],
    );
    const is_designer_member = useMemo(
        () => current_user?.is_designer && current_user?.permission_level === 'LVL3',
        [current_user],
    );

    const is_admin = useMemo(() => current_user?.permission_level === 'LVL1', [current_user]);
    const is_supervisor = useMemo(() => current_user?.permission_level === 'LVL2', [current_user]);
    const is_member = useMemo(() => current_user?.permission_level === 'LVL3', [current_user]);

    const is_staff = useMemo(() => current_user?.is_staff, [current_user]);

    const is_trial_end = useMemo(() => current_user?.trial_end, [current_user]);

    return useMemo(
        () => ({
            current_user,
            permissions,
            full_name,
            profile_pic,
            user_email,
            is_client,
            is_client_admin,
            is_client_manager,
            is_client_member,
            is_designer,
            is_designer_admin,
            is_designer_supervisor,
            is_designer_member,
            is_admin,
            is_supervisor,
            is_member,
            is_staff,
            is_trial_end,
        }),
        [
            current_user,
            permissions,
            full_name,
            profile_pic,
            user_email,
            is_client,
            is_client_admin,
            is_client_manager,
            is_client_member,
            is_designer,
            is_designer_admin,
            is_designer_supervisor,
            is_designer_member,
            is_admin,
            is_supervisor,
            is_member,
            is_staff,
            is_trial_end,
        ],
    );
};
