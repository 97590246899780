export enum MadgicxActions {
    SET_MADGICX_TASK_ID = 'SET_MADGICX_TASK_ID',
    CLEAR_MADGICX_TASK_ID = 'CLEAR_MADGICX_TASK_ID',
}

export const setMadgicxTaskId = (dispatch: any, id: string) => {
    dispatch({ type: MadgicxActions.SET_MADGICX_TASK_ID, payload: { data: id } });
};

export const clearMadgicxTaskId = (dispatch: any) => {
    dispatch({ type: MadgicxActions.CLEAR_MADGICX_TASK_ID });
};
