import { initialState } from '@store/root-reducer';
import { TaskActions } from './tasks.action';
import { ITask, ITaskModel, TaskFilterCategory } from '@models/ITaskModel';
import { IActionModel } from '@models/IActionModel';
import { SubscriptionStatus } from '@data/types';
import { ITEMS_PER_PAGE } from '@data/defaults';

export const taskReducer = (state: ITaskModel = initialState.tasks, action: IActionModel) => {
    switch (action.type) {
        // Task Actions
        case TaskActions.SET_TASKS: {
            return {
                ...state,
                data: { ...action.payload.data },
            };
        }
        case TaskActions.CLEAR_TASKS: {
            return initialState.tasks;
        }
        case TaskActions.ADD_TASK: {
            const tasks: ITask[] = [...state.data.results.data];

            tasks.unshift(action.payload.data);

            return {
                ...state,
                data: {
                    ...state.data,
                    results: {
                        ...state.data.results,
                        data: tasks,
                    },
                },
            };
        }
        case TaskActions.UPDATE_TASKS: {
            const tasks: ITask[] = [...state.data.results.data];

            const index = tasks.findIndex(task => task.id === action.payload.data.id);

            if (index !== -1) {
                tasks[index] = action.payload.data;
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    results: {
                        ...state.data.results,
                        data: tasks,
                    },
                },
            };
        }
        case TaskActions.UPDATE_TASK_STATUS: {
            const tasks: ITask[] = [...state.data.results.data];

            const index = tasks.findIndex(task => task.id === action.payload.data.id);

            if (index !== -1) {
                tasks[index].status = action.payload.data.status;

                const updatedTask = tasks.splice(index, 1);

                tasks.unshift(updatedTask[0]);
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    results: {
                        ...state.data.results,
                        data: tasks,
                    },
                },
            };
        }
        case TaskActions.UPDATE_TASK_FILES: {
            const tasks: ITask[] = [...state.data.results.data];

            const task = tasks.find(task => task.id === action.payload.data.id);

            if (task) {
                task.files = action.payload.data?.files;
                task.total_files = action.payload.data?.files?.length;
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    results: {
                        ...state.data.results,
                        data: tasks,
                    },
                },
            };
        }
        case TaskActions.MARK_TASK_AS_READ: {
            const tasks: ITask[] = [...state.data.results.data];

            const task = tasks.find(task => task.id === action.payload.data.id);

            if (task) {
                task.total_unread_comments = 0;
                task.is_tagged = false;
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    results: {
                        ...state.data.results,
                        data: tasks,
                    },
                },
            };
        }
        case TaskActions.UPDATE_TASK_COMMENT_COUNT: {
            const tasks: ITask[] = [...state.data.results.data];

            const task = tasks.find(task => task.id === action.payload.data.id);

            if (task) task.total_comments = action.payload.data.total_comments;

            return {
                ...state,
                data: {
                    ...state.data,
                    results: {
                        ...state.data.results,
                        data: tasks,
                    },
                },
            };
        }
        case TaskActions.DELETE_TASK: {
            const tasks: ITask[] = [...state.data.results.data];

            const index = tasks.findIndex(task => task.id === action.payload.data.id);

            tasks.splice(index, 1);

            return {
                ...state,
                data: {
                    ...state.data,
                    results: {
                        ...state.data.results,
                        data: tasks,
                    },
                },
            };
        }
        case TaskActions.SHOULD_NOTIFY: {
            return {
                ...state,
                shouldNotify: !state.shouldNotify,
            };
        }
        // Filter Actions
        case TaskActions.SET_TASK_FILTERS: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload.data,
                },
            };
        }
        case TaskActions.CLEAR_TASK_FILTERS: {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    client_team_ids: [],
                    client_ids: [],
                    designer_ids: [],
                    status: [],
                },
            };
        }
        case TaskActions.CLEAR_ALL_TASK_FILTERS: {
            return {
                ...state,
                filters: {
                    limit: ITEMS_PER_PAGE,
                    page: 1,
                    sort_by: '-task_updated_at',
                    client_team_ids: [],
                    client_ids: [],
                    designer_ids: [],
                    status: [],
                    searchTerm: '',
                    category: TaskFilterCategory.open,
                    subscription_status: SubscriptionStatus.active,
                },
            };
        }
        default:
            return state;
    }
};

export default taskReducer;
