import axios from 'axios';
import Toast from '@components/common/Toast/Toast.component';
import { ApiService } from '@api/api';

export class FileApi extends ApiService {
    attachToBrandkit = async (brandkitId: number, file: FormData) => {
        return this.$post({
            url: `brand_kits/${brandkitId}/files/`,
            data: file,
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    };

    getFromBrandkit = async (brandkitId: number) => {
        return this.$get({
            url: `brand_kits/${brandkitId}/files/`,
        });
    };

    attachToTask = async (taskId: number, file: FormData) => {
        return this.$post({
            url: `tasks/${taskId}/files/`,
            data: file,
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    };

    getFromTask = async (taskId: number) => {
        return this.$get({
            url: `tasks/${taskId}/files/`,
        });
    };

    attachToComment = async (commentId: number, file: FormData) => {
        return this.$post({
            url: `comments/${commentId}/files/`,
            data: file,
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    };

    getFromComment = async (commentId: number) => {
        return this.$get({
            url: `comments/${commentId}/files/`,
        });
    };

    directDownload = async (url: string, filename: string): Promise<boolean> => {
        return axios
            .get(url, { responseType: 'blob' })
            .then(response => {
                this.download(response.data, filename, response.headers['content-type']);

                return true;
            })
            .catch(error => {
                Toast.error({ message: 'Unable to download file. Please try again later.' });

                return false;
            });
    };

    downloadAllFilesFromComment = async (commentId: number) => {
        return this.$get({
            url: `comments/${commentId}/download/files`,
        }).then(response => {
            this.directDownload(response.data.zip_file_url, `${commentId}.zip`);

            return true;
        });
    };

    downloadExportData = async () => {
        return this.$get({
            url: `download-csv/ `,
        }).then(response => {
            this.download(response.data, 'export.csv', 'text/csv');

            return true;
        });
    };

    download = (content: any, fileName: string, contentType: string) => {
        const a = document.createElement('a');
        const file = new Blob([content], { type: contentType });

        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
    };
}

export const fileApi = new FileApi();
