import { initialState } from '@store/root-reducer';
import { UserActions } from '@store/user/user.actions';
import { IUserModel } from '@models/IUserModel';
import { IActionModel } from '@models/IActionModel';

export const userReducer = (state: IUserModel | null = initialState.user, action: IActionModel) => {
    switch (action.type) {
        case UserActions.SET_USER: {
            return { ...action.payload.data };
        }
        case UserActions.SET_ACCESS_TOKEN: {
            return { ...state, accessToken: action.payload.data };
        }
        case UserActions.CLEAR_STORE: {
            return null;
        }
        case UserActions.IS_LOADING: {
            return { ...state, is_loading: action.payload.data };
        }
        default:
            return state;
    }
};
export default userReducer;
