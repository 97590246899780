import { IMemberModel } from '@models/IMemberModel';
import { ISignupModel } from '@models/ISignupModel';
import { ITokenModel } from '@models/ITokenModel';
import { IUserModel } from '@models/IUserModel';
import { ApiService, IRequestState } from '@api/api';
import { IClientResponse } from '@models/IClientModel';
import { IDesigner } from '@models/IDesignerModel';

export class UserApi extends ApiService {
    login = async (data: { email: string; password: string }): Promise<IRequestState<ITokenModel>> => {
        return this.$post({
            url: `token/`,
            data,
        });
    };

    signup = async (data: ISignupModel): Promise<IRequestState<ITokenModel>> => {
        return this.$post({
            url: `client_accounts/`,
            data,
        });
    };

    getUser = async (userId: number): Promise<IRequestState<IUserModel>> => {
        return this.$get({
            url: `users/${userId}/`,
        });
    };

    updateUser = async (userId: number, data: IMemberModel): Promise<IRequestState<IMemberModel>> => {
        return this.$put({
            url: `users/${userId}/`,
            data,
        });
    };

    patchUser = async (userId: number, data: { is_active: boolean }): Promise<IRequestState<IUserModel>> => {
        return this.$patch({
            url: `users/${userId}/`,
            data,
        });
    };

    getAllUsers = async (): Promise<IRequestState<IUserModel[]>> => {
        return this.$get({
            url: `users/`,
        });
    };

    getTaggableUsers = async (taskId: string) => {
        return this.$get({
            url: `task/${taskId}/tagged-users/`,
        });
    };

    getStaffs = async () => {
        return this.$get({
            url: `staffs/`,
        });
    };

    getAllDesigners = async (): Promise<IRequestState<IDesigner>> => {
        return this.$get({
            url: `users/all_designers/`,
        });
    };

    getAllClientTeam = async (): Promise<IRequestState<IClientResponse>> => {
        // adding '?limit=2000' as a query param, this is a temporary fix to be able to display 2000 items per request.
        return this.$get({
            url: `teams/client/?limit=7000`,
        });
    };

    getAllClient = async (): Promise<IRequestState<IClientResponse>> => {
        // adding '?limit=2000' as a query param, this is a temporary fix to be able to display 2000 items per request.
        return this.$get({
            url: `clients/?limit=7000`,
        });
    };

    resetPassword = async (userId: number, password: string) => {
        return this.$put({
            url: `user/${userId}/reset-password/`,
            data: { password: password },
        });
    };

    getTokens = async (data: {
        token: string;
        data: string;
        timestamp: string;
    }): Promise<IRequestState<ITokenModel>> => {
        return this.$get({
            url: `externals/madgicx/single-sign-on?token=${data.token}&data=${data.data}&timestamp=${data.timestamp}`,
        });
    };
}

export const userApi = new UserApi();
