import { memo } from 'react';
import { Box, Button, CircularProgress, SxProps } from '@mui/material';
import { Typography } from '@components/index';
import { ChevronUp, ChevronDown } from '@assets/icons';
import { DarkGrey, LightGrey, WHITE, BLACK, Spacing } from '@style/theme';
import { useNavigate } from 'react-router-dom';

type Variant = 'light' | 'dark';

export interface SubmenuButtonProps {
    /**
     * Label of the button
     */
    label?: string | React.ReactNode;
    /**
     * Button variant
     */
    variant?: Variant;
    /**
     * Function to be called when the button is clicked
     */
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    /**
     * Path to be navigated to when the button is clicked
     */
    path?: string;
    /**
     * Icon to be displayed at the start of the button
     */
    startIcon?: React.ReactNode;
    /**
     * Number of notifications to be displayed
     */
    notifications?: number;
    /**
     * Whether the button is a dropdown, If true, the button has submenus
     */
    dropdown?: boolean;
    /**
     * If true, the button is selected
     */
    selected?: boolean;
    /**
     * If true, the button is in loading state
     */
    loading?: boolean;
    /**
     * If true, the button is disabled
     */
    disabled?: boolean;
    /**
     * Custom styles for the button
     */
    muiStyle?: SxProps;
}

const getButtonVariant = (variant: Variant, selected?: boolean) => {
    switch (variant) {
        case 'light':
            return {
                color: selected ? DarkGrey.DARK : DarkGrey.MEDIUM,
                backgroundColor: WHITE,
                '& svg path': {
                    fill: selected ? DarkGrey.DARK : DarkGrey.MEDIUM,
                },
                '&:hover': {
                    color: DarkGrey.DARK,
                    backgroundColor: WHITE,

                    '& svg path': {
                        fill: DarkGrey.DARK,
                    },
                },
                '&:disabled': {
                    color: DarkGrey.LIGHT,
                    '& svg path': {
                        fill: DarkGrey.LIGHT,
                    },
                },
            };
        case 'dark':
            return {
                color: selected ? DarkGrey.LIGHT : DarkGrey.MEDIUM,
                backgroundColor: BLACK,
                '& svg path': {
                    fill: selected ? DarkGrey.LIGHT : DarkGrey.MEDIUM,
                },
                '&:hover': {
                    color: DarkGrey.LIGHT,
                    backgroundColor: BLACK,

                    '& svg path': {
                        fill: DarkGrey.LIGHT,
                    },
                },
                '&:disabled': {
                    color: DarkGrey.DARK,
                    '& svg path': {
                        fill: DarkGrey.DARK,
                    },
                },
            };
        default:
            return {};
    }
};

const getLoaderVariant = (variant: Variant) => {
    switch (variant) {
        case 'light':
            return {
                color: DarkGrey.DARK,
            };
        case 'dark':
            return {
                color: LightGrey.LIGHT,
            };
        default:
            return {};
    }
};

const labelStyle = {
    width: '100%',
    textAlign: 'left',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    my: 0,
    mx: Spacing.SM,
};

export default memo(function SubmenuButton({
    label,
    variant = 'light',
    onClick = () => {},
    path = undefined,
    startIcon,
    notifications = undefined,
    dropdown = false,
    selected = false,
    loading = false,
    disabled = false,
    muiStyle,
}: SubmenuButtonProps) {
    const navigate = useNavigate();

    return (
        <Button
            disabled={loading || disabled}
            onClick={e => {
                if (path) {
                    navigate(path);
                }
                onClick(e);
            }}
            startIcon={
                loading ? (
                    <CircularProgress
                        size={18}
                        sx={{
                            ...getLoaderVariant(variant),
                        }}
                    />
                ) : (
                    startIcon
                )
            }
            endIcon={
                dropdown && !selected ? (
                    <ChevronDown size={16} />
                ) : dropdown && selected ? (
                    <ChevronUp size={16} />
                ) : undefined
            }
            sx={{
                '& .MuiButton-endIcon': {
                    ml: Spacing.LG,

                    '& svg': {
                        opacity: selected ? 1 : 0,
                    },
                },
                width: 160,
                ...getButtonVariant(variant, selected),
                ...muiStyle,
            }}
        >
            <Box display="flex" width="100%">
                <Typography variant="body2" muiStyle={{ ...labelStyle }}>
                    {label}
                </Typography>
            </Box>
        </Button>
    );
});
