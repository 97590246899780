import React, { FC, memo } from 'react';
import { SxProps } from '@mui/material';
import { Typography } from '@components/index';
import { FontWeight, DarkGrey, Spacing } from '@style/theme';
import { FlexColumnCenter } from '@components/wrappers/Flex/Flex.wrapper';
import { FontVariants } from '@data/types';

type EmptyStateProps = {
    children?: React.ReactNode | undefined | null;
    fullHeight?: boolean;
    muiStyle?: SxProps;
};

const EmptyState = ({ children, fullHeight = false, muiStyle }: EmptyStateProps) => (
    <FlexColumnCenter height={fullHeight ? '100%' : 'auto'} gap={Spacing.SM} padding={Spacing.SM} sx={{ ...muiStyle }}>
        {children}
    </FlexColumnCenter>
);

export const NoData: FC<{ icon?: React.ReactNode; message: string; variant?: FontVariants } & EmptyStateProps> = memo(
    ({ icon, message, variant = 'body2', ...props }) => (
        <EmptyState {...props}>
            {icon && icon}

            <Typography
                variant={variant}
                alignment="center"
                color={DarkGrey.MEDIUM}
                weight={FontWeight.REGULAR}
                prewrap={true}
            >
                {message}
            </Typography>
        </EmptyState>
    ),
);
