import { memo } from 'react';
import { Box, BoxProps } from '@mui/material';
import { Scroll } from '@style/theme';
import { Axis } from '@data/types';

interface ScrollerProps extends BoxProps {
    axis?: Axis;
    children?: React.ReactNode;
}

export default memo(function Scroller(props: ScrollerProps) {
    const { axis = 'y', children, sx, ...rest } = props;

    return (
        <Box {...rest} sx={{ ...sx, ...Scroll(axis) }}>
            {children}
        </Box>
    );
});
