import { memo, useMemo, useState } from 'react';
import { Button, MenuButton, SubmenuButton } from '@components/index';
import { OutlineSad, OutlinePlusCircle } from '@assets/icons';
import { DarkGrey, Spacing } from '@style/theme';
import { JustifyCenter } from '@components/wrappers/Flex/Flex.wrapper';
import { Accordion } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { NoData } from '@components/common/EmptyState/EmptyState.component';
import Scroller from '@layout/Scroller/Scroller';

export interface IMenuOptions {
    id?: number;
    /**
     * Button content
     */
    label?: string | React.ReactNode;
    /**
     * Button type
     */
    buttontype?: 'menu' | 'submenu';
    /**
     * Optional button variant
     */
    invert?: boolean;
    /**
     * Optional click handler
     */
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    /**
     * Optional path for links
     */
    path?: string;
    /**
     * Icon at the start of the button
     */
    startIcon?: React.ReactNode;
    /**
     * Optional, Should show number notifications?
     */
    notifications?: number;
    /**
     * Optional, If true a dropdown arrow will show at the end of the button
     */
    dropdown?: boolean;
    /**
     * Optional, If true will force the hover state
     */
    selected?: boolean;
}
export interface MenuAccordionProps {
    label?: string | React.ReactNode;
    startIcon?: React.ReactNode;
    dropdown?: boolean;
    selected: boolean;
    path?: string;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    options?: IMenuOptions[];
    emptyMessage?: string;
    addButtonLabel?: string;
    addButtonAllowed?: boolean;
    onAddPath?: string;
    onAddClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export default memo(function MenuAccordion({
    label = 'Menu Label',
    onClick = () => {},
    startIcon,
    selected = false,
    path,
    dropdown = false,
    options,
    emptyMessage = "You don't have any items for now",
    addButtonLabel,
    addButtonAllowed = false,
    onAddPath,
    onAddClick,
}: MenuAccordionProps) {
    const navigate = useNavigate();

    const [selectedSubmenuId, setSelectedSubmenuId] = useState<number | undefined>(undefined);

    const selectedSubmenu = useMemo(
        () => options?.find(option => option?.id === selectedSubmenuId),
        [options, selectedSubmenuId],
    );

    return (
        <Accordion expanded={selected}>
            <MenuButton
                label={label}
                startIcon={startIcon}
                dropdown={dropdown}
                selected={selected}
                path={path}
                onClick={e => {
                    onClick(e);

                    if (selected === false) {
                        setSelectedSubmenuId(undefined);
                    }
                }}
            />
            <>
                <Scroller width={160} maxHeight={200} my={Spacing.MD} mx={0}>
                    {options !== undefined && options?.length > 0 ? (
                        options?.map((option, i) => {
                            return (
                                <SubmenuButton
                                    key={option.id}
                                    label={option.label}
                                    startIcon={option.startIcon}
                                    selected={selectedSubmenu?.id === option.id ?? false}
                                    path={option.path}
                                    onClick={e => {
                                        option.onClick && option.onClick(e);

                                        setSelectedSubmenuId(option.id);
                                    }}
                                />
                            );
                        })
                    ) : (
                        <NoData icon={<OutlineSad size={24} fill={DarkGrey.MEDIUM} />} message={emptyMessage} />
                    )}
                </Scroller>
                {addButtonAllowed && addButtonLabel && onAddPath && (
                    <JustifyCenter>
                        <Button
                            label={addButtonLabel}
                            variant="stroke"
                            startIcon={<OutlinePlusCircle />}
                            onClick={e => {
                                onAddPath && navigate(onAddPath);
                                onAddClick && onAddClick(e);
                                setSelectedSubmenuId(undefined);
                            }}
                        />
                    </JustifyCenter>
                )}
            </>
        </Accordion>
    );
});
