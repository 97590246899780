import { IconProps } from '.';

export function OutlineInfo({ fill = '#0D182F', size = 20, muiStyle }: IconProps) {
    return (
        <svg width={size} height={size} style={{ ...muiStyle }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path
                fill={fill}
                d="M16 3.2c-7.069 0-12.8 5.731-12.8 12.8s5.731 12.8 12.8 12.8v0c7.069 0 12.8-5.731 12.8-12.8s-5.731-12.8-12.8-12.8v0zM0 16c0-8.837 7.163-16 16-16s16 7.163 16 16-7.163 16-16 16-16-7.163-16-16zM14.32 9.6c0-0.884 0.716-1.6 1.6-1.6v0h0.16c0.884 0 1.6 0.716 1.6 1.6v0 0.16c0 0.884-0.716 1.6-1.6 1.6v0h-0.16c-0.884 0-1.6-0.716-1.6-1.6v0-0.16zM16 12.8c0.884 0 1.6 0.716 1.6 1.6v0 8c0 0.884-0.716 1.6-1.6 1.6s-1.6-0.716-1.6-1.6v0-8c0-0.884 0.716-1.6 1.6-1.6v0z"
            ></path>
        </svg>
    );
}

export function OutlineStop({ fill = '#0D182F', size = 20, muiStyle }: IconProps) {
    return (
        <svg width={size} height={size} style={{ ...muiStyle }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path
                fill={fill}
                d="M5.888 8.15c-1.679 2.145-2.692 4.88-2.692 7.853 0 7.069 5.731 12.8 12.8 12.8 2.972 0 5.708-1.013 7.88-2.712l-0.028 0.021-17.96-17.962zM8.15 5.888l17.962 17.962c1.68-2.145 2.694-4.882 2.694-7.856 0-7.069-5.731-12.8-12.8-12.8-2.974 0-5.711 1.014-7.884 2.716l0.028-0.021zM0 16c0-8.837 7.163-16 16-16s16 7.163 16 16-7.163 16-16 16-16-7.163-16-16z"
            ></path>
        </svg>
    );
}
