import { IFileRequestModel, Purpose } from '@models/IFileModel';

const getFormData = (files: IFileRequestModel[], purpose?: Purpose) => {
    const uploadFiles: FormData = new FormData();

    files.forEach(file => {
        if (file.file !== undefined) {
            uploadFiles.append('file_url', file.file);
        }
    });

    uploadFiles.append('purpose', purpose ?? 'no-purpose');

    return { uploadFiles };
};

export default getFormData;
