import React, { memo } from 'react';
import { SxProps } from '@mui/material';
import { DarkGrey } from '@style/theme';
import { Typography } from '@components/index';
import { FontVariants } from '@data/types';
import { CustomTypographyProps } from '../Typography.component';

interface OverlineProps extends CustomTypographyProps {
    ref?: React.RefObject<HTMLParagraphElement>;
    variant?: FontVariants;
    children: string | React.ReactNode;
    color?: string;
    muiStyle?: SxProps;
}

export default memo(function Overline(props: OverlineProps) {
    const { ref, children, color = DarkGrey.DARK, muiStyle } = props;

    if (!children) return null;

    return (
        <Typography ref={ref} variant="overline" color={color} muiStyle={{ textTransform: 'capitalize', ...muiStyle }}>
            {children}
        </Typography>
    );
});
