import React, { memo, useCallback, useEffect, useState } from 'react';
import { Typography } from '@components/index';
import { ChevronDown } from '@assets/icons';
import { Border, BorderRadius, DarkGrey, Spacing } from '@style/theme';
import { AlignCenter, SpaceBetween } from '@components/wrappers/Flex/Flex.wrapper';
import { IOption } from '@data/interfaces';
import Label from '@components/wrappers/Label/Label.wrapper';
import Popup from '@components/wrappers/Popup/Popup.wrapper';
import SingleSelectList, { OptionProps } from '../SingleSelectList/SingleSelectList.component';

interface DropdownSingleSelectProps {
    label?: string;
    defaultValue?: string;
    options?: IOption[];
    onChange?: (value: any) => void;
    placeholder?: string;
    // MultiSelectList props
    searchable?: boolean;
    searchPlaceholder?: string;
    selectRender?: (props: OptionProps) => React.ReactNode;
    selectWidth?: number | string;
    selectHeight?: number;
    required?: boolean;
    disabled?: boolean;
}

const style = {
    root: {
        width: '100%',
        minHeight: 40,

        border: `1px solid ${Border.MEDIUM}`,
        boxSizing: 'border-box',
        borderRadius: BorderRadius.XS,
    },
    content: {
        width: '100%',
        alignItems: 'center',
        padding: Spacing.MD,
    },
};

export default memo(function DropdownSingleSelect({
    label = '',
    defaultValue,
    options = [],
    onChange,
    placeholder = 'Select',
    searchable = false,
    searchPlaceholder = 'Search',
    selectRender,
    selectWidth = 280,
    selectHeight = 140,
    required,
    disabled,
}: DropdownSingleSelectProps) {
    const [option, setOption] = useState<IOption>();

    useEffect(() => {
        if (defaultValue) {
            const option = options.find(option => option.value.toString() === defaultValue);

            if (option) setOption(option);
        }
    }, [defaultValue, options]);

    const handleChange = useCallback(
        (option: IOption) => {
            setOption(option);
            onChange?.(option.value);
        },
        [onChange],
    );

    return (
        <Label label={label} variant="body1" required>
            <Popup
                trigger={
                    <AlignCenter sx={{ ...style.root }}>
                        <SpaceBetween sx={{ ...style.content }}>
                            <Typography variant="body1" muiStyle={{ color: disabled ? DarkGrey.LIGHT : DarkGrey.DARK }}>
                                {option?.label ? option.label : placeholder}
                            </Typography>
                            <ChevronDown fill={disabled ? DarkGrey.LIGHT : DarkGrey.DARK} size={12} />
                        </SpaceBetween>
                    </AlignCenter>
                }
                disabled={disabled}
                width="100%"
            >
                <SingleSelectList
                    value={option}
                    options={options}
                    optionsRender={selectRender}
                    onChange={options => handleChange(options)}
                    placeholder={searchPlaceholder}
                    isSearchable={searchable}
                    width={selectWidth}
                    height={selectHeight}
                    gap={Spacing.XS}
                    required={required}
                />
            </Popup>
        </Label>
    );
});
