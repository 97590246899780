import { IUserModel, Permission } from '@models/IUserModel';

const DEFAULT_PERMISSIONS: Permission[] = ['can_see_brandkits', 'can_see_tasks', 'can_see_teams'];

const DESIGNER_ADMIN_PERMISSIONS: Permission[] = [
    // brandkits
    'can_add_brandkits',
    'can_edit_brandkits',
    'can_add_brandkits_for_clients',
    'can_edit_brandkits_for_clients',
    // tasks
    'can_assign_tasks',
    'can_filter_tasks',
    'can_add_tasks_for_clients',
    'can_edit_tasks_for_clients',
    // teams
    'can_add_teams',
    'can_add_members',
    // services
    'can_see_services',
    // clients
    'can_see_clients',
    'can_add_clients',
    'can_edit_clients',
    'can_see_users',
    'can_add_users',
    'can_edit_users',
    'can_filter_tasks',
];

const CLIENT_ADMIN_PERMISSIONS: Permission[] = [
    // brandkits
    'can_add_brandkits',
    'can_edit_brandkits',
    // tasks
    'can_add_tasks',
    'can_edit_tasks',
    // teams
    'can_add_teams',
    'can_add_members',
];

const CLIENT_MEMBER_PERMISSIONS: Permission[] = [
    // brandkits
    'can_add_brandkits',
    'can_edit_brandkits',
    // tasks
    'can_add_tasks',
    'can_edit_tasks',
];

export const getPermissions = (user: IUserModel) => {
    // By default, any user can see brandkits, tasks and teams
    const permissions: string[] = [];

    permissions.push(...DEFAULT_PERMISSIONS);

    //  User is a designer
    if (user.is_designer) {
        // User has a permission level of 1 = Admin or 2 = Supervisor
        if (user.permission_level !== 'LVL3') permissions.push(...DESIGNER_ADMIN_PERMISSIONS);
    }
    // User is a client
    if (user.is_client) {
        // User has a permission level of 1 = Admin or 2 = Supervisor
        if (user.permission_level === 'LVL1') permissions.push(...CLIENT_ADMIN_PERMISSIONS);
        // User has a permission level of 3 = Member
        else permissions.push(...CLIENT_MEMBER_PERMISSIONS);
    }

    return permissions;
};
