import { useEffect } from 'react';
import * as FullStory from '@fullstory/browser';
// import { useUser } from '@hooks/useUser';

export const useFullStory = () => {
    // const user = useUser();

    useEffect(() => {
        FullStory.init({ orgId: 'CFGMH', recordCrossDomainIFrames: true });
        // FullStory.FullStory('setProperties', {
        //     type: 'user',
        //     properties: {
        //         displayName: user.full_name,
        //         email: user.user_email,
        //         id: user.current_user?.id,
        //         discordId: user.current_user?.discord_id,
        //         permissionLevel: user.current_user?.permission_level,
        //     },
        // });
        // }, [user]);
    }, []);
};
