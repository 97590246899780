import React, { memo } from 'react';
import { SxProps } from '@mui/material';
import { BorderRadius, WHITE } from '@style/theme';
import { FlexColumn } from '@components/wrappers/Flex/Flex.wrapper';

export interface ListPanel {
    children?: React.ReactNode;
    muiStyle?: SxProps;
}

export default memo(function ListPanel({ children, muiStyle }: ListPanel) {
    return (
        <FlexColumn
            minWidth={360}
            width={360}
            height="100%"
            borderRadius={BorderRadius.SM}
            overflow="hidden"
            boxSizing="border-box"
            bgcolor={WHITE}
            sx={{ ...muiStyle }}
        >
            {children}
        </FlexColumn>
    );
});
