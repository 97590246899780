import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { persistor, store } from '@store/root-reducer';
import { PersistGate } from 'redux-persist/integration/react';
import LoadingPage from '@pages/Loading/Loading.page';
import { ThemeProvider } from '@mui/material';
import { APP_THEME } from '@style/theme';
import IntegrationProvider from './IntegrationProvider';

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <React.StrictMode>
                <ThemeProvider theme={APP_THEME}>
                    <Suspense fallback={<LoadingPage />}>
                        <IntegrationProvider>
                            <App />
                        </IntegrationProvider>
                    </Suspense>
                </ThemeProvider>
            </React.StrictMode>
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
