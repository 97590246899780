import { Box, SxProps } from '@mui/material';
import { Typography } from '@components/index';
import { DarkGrey, LightGrey, FontWeight, Spacing, BorderRadius } from '@style/theme';
import { OutlineDownload } from '@assets/icons';
import { AlignCenter } from '@components/wrappers/Flex/Flex.wrapper';
import React, { memo } from 'react';
import Preview from '@components/common/Preview/Preview';

export interface DisplayFileProps {
    /**
     * content of the card
     */
    file: { name: string; url: string; extension: string };
    /**
     * file type
     */
    type?: 'font' | 'music';
    /**
     * on click
     */
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    /**
     * color icons
     */
    color?: string;
    /**
     * Custom styling
     */
    muiStyle?: SxProps;
}

const styles = {
    root: {
        gap: Spacing.MD,
        cursor: 'pointer',
        '&:hover': {
            '& svg': {
                transition: 'all 200ms ease',
                opacity: '1 !important',
            },
        },
    },
    preview: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 40,
        borderRadius: BorderRadius.XS,
        boxSizing: 'border-box',
        border: `2px dashed ${LightGrey.DARK}`,
    },
    details: {
        width: 100,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
};

export default memo(function DisplayFile({ file, onClick, color = DarkGrey.DARK, muiStyle }: DisplayFileProps) {
    return (
        <AlignCenter onClick={onClick} sx={{ ...styles.root, ...muiStyle }}>
            <Preview url={file.url} extension={file.extension} size={24} muiStyle={{ ...styles.preview }} />
            <Box sx={{ ...styles.details }}>
                {file?.name && <Typography weight={FontWeight.BOLD}>{file.name}</Typography>}
                <Typography variant="body2">{file.url}</Typography>
            </Box>
            <OutlineDownload muiStyle={{ opacity: 0 }} />
        </AlignCenter>
    );
});
