import { ChangeEvent, memo, useCallback, useMemo } from 'react';
import { Input } from '@components/index';
import { OutlineSearch } from '@assets/icons';
import { Spacing } from '@style/theme';
import { SxProps } from '@mui/material';

export interface SearchInputProps {
    searchableItems: any[] | undefined;
    searchFilter?: string[];
    setSearchResults?: any;
    maxLength?: number;
    width?: number | string;
    placeholder?: string;
    disabled?: boolean;
    muiStyle?: SxProps;
}

const style = {
    root: {},
    icon: { margin: `0 ${Spacing.MD} 0 0` },
};

export default memo(function SearchInput({
    searchableItems,
    searchFilter = ['name'],
    setSearchResults,
    maxLength,
    placeholder = 'Input Placeholder',
    width = '100%',
    disabled = false,
    muiStyle,
}: SearchInputProps) {
    const styles = useMemo(() => ({ ...style.root, ...muiStyle }), [muiStyle]);

    const handleSearchChange = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (!e.target.value) return setSearchResults(searchableItems);

            const results = searchableItems?.filter(item =>
                searchFilter!.every(key => item[key].toLowerCase().includes(e.target.value.toLowerCase())),
            );

            setSearchResults(results);
        },
        [searchableItems, searchFilter, setSearchResults],
    );

    return (
        <Input
            placeholder={placeholder}
            startIcon={<OutlineSearch size={16} muiStyle={{ ...style.icon }} />}
            onChange={handleSearchChange}
            maxLength={maxLength}
            width={width}
            disabled={disabled}
            muiStyle={{ ...styles }}
        />
    );
});
