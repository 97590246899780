import { Divider } from '@mui/material';
import { DarkGrey, Spacing } from '@style/theme';
import { FlexBox, FlexColumn, SpaceBetween } from '@components/wrappers/Flex/Flex.wrapper';
import React, { memo } from 'react';
import Tooltip from '@components/wrappers/Tooltip/Tooltip.wrapper';
import Title from '@components/typography/Title/Title.component';
import Subtitle from '@components/typography/Subtitle/Subtitle.component';

export interface HeaderProps {
    title?: string;
    tooltip?: boolean;
    subtitle?: string | number;
    color?: string;
    children?: React.ReactNode;
}

const style = {
    span: {
        fontSize: '0.75rem',
        lineHeight: '1rem',
        color: DarkGrey.LIGHT,
    },
};

export default memo(function Header({ title, tooltip = false, subtitle, color, children }: HeaderProps) {
    return (
        <>
            <SpaceBetween padding={Spacing.LG}>
                <FlexColumn overflow="hidden">
                    <Tooltip tooltip={tooltip ? title : undefined}>
                        <Title color={color}>{title}</Title>
                    </Tooltip>

                    {subtitle && (
                        <Subtitle>
                            <span color={DarkGrey.LIGHT} style={{ ...style.span }}>
                                by&nbsp;
                            </span>
                            {subtitle}
                        </Subtitle>
                    )}
                </FlexColumn>

                <FlexBox gap={Spacing.MD}>{children}</FlexBox>
            </SpaceBetween>
            <Divider />
        </>
    );
});
