import { memo } from 'react';
import { SmallButton } from '@components/index';
import { Spacing } from '@style/theme';
import { Priorities } from '@data/types';
import { FlexBox } from '@components/wrappers/Flex/Flex.wrapper';
import { TASK_PRIORITY_OPTIONS } from '@data/defaults';
export interface PriorityLabelGroupProps {
    label?: string;
    priority?: Priorities;
    onClick?: (e: React.MouseEvent<HTMLElement>, value: Priorities) => void;
    disabled?: boolean;
}

export default memo(function PriorityLabelGroup({ label, priority, onClick }: PriorityLabelGroupProps) {
    return (
        <FlexBox gap={Spacing.MD}>
            {TASK_PRIORITY_OPTIONS.map(option => (
                <SmallButton
                    key={option.value}
                    label={option.label}
                    startIcon={option.startIcon}
                    variant={option.value === priority ? option.variant : 'stroke'}
                    invert={true}
                    onClick={e => {
                        const value = option.value as Priorities;
                        onClick?.(e, value);
                    }}
                />
            ))}
        </FlexBox>
    );
});
