import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';
import { IAppStateModel } from '@models/IAppStateModel';
import userReducer from '@store/user/user.reducer';
import brandkitReducer from '@store/brandkit/brandkit.reducer';
import taskReducer from './tasks/tasks.reducer';
import teamsReducer from './teams/teams.reducer';
import selectedTaskReducer from './selected-task/selected-task.reducer';
import selectedBrandkitReducer from '@store/selected-brandkit/selected-brandkit.reducer';
import selectedMemberReducer from './selected-member/selected-member.reducer';
import selectedMembersReducer from './selected-members/selected-members.reducer';
import selectedTeamReducer from './selected-team/selected-team.reducer';
import refreshReducer from './refresh/refresh.reducer';
import commentsReducer from './comments/comments.reducer';
import usersReducer from './users/users.reducer';
import { ITEMS_PER_PAGE } from '@data/defaults';
import madgicxReducer from './madgicx_task_id/madgicx.reducer';
import { TaskFilterCategory } from '@models/ITaskModel';
import { SubscriptionStatus } from '@data/types';

export const initialState: IAppStateModel = {
    user: null,
    users: {
        designers: [],
        clients: [],
    },
    brandkits: [],
    tasks: {
        filters: {
            limit: ITEMS_PER_PAGE,
            page: 1,
            sort_by: '-task_updated_at',
            client_team_ids: [],
            client_ids: [],
            designer_ids: [],
            status: [],
            category: TaskFilterCategory.open,
            subscription_status: SubscriptionStatus.active,
            searchTerm: '',
        },
        data: {
            results: {
                data: [],
            },
        },
        shouldNotify: true,
    },
    teams: {
        results: [],
    },
    members: [],
    comments: [],
    madgicx_task_id: null,
    selectedTask: null,
    selectedBrandkit: null,
    selectedTeam: null,
    selectedMember: null,
    selectedMembers: [],
    /*
     ** When this is set to true, can trigger a reload of data from server
     */
    refresh: {
        files: false,
        tasks: false,
        teams: false,
        members: false,
    },
};

const appReducer = combineReducers({
    user: userReducer,
    users: usersReducer,
    brandkits: brandkitReducer,
    tasks: taskReducer,
    teams: teamsReducer,
    comments: commentsReducer,
    madgicx_task_id: madgicxReducer,
    selectedTask: selectedTaskReducer,
    selectedBrandkit: selectedBrandkitReducer,
    selectedTeam: selectedTeamReducer,
    selectedMember: selectedMemberReducer,
    selectedMembers: selectedMembersReducer,
    /*
     ** When this is set to true, can trigger a reload of data from server
     */
    refresh: refreshReducer,
});

export const rootReducer: any = (state: IAppStateModel, action: any) => {
    if (action.type === 'CLEAR_STORE') {
        return appReducer(undefined, action);
    }

    return appReducer(state, action);
};

export const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk],
});

export const persistor = persistStore(store);
