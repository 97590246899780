import { IconProps } from '.';

export function OutlineUserCircle({ fill = '#0D182F', size = 24, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...muiStyle }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 4C7.58172 4 4 7.58172 4 12C4 14.3894 5.04749 16.5341 6.70831 18C8.11787 16.7561 9.97103 16 11.9998 16C14.0287 16 15.882 16.7562 17.2915 18.0001C18.9524 16.5342 20 14.3894 20 12C20 7.58172 16.4183 4 12 4ZM15.5571 19.1676C14.5614 18.4332 13.3316 18 11.9998 18C10.6682 18 9.43837 18.4331 8.44266 19.1675C9.51418 19.7004 10.7221 20 12 20C13.2778 20 14.4857 19.7004 15.5571 19.1676ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 9C10.8954 9 10 9.89543 10 11C10 12.1046 10.8954 13 12 13C13.1046 13 14 12.1046 14 11C14 9.89543 13.1046 9 12 9ZM8 11C8 8.79086 9.79086 7 12 7C14.2091 7 16 8.79086 16 11C16 13.2091 14.2091 15 12 15C9.79086 15 8 13.2091 8 11Z"
                fill={fill}
            />
        </svg>
    );
}

export function DashedUserCircle({ fill = '#D0D0D0', size = 42, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 42 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...muiStyle }}
        >
            <circle
                cx="21"
                cy="21"
                r="20"
                fill="#F9FAFB"
                stroke="#E5E7EB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="8 8"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.0003 11.6667C18.7912 11.6667 17.0003 13.4575 17.0003 15.6667C17.0003 17.8758 18.7912 19.6667 21.0003 19.6667C23.2095 19.6667 25.0003 17.8758 25.0003 15.6667C25.0003 13.4575 23.2095 11.6667 21.0003 11.6667ZM14.3337 15.6667C14.3337 11.9848 17.3184 9 21.0003 9C24.6822 9 27.667 11.9848 27.667 15.6667C27.667 19.3486 24.6822 22.3333 21.0003 22.3333C17.3184 22.3333 14.3337 19.3486 14.3337 15.6667ZM16.0831 27.6715C14.8737 28.4777 14.3337 29.4451 14.3337 30.3333C14.3337 31.0697 13.7367 31.6667 13.0003 31.6667C12.2639 31.6667 11.667 31.0697 11.667 30.3333C11.667 28.2761 12.9178 26.5768 14.6039 25.4527C16.2993 24.3224 18.5647 23.6667 21.0003 23.6667C23.436 23.6667 25.7014 24.3224 27.3968 25.4527C29.0829 26.5768 30.3337 28.2761 30.3337 30.3333C30.3337 31.0697 29.7367 31.6667 29.0003 31.6667C28.2639 31.6667 27.667 31.0697 27.667 30.3333C27.667 29.4451 27.1269 28.4777 25.9176 27.6715C24.7176 26.8715 22.9829 26.3333 21.0003 26.3333C19.0177 26.3333 17.2831 26.8715 16.0831 27.6715Z"
                fill={fill}
            />
        </svg>
    );
}

export function DashedUserCirclePlus({ fill = '#D0D0D0', size = 42, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...muiStyle }}
        >
            <path
                fill="#F9FAFB"
                stroke="#E5E7EB"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="8 8"
                d="M30.75 16c0 8.146-6.604 14.75-14.75 14.75s-14.75-6.604-14.75-14.75c0-8.146 6.604-14.75 14.75-14.75s14.75 6.604 14.75 14.75z"
            ></path>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#d0d0d0"
                d="M13.54 10.263c-1.358 0-2.458 1.101-2.458 2.458s1.101 2.458 2.458 2.458v0c1.358 0 2.458-1.101 2.458-2.458s-1.101-2.458-2.458-2.458v0zM9.442 12.722c0-2.263 1.834-4.098 4.098-4.098s4.098 1.834 4.098 4.098v0c0 2.263-1.834 4.098-4.098 4.098s-4.098-1.834-4.098-4.098v0zM21.734 13.542c0.454 0 0.82 0.368 0.82 0.82v1.638h1.638c0.439 0.018 0.788 0.378 0.788 0.819s-0.349 0.802-0.786 0.819l-0.002 0h-1.638v1.64c0 0.009 0.001 0.021 0.001 0.032 0 0.453-0.367 0.82-0.82 0.82s-0.82-0.367-0.82-0.82c0-0.011 0-0.022 0.001-0.033l-0 0.002v-1.64h-1.64c-0.009 0-0.021 0.001-0.032 0.001-0.453 0-0.82-0.367-0.82-0.82s0.367-0.82 0.82-0.82c0.011 0 0.022 0 0.033 0.001l-0.002-0h1.641v-1.638c0-0.452 0.366-0.82 0.819-0.82zM10.518 20.101c-0.744 0.495-1.076 1.090-1.076 1.635 0 0.009 0.001 0.021 0.001 0.032 0 0.453-0.367 0.82-0.82 0.82s-0.82-0.367-0.82-0.82c0-0.011 0-0.022 0.001-0.033l-0 0.002c0-1.264 0.768-2.308 1.804-2.999 1.042-0.694 2.434-1.098 3.932-1.098 1.496 0 2.889 0.403 3.931 1.098 1.036 0.691 1.804 1.736 1.804 2.999-0.018 0.439-0.378 0.788-0.819 0.788s-0.802-0.349-0.819-0.786l-0-0.002c0-0.546-0.332-1.139-1.076-1.635-0.738-0.492-1.803-0.823-3.021-0.823-1.219 0-2.285 0.332-3.023 0.823z"
            ></path>
        </svg>
    );
}

export function OutlineUser({ fill = '#0D182F', size = 20 }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <path
                fill={fill}
                d="M16 3.2c-3.535 0-6.4 2.865-6.4 6.4s2.865 6.4 6.4 6.4v0c3.535 0 6.4-2.865 6.4-6.4s-2.865-6.4-6.4-6.4v0zM6.4 9.6c0-5.302 4.298-9.6 9.6-9.6s9.6 4.298 9.6 9.6v0c0 5.302-4.298 9.6-9.6 9.6s-9.6-4.298-9.6-9.6v0zM7.797 26.101c-2.048 1.277-2.997 2.84-2.997 4.299 0 0.884-0.716 1.6-1.6 1.6s-1.6-0.716-1.6-1.6v0c0-2.96 1.915-5.397 4.501-7.013 2.608-1.632 6.112-2.587 9.899-2.587s7.291 0.957 9.899 2.587c2.586 1.616 4.501 4.053 4.501 7.013 0 0.884-0.716 1.6-1.6 1.6s-1.6-0.716-1.6-1.6v0c0-1.459-0.95-3.021-2.997-4.301-2.024-1.262-4.92-2.099-8.203-2.099s-6.179 0.835-8.203 2.101z"
            ></path>
        </svg>
    );
}

export function OutlineUserClose({ fill = '#0D182F', size = 20 }: IconProps) {
    return (
        <svg width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <path
                fill={fill}
                d="M11.2 5.6c-2.651 0-4.8 2.149-4.8 4.8s2.149 4.8 4.8 4.8v0c2.651 0 4.8-2.149 4.8-4.8s-2.149-4.8-4.8-4.8v0zM3.2 10.4c0-4.418 3.582-8 8-8s8 3.582 8 8v0c0 4.418-3.582 8-8 8s-8-3.582-8-8v0zM31.532 12.469c0.289 0.29 0.468 0.689 0.468 1.131s-0.179 0.842-0.468 1.131l-2.069 2.069 2.069 2.069c0.278 0.287 0.449 0.68 0.449 1.112 0 0.884-0.716 1.6-1.6 1.6-0.432 0-0.824-0.171-1.112-0.45l-2.068-2.068-2.069 2.069c-0.287 0.278-0.68 0.449-1.112 0.449-0.884 0-1.6-0.716-1.6-1.6 0-0.432 0.171-0.824 0.45-1.112l2.068-2.068-2.069-2.069c-0.278-0.287-0.449-0.68-0.449-1.112 0-0.884 0.716-1.6 1.6-1.6 0.432 0 0.824 0.171 1.112 0.45l2.068 2.068 2.069-2.069c0.29-0.289 0.689-0.468 1.131-0.468s0.842 0.179 1.131 0.468v0zM5.299 24.805c-1.451 0.97-2.099 2.13-2.099 3.195 0 0.884-0.716 1.6-1.6 1.6s-1.6-0.716-1.6-1.6v0c0-2.469 1.501-4.507 3.525-5.856 2.034-1.358 4.752-2.144 7.675-2.144s5.642 0.787 7.675 2.144c2.024 1.347 3.525 3.386 3.525 5.856 0 0.884-0.716 1.6-1.6 1.6s-1.6-0.716-1.6-1.6v0c0-1.066-0.648-2.227-2.099-3.194-1.44-0.96-3.522-1.606-5.901-1.606s-4.461 0.646-5.901 1.606z"
            ></path>
        </svg>
    );
}
