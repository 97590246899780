import { userApi } from '@api/modules/apiUsers';
import { parseJWT } from '@utils/parseJWT';
import { setTokenCookies } from '@utils/cookieStorage';
import { ITokenModel } from '@models/ITokenModel';
import { setUserBrandkits } from '@store/brandkit/brandkit.actions';
import { getPermissions } from '@helper/getPermissions';
import { clearStorage } from '@utils/clearStorage';

export enum UserActions {
    SET_USER = 'SET_USER',
    SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN',
    IS_LOADING = 'IS_LOADING',
    CLEAR_STORE = 'CLEAR_STORE',
}

export const setUser = async (dispatch: any, tokens: ITokenModel) => {
    clearStorage(dispatch); // clears local storage

    setTokenCookies(tokens);

    const userResponse = await userApi.getUser(parseJWT(tokens.access).user_id);

    if (userResponse?.data) {
        const permissions = getPermissions(userResponse.data);

        const user = {
            ...userResponse.data,
            user_permissions: permissions,
        };

        dispatch({
            type: UserActions.SET_USER,
            payload: { data: user },
        });

        setUserBrandkits(dispatch, user);
    } else {
        clearStorage(dispatch);
    }
};
