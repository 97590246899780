import Cookies from 'js-cookie';

export const setTokenCookies = (token: { access: string; refresh: string }) => {
    const { access, refresh } = token;

    Cookies.set('access_token', access);
    Cookies.set('refresh_token', refresh);
};

export const clearTokenCookies = () => {
    Cookies.remove('access_token');
    Cookies.remove('refresh_token');
};
