import { SxProps } from '@mui/material';
import { FlexColumn } from '@components/wrappers/Flex/Flex.wrapper';

export interface PanelProps {
    /**
     * children
     */
    children?: React.ReactNode;
    /**
     * background color
     */
    bgcolor?: string;
    /**
     * border radius
     */
    borderRadius?: number;
    /**
     * width
     */
    width?: number | string;
    /**
     * Custom Style
     */
    muiStyle?: SxProps;
}

export default function Panel({ children, bgcolor = '#FFFFFF', borderRadius = 4, width, muiStyle }: PanelProps) {
    return (
        <FlexColumn
            width={width}
            height="100%"
            overflow="hidden"
            boxSizing="border-box"
            bgcolor={bgcolor}
            borderRadius={borderRadius}
            sx={{ ...muiStyle }}
        >
            {children}
        </FlexColumn>
    );
}
