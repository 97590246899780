import { HTMLAttributes, memo } from 'react';
import { SxProps } from '@mui/material';
import { FONTS_FILETYPE, AUDIO_FILETYPE, IMAGES_FILETYPE } from '@data/defaults';
import { OutlineFont, OutlineHeadphone, OutlineFileCheck } from '@assets/icons';
import { AlignCenter } from '@components/wrappers/Flex/Flex.wrapper';
import { DarkGrey } from '@style/theme';

export interface PreviewProps extends HTMLAttributes<HTMLInputElement> {
    url: string;
    extension?: string;
    size?: number;
    width?: number | string;
    height?: number | string;
    imageFit?: 'contain' | 'cover';
    iconSize?: number;
    iconColor?: string;
    muiStyle?: SxProps;
}

const getIcon = (extension?: string, iconSize?: number, iconColor?: string) => {
    const size = iconSize ?? 24;
    const color = iconColor ?? DarkGrey.DARK;

    if (extension) {
        if (FONTS_FILETYPE.includes(extension)) return <OutlineFont size={size} fill={color} />;
        if (AUDIO_FILETYPE.includes(extension)) return <OutlineHeadphone size={size} fill={color} />;
        if (IMAGES_FILETYPE.includes(extension)) return <OutlineFileCheck size={size} fill={color} />;
    }

    return <OutlineFileCheck size={size} fill={color} />;
};

// chrome file reader will crush the page bc of psd format (url is too long)
const unsupportedTypes = ['psd'];

export default memo(function Preview({
    url,
    extension,
    size = 24,
    height,
    width,
    imageFit = 'contain',
    iconSize,
    iconColor,
    muiStyle,
}: PreviewProps) {
    return (
        <AlignCenter
            width={width ?? size}
            minWidth={width ?? size}
            height={height ?? size}
            overflow="hidden"
            justifyContent="center"
            sx={{ ...muiStyle }}
        >
            {IMAGES_FILETYPE.filter(type => !unsupportedTypes.includes(type)).includes(extension ?? '') ? (
                <img src={url} alt={`alt-${url}`} style={{ width: '100%', height: '100%', objectFit: imageFit }} />
            ) : (
                getIcon(extension, iconSize, iconColor)
            )}
        </AlignCenter>
    );
});
