import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, CircularProgress, SxProps } from '@mui/material';
import { Typography } from '@components/index';
import { FontWeight, Spacing } from '@style/theme';
import { ChevronUp, ChevronDown } from '@assets/icons';
import { getMenuVariant } from '@style/getMenuVariant';
import { getMenuLoaderVariant } from '@style/getMenuLoaderVariant';
import { MenuButtonVariants } from '@data/types';
import Tooltip from '@components/wrappers/Tooltip/Tooltip.wrapper';

export interface MenuButtonProps {
    label?: string | React.ReactNode;
    variant?: MenuButtonVariants;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    path?: string;
    startIcon?: React.ReactNode;
    tooltip?: string;
    notifications?: number;
    dropdown?: boolean;
    selected?: boolean;
    loading?: boolean;
    disabled?: boolean;
    muiStyle?: SxProps;
}

const style = {
    root: {
        justifyContent: 'flex-start',
        overflow: 'hidden',
    },
};

export default memo(function MenuButton({
    label,
    variant = 'light',
    onClick = () => {},
    path = undefined,
    startIcon,
    tooltip,
    notifications = undefined,
    dropdown = false,
    selected = false,
    loading = false,
    disabled = false,
    muiStyle,
}: MenuButtonProps) {
    const navigate = useNavigate();

    return (
        <Tooltip tooltip={tooltip}>
            <Button
                disabled={loading || disabled}
                onClick={e => {
                    if (path) navigate(path);
                    onClick(e);
                }}
                startIcon={
                    loading ? <CircularProgress size={18} sx={{ ...getMenuLoaderVariant(variant) }} /> : startIcon
                }
                endIcon={
                    dropdown && !selected ? (
                        <ChevronDown size={12} />
                    ) : dropdown && selected ? (
                        <ChevronUp size={12} />
                    ) : undefined
                }
                sx={{
                    '& .MuiButton-endIcon': {
                        ml: Spacing.LG,

                        '& svg': {
                            opacity: selected ? 1 : 0,
                        },
                    },
                    ...style.root,
                    ...getMenuVariant(variant, selected),
                    ...muiStyle,
                }}
            >
                <Typography weight={FontWeight.BOLD} muiStyle={{ my: 0, mx: Spacing.SM }}>
                    {label}
                </Typography>
            </Button>
        </Tooltip>
    );
});
