import { useCallback, useEffect, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import _ from 'lodash';
import { Box, Button } from '@mui/material';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

interface ILayout {
    currentBreakpoint: string;
    compactType: 'vertical' | 'horizontal' | null | undefined;
    mounted: boolean;
    layouts: { lg: any };
}

function generateLayout() {
    return _.map(_.range(0, 25), function (item, i) {
        var y = Math.ceil(Math.random() * 4) + 1;
        return {
            x: (_.random(0, 5) * 2) % 12,
            y: Math.floor(i / 6) * y,
            w: 2,
            h: 1,
            i: i.toString(),
            static: Math.random() < 0.05,
        };
    });
}

export default function GridLayoutDemo({
    className = 'layout',
    rowHeight = 60,
    onLayoutChange = (layout: any, layouts?: any) => {},
    cols = { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    initialLayout = generateLayout(),
}) {
    const [layout, setLayout] = useState<ILayout>({
        currentBreakpoint: 'lg',
        compactType: 'vertical',
        mounted: false,
        layouts: { lg: generateLayout() },
    });

    const onBreakpointChange = useCallback((breakpoint: any) => {
        setLayout({ ...layout, currentBreakpoint: breakpoint });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCompactTypeChange = useCallback(() => {
        const { compactType: oldCompactType } = layout;

        const compactType =
            oldCompactType === 'horizontal' ? 'vertical' : oldCompactType === 'vertical' ? null : 'horizontal';

        setLayout({ ...layout, compactType });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onLayoutUpdate = useCallback((layout: any, layouts: any) => {
        onLayoutChange(layout, layouts);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNewLayout = useCallback(() => {
        setLayout({ ...layout, layouts: { lg: generateLayout() } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setLayout({ ...layout, mounted: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderGrid = () => {
        return _.map(layout.layouts.lg, function (l: any, i: number) {
            return (
                <div key={i} className={l.static ? 'static' : ''} style={{ border: '1px solid #000' }}>
                    {l.static ? (
                        <span className="text" title="This item is static and cannot be removed or resized.">
                            Static - {i}
                        </span>
                    ) : (
                        <>
                            <Box display="inline-block">
                                <Button>One</Button>
                                <Button>Two</Button>
                                <Button>Three</Button>
                            </Box>
                        </>
                    )}
                </div>
            );
        });
    };

    return (
        <div>
            {/* <div> Current Breakpoint: {layout.currentBreakpoint} ({cols[layout.currentBreakpoint]} columns)</div> */}
            {/* <div> Compaction type: {_.capitalize(layout.compactType) || "No Compaction"} </div> */}
            <button onClick={onNewLayout}>Generate New Layout</button>
            <button onClick={onCompactTypeChange}>Change Compaction Type</button>
            <ResponsiveReactGridLayout
                className={className}
                rowHeight={rowHeight}
                layouts={layout.layouts}
                onBreakpointChange={onBreakpointChange}
                onLayoutChange={onLayoutUpdate}
                // WidthProvider option
                measureBeforeMount={false}
                // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
                // and set `measureBeforeMount={true}`.
                useCSSTransforms={layout.mounted}
                compactType={layout.compactType}
                preventCollision={!layout.compactType}
            >
                {renderGrid()}
            </ResponsiveReactGridLayout>
        </div>
    );
}
