import { ApiService, IRequestState } from '@api/api';
import { ITaskModel, ITaskRequest, ITaskFilters, ITask } from '@models/ITaskModel';
import { Statuses } from '@data/types';

export class TaskApi extends ApiService {
    createTask = async (data: ITaskRequest): Promise<IRequestState<ITask>> => {
        return this.$post({
            url: `tasks/`,
            data,
        });
    };

    getTask = async (taskId: number): Promise<IRequestState<ITask>> => {
        return this.$get({
            url: `tasks/${taskId}/`,
        });
    };

    // updating the api call to depend wether the filter is triggered by changing pages or not.
    filterTasks = async (filters: ITaskFilters, pageTriggered = false): Promise<IRequestState<ITaskModel>> => {
        const queryString = this.serializeQueryParams(pageTriggered ? filters : { ...filters, page: 1 });

        return this.$get({
            url: `tasks/?${queryString}`,
        });
    };

    updateTask = async (taskId: number, data: ITaskRequest): Promise<IRequestState<ITask>> => {
        return this.$put({
            url: `tasks/${taskId}/`,
            data,
        });
    };

    deleteTask = async (taskId: number): Promise<IRequestState<void>> => {
        return this.$delete({
            url: `tasks/${taskId}/`,
        });
    };

    getAllTasks = async (): Promise<IRequestState<ITaskModel>> => {
        return this.$get({
            url: `tasks/`,
        });
    };

    updateStatus = async (taskId: number, status: Statuses, shouldNotify?: boolean): Promise<IRequestState<ITask>> => {
        return this.$patch({
            url: `tasks/${taskId}/update_status/`,
            data: { status, notifications_enabled: shouldNotify },
        });
    };

    assignTask = async (taskId: number, data: any): Promise<IRequestState<ITask>> => {
        return this.$patch({
            url: `tasks/${taskId}/assign_designer/`,
            data,
        });
    };
}

export const taskApi = new TaskApi();
