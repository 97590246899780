import { useMemo, lazy } from 'react';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import {Route, Routes} from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import GridLayoutPage from '@pages/GridLayout/GridLayout.page';
import UiKitPage from '@pages/UIKit/UIKit.page';
import PrivateRoute from '@route/PrivateRoute';
import { useUser } from '@hooks/useUser';

const LoginPage = lazy(() => import('@pages/Login/Login.page'));
const SignupPage = lazy(() => import('@pages/Signup/SignupPage.page'));
const TaskPage = lazy(() => import('@pages/Task/Task.page'));
const BrandkitRequestPage = lazy(() => import('@pages/Brandkit/BrandkitRequest/BrandkitRequest.page'));
const BrandkitDetailsPage = lazy(() => import('@pages/Brandkit/BrandkitDetails/BrandkitDetails.page'));
const TeamPage = lazy(() => import('@pages/Team/Team.page'));
const ServicesPage = lazy(() => import('@pages/Services/Services.page'));
const NotFoundPage = lazy(() => import('@pages/PageNotFound/PageNotFound.page'));

function App() {
    const { permissions, user_email } = useUser();

    const isLoggedIn = useMemo(() => !!user_email, [user_email]);

    // page permissions
    const taskPermitted = useMemo(() => permissions?.includes('can_see_tasks'), [permissions]);
    const seeBrandkitPermitted = useMemo(() => permissions?.includes('can_see_brandkits'), [permissions]);
    const addBrandkitPermitted = useMemo(() => permissions?.includes('can_add_brandkits'), [permissions]);
    const seeServicesPermitted = useMemo(() => permissions?.includes('can_see_services'), [permissions]);
    const seeTeamsPermitted = useMemo(() => permissions?.includes('can_see_teams'), [permissions]);

    return (
        <>
            <ToastContainer />
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<SignupPage />} />
                    <Route path="externals/madgicx/*" element={<SignupPage />} />
                    <Route path="/login" element={<LoginPage />} />

                    <Route
                        path="/tasks"
                        element={
                            <PrivateRoute isLoggedIn={isLoggedIn} permitted={taskPermitted}>
                                <TaskPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/tasks/:taskId"
                        element={
                            <PrivateRoute isLoggedIn={isLoggedIn} permitted={taskPermitted} redirectPath="/tasks">
                                <TaskPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/brandkit"
                        element={
                            <PrivateRoute
                                isLoggedIn={isLoggedIn}
                                permitted={addBrandkitPermitted}
                                redirectPath="/tasks"
                            >
                                <BrandkitRequestPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/brandkit/:id"
                        element={
                            <PrivateRoute
                                isLoggedIn={isLoggedIn}
                                permitted={seeBrandkitPermitted}
                                redirectPath="/tasks"
                            >
                                <BrandkitDetailsPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/teams"
                        element={
                            <PrivateRoute isLoggedIn={isLoggedIn} permitted={seeTeamsPermitted} redirectPath="/tasks">
                                <TeamPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/services"
                        element={
                            <PrivateRoute
                                isLoggedIn={isLoggedIn}
                                permitted={seeServicesPermitted}
                                redirectPath="/tasks"
                            >
                                <ServicesPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/ui-kit"
                        element={
                            <PrivateRoute>
                                <UiKitPage />
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path="/layout"
                        element={
                            <PrivateRoute>
                                <GridLayoutPage />
                            </PrivateRoute>
                        }
                    />
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}

export default App;
