import { initialState } from '@store/root-reducer';
import { CommentsActions } from './comments.actions';
import { IActionModel } from '@models/IActionModel';
import { ICommentModel } from '@models/ICommentModel';

export const commentsReducer = (state: ICommentModel[] = initialState.comments, action: IActionModel) => {
    switch (action.type) {
        case CommentsActions.SET_COMMENTS: {
            return [...action.payload.data];
        }
        case CommentsActions.ADD_COMMENTS: {
            const comments: ICommentModel[] = [...state];

            comments.push(action.payload.data);

            return comments;
        }
        case CommentsActions.UPDATE_COMMENTS: {
            const comments: ICommentModel[] = [...state];

            const index = comments.findIndex(task => task.id === action.payload.data.id);

            comments[index] = action.payload.data;

            return comments;
        }
        case CommentsActions.DELETE_COMMENTS: {
            const comments: ICommentModel[] = [...state];

            const newComments = comments.filter(comment => comment.id !== action.payload.data.id);

            const sortedComments = newComments.sort((a: ICommentModel, b: ICommentModel) => {
                return a.commented_on! - b.commented_on!;
            });

            return sortedComments;
        }
        default:
            return state;
    }
};
export default commentsReducer;
