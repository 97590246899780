import { IActionModel } from '@models/IActionModel';
import { initialState } from '@store/root-reducer';
import { MadgicxActions } from './madgicx.action';

export const madgicxReducer = (state: string | null = initialState.madgicx_task_id, action: IActionModel) => {
    switch (action.type) {
        case MadgicxActions.SET_MADGICX_TASK_ID: {
            return action.payload.data;
        }
        case MadgicxActions.CLEAR_MADGICX_TASK_ID: {
            return null;
        }
        default:
            return state;
    }
};
export default madgicxReducer;
