import { useCallback, useState } from 'react';
import _ from 'lodash';
import { IFileRequestModel } from '@models/IFileModel';

export const useDrop = (singleFile = false) => {
    const [files, setFiles] = useState<IFileRequestModel[]>([]);

    const uniqueName = (name: string) => {
        const file_name = name?.split('.')[0];

        const ext = name?.split('.')[1];
        const uid = Math.floor(1000 + Math.random() * 9000);

        return `${file_name}_${uid}.${ext}`;
    };

    const onDrop = useCallback(acceptedFiles => {
        _.forEach(acceptedFiles, file => {
            const reader = new FileReader();

            const file_name = uniqueName(file.name);

            const file_size_in_bytes = file.size;
            const last_modified = file.lastModified;

            reader.onload = () => {
                const file_url = reader.result as string;

                if (singleFile) {
                    setFiles([
                        {
                            file,
                            file_name,
                            file_url,
                            file_size_in_bytes,
                            last_modified,
                        },
                    ]);
                    return;
                }
                setFiles(previousFiles => [
                    ...previousFiles,
                    {
                        file,
                        file_name,
                        file_url,
                        file_size_in_bytes,
                        last_modified,
                    },
                ]);
            };
            reader.readAsDataURL(file);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onPaste = useCallback((e: any) => {
        const items = e.clipboardData?.items;

        const item = items?.[0];
        const file = item?.getAsFile();

        if (file) {
            const reader = new FileReader();

            const file_name = uniqueName(file.name);

            const file_size_in_bytes = file.size;
            const last_modified = file.lastModified;

            reader.onload = () => {
                const file_url = reader.result as string;

                if (file) {
                    setFiles(previousFiles => [
                        ...previousFiles,
                        {
                            file,
                            file_name,
                            file_url,
                            file_size_in_bytes,
                            last_modified,
                        },
                    ]);
                }
            };

            reader.readAsDataURL(file!);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const removeFile = (toDelete: IFileRequestModel) => {
        setFiles(files => {
            return files.filter(f => f.file_name !== toDelete.file_name);
        });
    };

    const clearFiles = () => {
        setFiles([]);
    };

    return {
        files,
        onDrop,
        onPaste,
        removeFile,
        clearFiles,
    };
};
