import { ApiService, IRequestState } from '@api/api';
import { IBrandkitModel } from '@models/IBrandkitModel';

export class BrandkitApi extends ApiService {
    createBrandkit = async (clientTeamId: number, data: IBrandkitModel): Promise<IRequestState<IBrandkitModel>> => {
        return this.$post({
            url: `teams/client/${clientTeamId}/brand_kits/`,
            data,
        });
    };

    getClientBrandkit = async (clientTeamId: number): Promise<IRequestState<IBrandkitModel[]>> => {
        return this.$get({
            url: `teams/client/${clientTeamId}/brand_kits/`,
        });
    };

    getDesignerBrandkit = async (): Promise<IRequestState<IBrandkitModel[]>> => {
        return this.$get({
            url: `brand_kits/`,
        });
    };

    getBrandkit = async (brandkitId: number): Promise<IRequestState<IBrandkitModel>> => {
        return this.$get({
            url: `brand_kits/${brandkitId}/`,
        });
    };

    updateBrandkit = async (brandkitId: number, data: IBrandkitModel): Promise<IRequestState<IBrandkitModel>> => {
        return this.$put({
            url: `brand_kits/${brandkitId}/`,
            data,
        });
    };
}

/* Sample Usage:

    import brandkitApi from 'api/BrandkitApi'; 

    const brandkitResponse = await brandkitApi.createBrandkit(clientTeamId, data);
*/

export const brandkitApi = new BrandkitApi();
