import { HTMLAttributes, memo, useMemo } from 'react';
import { SxProps } from '@mui/material';
import { OutlineTeamSquare } from '@assets/icons';
import { FlexCenter } from '@components/wrappers/Flex/Flex.wrapper';
import Tooltip from '@components/wrappers/Tooltip/Tooltip.wrapper';
import { Sizes } from '@data/types';
import { IconSize, getIconSize } from '@style/getIconSize';
import { DarkGrey } from '@style/theme';

export interface AvatarProps extends HTMLAttributes<HTMLDivElement> {
    src: string | React.ReactNode;
    round?: boolean;
    size?: Sizes;
    tooltip?: string;
    fill?: string;
    fallbackIcon?: React.ReactNode;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    muiStyle?: SxProps;
}

const style = (size: Sizes, round: boolean) => {
    return {
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            boxSizing: 'border-box',
            ...getIconSize(size),
            ...(round && { borderRadius: '50%' }),
        },
    };
};

export default memo(function Avatar({
    src,
    round = true,
    size = 'sm',
    tooltip,
    fill = DarkGrey.MEDIUM,
    fallbackIcon = <OutlineTeamSquare size={IconSize[size]} fill={fill} />,
    onClick,
    muiStyle,
}: AvatarProps) {
    const styles = useMemo(() => ({ ...style(size, round).root, ...muiStyle } as SxProps), [size, round, muiStyle]);
    return (
        <Tooltip tooltip={tooltip} placement="top" arrow={true}>
            <FlexCenter onClick={onClick} sx={{ ...styles }}>
                {typeof src === 'string' ? (
                    <img src={src} alt={src} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                ) : (
                    fallbackIcon
                )}
            </FlexCenter>
        </Tooltip>
    );
});
