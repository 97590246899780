import { ButtonVariants } from '@data/types';
import { Border, DarkGrey, LightGrey, Primary, WHITE } from './theme';

export const buttonVariant = (variant: ButtonVariants) => {
    switch (variant) {
        case 'primary':
            return {
                color: WHITE,
                backgroundColor: Primary.DARK,
                '& svg path': {
                    fill: WHITE,
                },
                '&:hover': {
                    backgroundColor: Primary.MEDIUM,
                },
                '&:disabled': {
                    color: WHITE,
                    backgroundColor: Primary.LIGHT,
                },
            };
        case 'flat':
            return {
                color: DarkGrey.DARK,
                backgroundColor: LightGrey.MEDIUM,
                '& svg path': {
                    fill: DarkGrey.DARK,
                },
                '&:hover': {
                    backgroundColor: LightGrey.LIGHT,
                },
                '&:disabled': {
                    color: DarkGrey.LIGHT,
                    '& svg path': {
                        fill: DarkGrey.LIGHT,
                    },
                },
            };
        case 'stroke':
            return {
                color: DarkGrey.DARK,
                backgroundColor: WHITE,
                border: `1px solid ${Border.MEDIUM}`,
                '& svg path': {
                    fill: DarkGrey.DARK,
                },
                '&:hover': {
                    color: DarkGrey.MEDIUM,
                    borderColor: LightGrey.DARK,
                    backgroundColor: WHITE,
                    '& svg path': {
                        fill: DarkGrey.MEDIUM,
                    },
                },
                '&:disabled': {
                    color: LightGrey.DARK,
                    borderColor: LightGrey.DARK,
                    backgroundColor: WHITE,
                    '& svg path': {
                        fill: LightGrey.DARK,
                    },
                },
            };
        default:
            return {};
    }
};
