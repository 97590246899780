import { fileApi } from '@api/modules/apiFile';
import { taskApi } from '@api/modules/apiTask';
import getFormData from '@helper/getFormData';
import { isInIframe } from '@helper/isInIframe';
import { IFileRequestModel, IFileResponseModel } from '@models/IFileModel';
import { ITaskModel, ITaskFilters, ITaskRequest, ITask } from '@models/ITaskModel';
import { setMadgicxTaskId } from '@store/madgicx_task_id/madgicx.action';
import { setSelectedTask } from '@store/selected-task/selected-task.action';
import { sendMessageToMadgicx } from '@utils/sendMessage';

export enum TaskActions {
    // Task Actions
    SET_TASKS = 'SET_TASKS',
    CLEAR_TASKS = 'CLEAR_TASKS',
    ADD_TASK = 'ADD_TASK',
    UPDATE_TASKS = 'UPDATE_TASKS',
    DELETE_TASK = 'DELETE_TASK',
    UPDATE_TASK_STATUS = 'UPDATE_TASK_STATUS',
    UPDATE_TASK_FILES = 'UPDATE_TASK_FILES',
    MARK_TASK_AS_READ = 'MARK_TASK_AS_READ',
    UPDATE_TASK_COMMENT_COUNT = 'UPDATE_TASK_COMMENT_COUNT',
    SHOULD_NOTIFY = 'SHOULD_NOTIFY',
    // Filter Actions
    SET_TASK_FILTERS = 'SET_TASK_FILTERS',
    CLEAR_TASK_FILTERS = 'CLEAR_TASK_FILTERS',
    CLEAR_ALL_TASK_FILTERS = 'CLEAR_ALL_TASK_FILTERS',
}

// Task Actions
export const setTasks = (dispatch: any, tasks: ITaskModel) => {
    dispatch({
        type: TaskActions.SET_TASKS,
        payload: { data: tasks },
    });
};

export const clearTasks = (dispatch: any) => {
    dispatch({ type: TaskActions.CLEAR_TASKS });
};

export const upsertTask = async (
    dispatch: any,
    mode: 'add' | 'edit',
    // taskId: number,
    request: ITaskRequest | ITask,
    files: IFileRequestModel[],
    navigate: any,
): Promise<boolean> => {
    const taskResponse =
        mode === 'add'
            ? await taskApi.createTask({ ...request })
            : await taskApi.updateTask((request as ITask).id, { ...request });

    if (taskResponse?.data) {
        const { uploadFiles } = getFormData(files);

        await fileApi.attachToTask(taskResponse.data.id!, uploadFiles);

        dispatch({
            type: mode === 'add' ? TaskActions.ADD_TASK : TaskActions.UPDATE_TASKS,
            payload: { data: taskResponse.data },
        });

        const taskId = mode === 'add' ? taskResponse.data.id : (request as ITask).id;

        if (isInIframe()) {
            setMadgicxTaskId(dispatch, taskId);
            sendMessageToMadgicx('SET_TASK_ID', { task_id: taskId });
        }

        setSelectedTask(dispatch, navigate, taskResponse.data);

        return true;
    }

    return false;
};

export const updateTaskFiles = (dispatch: any, taskId: number, files: IFileResponseModel[]) => {
    dispatch({
        type: TaskActions.UPDATE_TASK_FILES,
        payload: {
            data: {
                id: taskId,
                files: files,
            },
        },
    });
};

export const shouldMarkTaskAsRead = (dispatch: any, task: ITask) => {
    dispatch({ type: TaskActions.MARK_TASK_AS_READ, payload: { data: { ...task } } });
};

export const shouldNotifyOnStatusChange = (dispatch: any) => {
    dispatch({ type: TaskActions.SHOULD_NOTIFY });
};

export const shouldUpdateCommentCount = (dispatch: any, task: ITask) => {
    dispatch({ type: TaskActions.UPDATE_TASK_COMMENT_COUNT, payload: { data: { ...task } } });
};

// Filter Actions
export const setTaskFilter = (dispatch: any, filters: ITaskFilters) => {
    dispatch({ type: TaskActions.SET_TASK_FILTERS, payload: { data: filters } });
};

export const clearTaskFilters = (dispatch: any) => dispatch({ type: TaskActions.CLEAR_TASK_FILTERS });
export const clearAllTaskFilters = (dispatch: any) => dispatch({ type: TaskActions.CLEAR_ALL_TASK_FILTERS });
