import React, { memo, useMemo } from 'react';
import { SxProps, Typography, TypographyProps } from '@mui/material';
import { FontWeight } from '@style/theme';
import { FontVariants, TextAlignments } from '@data/types';

export interface CustomTypographyProps extends TypographyProps {
    ref?: React.RefObject<HTMLDivElement>;
    children: string | React.ReactNode;
    variant?: FontVariants;
    weight?: FontWeight;
    alignment?: TextAlignments;
    color?: string;
    prewrap?: boolean;
    onClick?: () => void;
    muiStyle?: SxProps;
}

const style = {
    root: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
};

export default memo(
    React.forwardRef<HTMLDivElement, CustomTypographyProps>(function AppTypography(
        {
            children,
            variant = 'body1',
            weight = FontWeight.REGULAR,
            alignment = 'inherit',
            color,
            prewrap,
            onClick,
            muiStyle,
        },
        ref,
    ) {
        const styles = useMemo(() => ({ ...style.root, ...muiStyle } as SxProps), [muiStyle]);
        const wrap = useMemo(() => (prewrap ? 'pre-wrap' : 'nowrap'), [prewrap]);
        const pointer = useMemo(() => (onClick ? 'pointer' : 'inherit'), [onClick]);

        return (
            <Typography
                ref={ref}
                variant={variant}
                fontWeight={weight}
                align={alignment}
                whiteSpace={wrap}
                onClick={onClick}
                sx={{ color: color, cursor: pointer, ...styles }}
            >
                {children}
            </Typography>
        );
    }),
);
