import { OutlineFolder, OutlineMenuAdd, OutlineSwatchesPallete, OutlineUserGroup } from '@assets/icons';

/**
 * In-App Navigation Items
 */

export const NAVIGATION_ITEMS: string[] = ['Request', 'Brand kits', 'Services', 'Teams', 'Design', 'Analytics'];

export const APP_NAVIGATION: {
    label: string;
    permission: string;
    startIcon?: React.ReactNode;
    dropdown?: boolean;
    options?: any[];
    selected?: boolean;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    path?: string;
    addButtonLabel?: string;
    addButtonPermission?: string;
    onAddPath?: string;
}[] = [
    {
        label: 'Request',
        permission: 'can_see_tasks',
        startIcon: <OutlineFolder />,
        selected: false,
        path: '/tasks',
        onClick: () => {},
    },
    {
        label: 'Brand kits',
        permission: 'can_see_brandkits',
        startIcon: <OutlineSwatchesPallete />,
        dropdown: true,
        selected: false,
        options: [],
        onClick: () => {},
        addButtonLabel: 'Add Brand Kit',
        addButtonPermission: 'can_add_brandkits',
        onAddPath: '/brandkit',
    },
    {
        label: 'Team',
        permission: 'can_see_teams',
        startIcon: <OutlineUserGroup />,
        selected: false,
        path: '/teams',
        onClick: () => {},
    },
    {
        label: 'Services',
        permission: 'can_see_services',
        startIcon: <OutlineMenuAdd />,
        selected: false,
        path: '/services',
        onClick: () => {},
    },
];
