import { usePostMessages } from '@hooks/useIframeMessages';
import { useFullStory } from '@hooks/useFullStory';

export const IntegrationProvider = ({ children }) => {
    usePostMessages();
    useFullStory();

    return <>{children}</>;
};

export default IntegrationProvider;
