import { initialState } from '@store/root-reducer';
import { TeamsActions } from './teams.actions';
import { ITeamResponse } from '@models/ITeamModel';
import { IActionModel } from '@models/IActionModel';

export const teamsReducer = (state: ITeamResponse = initialState.teams, action: IActionModel) => {
    switch (action.type) {
        case TeamsActions.SET_TEAMS: {
            return { ...action.payload.data };
        }
        case TeamsActions.ADD_TEAMS: {
            const teams: ITeamResponse = { ...state };

            teams.results.push(action.payload.data);

            return teams;
        }
        case TeamsActions.UPDATE_TEAMS: {
            const teams: ITeamResponse = { ...state };

            const index = teams.results.findIndex(teams => teams.id === action.payload.data.id);

            teams[index] = action.payload.data;

            return teams;
        }
        case TeamsActions.DELETE_TEAMS: {
            return { ...state, ...action.payload.data };
        }
        default:
            return state;
    }
};
export default teamsReducer;
