import { IconProps } from '.';

export function ChevronUp({ fill = '#0D182F', size = 20, muiStyle }: IconProps) {
    return (
        <svg width={size} height={size} style={{ ...muiStyle }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path
                fill={fill}
                d="M14.584 7.585c0.362-0.362 0.863-0.586 1.416-0.586s1.054 0.224 1.416 0.587l14.022 14.022c0.348 0.36 0.562 0.851 0.562 1.392 0 1.106-0.897 2.003-2.003 2.003-0.541 0-1.032-0.214-1.392-0.563l-12.605-12.605-12.605 12.605c-0.36 0.348-0.851 0.562-1.392 0.562-1.106 0-2.003-0.897-2.003-2.003 0-0.541 0.214-1.032 0.563-1.392l14.021-14.021z"
            ></path>
        </svg>
    );
}

export function ChevronDown({ fill = '#0D182F', size = 20, muiStyle }: IconProps) {
    return (
        <svg width={size} height={size} style={{ ...muiStyle }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path
                fill={fill}
                d="M0.585 7.605c0.362-0.362 0.861-0.585 1.413-0.585s1.051 0.224 1.413 0.585l12.576 12.576 12.576-12.576c0.364-0.376 0.873-0.61 1.437-0.61 1.104 0 1.998 0.895 1.998 1.998 0 0.564-0.234 1.074-0.61 1.437l-13.99 13.99c-0.362 0.362-0.861 0.585-1.413 0.585s-1.051-0.224-1.413-0.585l-13.989-13.989c-0.362-0.362-0.585-0.861-0.585-1.413s0.224-1.051 0.585-1.413v0z"
            ></path>
        </svg>
    );
}

export function ChevronLeft({ fill = '#0D182F', size = 20, muiStyle }: IconProps) {
    return (
        <svg width={size} height={size} style={{ ...muiStyle }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path
                fill={fill}
                d="M24.421 0.586c0.362 0.362 0.586 0.863 0.586 1.415s-0.224 1.053-0.586 1.415l-12.596 12.596 12.596 12.596c0.348 0.36 0.562 0.85 0.562 1.39 0 1.105-0.896 2.002-2.002 2.002-0.54 0-1.031-0.214-1.391-0.562l-14.010-14.010c-0.362-0.362-0.586-0.863-0.586-1.415s0.224-1.053 0.586-1.415l14.011-14.011c0.362-0.362 0.863-0.586 1.415-0.586s1.053 0.224 1.415 0.586v0z"
            ></path>
        </svg>
    );
}

export function ChevronRight({ fill = '#0D182F', size = 24, muiStyle }: IconProps) {
    return (
        <svg width={size} height={size} style={{ ...muiStyle }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path
                fill={fill}
                d="M7.579 0.586c0.362-0.362 0.863-0.586 1.415-0.586s1.053 0.224 1.415 0.586l14.011 14.011c0.362 0.362 0.586 0.863 0.586 1.415s-0.224 1.053-0.586 1.415l-14.011 14.011c-0.36 0.348-0.85 0.562-1.391 0.562-1.105 0-2.002-0.896-2.002-2.002 0-0.54 0.214-1.031 0.562-1.391l-0.001 0.001 12.596-12.596-12.596-12.596c-0.362-0.362-0.586-0.862-0.586-1.415s0.224-1.053 0.586-1.415v0z"
            ></path>
        </svg>
    );
}

export function TriangleUp({ fill = '#0D182F', size = 24, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...muiStyle }}
        >
            <path
                d="M13.0603 7.93934C12.4745 7.35355 11.5248 7.35355 10.939 7.93934L6.43901 12.4393C6.01002 12.8683 5.88169 13.5135 6.11386 14.074C6.34603 14.6345 6.89298 14.9999 7.49967 14.9999L16.4996 14.9999C17.1063 14.9999 17.6532 14.6345 17.8854 14.074C18.1176 13.5135 17.9892 12.8683 17.5603 12.4393L13.0603 7.93934Z"
                fill={fill}
            />
        </svg>
    );
}

export function TriangleDown({ fill = '#0D182F', size = 24, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...muiStyle }}
        >
            <path
                d="M7.49979 8.5C6.8931 8.5 6.34615 8.86546 6.11398 9.42597C5.88181 9.98648 6.01014 10.6316 6.43914 11.0606L10.9391 15.5606C11.2204 15.8419 11.6019 15.9999 11.9998 15.9999C12.3976 15.9999 12.7791 15.8419 13.0604 15.5606L17.5604 11.0606C17.9894 10.6316 18.1177 9.98648 17.8855 9.42597C17.6534 8.86546 17.1064 8.5 16.4997 8.5L7.49979 8.5Z"
                fill={fill}
            />
        </svg>
    );
}

export function TriangleLeft({ fill = '#0D182F', size = 24, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...muiStyle }}
        >
            <path
                d="M15.0001 7.50003C15.0001 6.89334 14.6347 6.34639 14.0742 6.11422C13.5137 5.88205 12.8685 6.01039 12.4395 6.43938L7.93952 10.9393C7.35374 11.5251 7.35374 12.4749 7.93952 13.0607L12.4395 17.5606C12.8685 17.9896 13.5137 18.1179 14.0742 17.8858C14.6347 17.6536 15.0001 17.1067 15.0001 16.5V7.50003Z"
                fill={fill}
            />
        </svg>
    );
}

export function TriangleRight({ fill = '#0D182F', size = 24, muiStyle }: IconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ ...muiStyle }}
        >
            <path
                d="M11.0608 6.43938C10.6318 6.01039 9.98666 5.88205 9.42615 6.11422C8.86564 6.34639 8.50018 6.89334 8.50018 7.50003L8.50018 16.5C8.50018 17.1067 8.86564 17.6536 9.42615 17.8858C9.98666 18.1179 10.6318 17.9896 11.0608 17.5606L15.5608 13.0607C16.1466 12.4749 16.1466 11.5251 15.5608 10.9393L11.0608 6.43938Z"
                fill={fill}
            />
        </svg>
    );
}

export function OutlineReload({ fill = '#0D182F', size = 24, muiStyle }: IconProps) {
    return (
        <svg width={size} height={size} style={{ ...muiStyle }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path
                fill={fill}
                d="M27.202 0c0.884 0 1.6 0.716 1.6 1.6v0 8c0 0.884-0.716 1.6-1.6 1.6v0h-8c-0.884 0-1.6-0.716-1.6-1.6s0.716-1.6 1.6-1.6v0h4.638c-2.018-1.98-4.786-3.202-7.84-3.202-4.674 0-8.679 2.864-10.357 6.932l-0.027 0.074c-0.241 0.597-0.816 1.010-1.487 1.010-0.884 0-1.6-0.716-1.6-1.6 0-0.22 0.044-0.43 0.125-0.621l-0.004 0.011c2.194-5.324 7.343-9.004 13.351-9.004 3.695 0 7.065 1.392 9.615 3.68l-0.013-0.012v-3.669c0-0.884 0.716-1.6 1.6-1.6v0zM28.47 19.312c0.592 0.244 1.001 0.816 1.001 1.484 0 0.215-0.043 0.421-0.12 0.609l0.004-0.011c-2.193 5.326-7.343 9.006-13.352 9.006-3.695 0-7.064-1.392-9.614-3.679l0.013 0.012v3.667c0 0.884-0.716 1.6-1.6 1.6s-1.6-0.716-1.6-1.6v0-8c0-0.884 0.716-1.6 1.6-1.6v0h8c0.884 0 1.6 0.716 1.6 1.6s-0.716 1.6-1.6 1.6v0h-4.638c2.018 1.98 4.786 3.202 7.839 3.202 2.478 0 4.768-0.805 6.623-2.168l-0.031 0.022c1.675-1.229 2.977-2.875 3.765-4.786l0.027-0.074c0.244-0.591 0.816-1 1.483-1 0.216 0 0.422 0.043 0.611 0.121l-0.011-0.004z"
            ></path>
        </svg>
    );
}

export function OutlineUndoUpright({ fill = '#0D182F', size = 24, muiStyle }: IconProps) {
    return (
        <svg width={size} height={size} style={{ ...muiStyle }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path
                fill={fill}
                d="M22.869 3.668c0.29-0.289 0.689-0.468 1.131-0.468s0.842 0.179 1.131 0.468l6.4 6.4c0.289 0.29 0.468 0.689 0.468 1.131s-0.179 0.842-0.468 1.131l-6.4 6.4c-0.287 0.278-0.679 0.449-1.112 0.449-0.884 0-1.6-0.716-1.6-1.6 0-0.432 0.171-0.824 0.45-1.112l3.668-3.668h-16.938c-3.535 0-6.4 2.865-6.4 6.4s2.865 6.4 6.4 6.4v0h8c0.884 0 1.6 0.716 1.6 1.6s-0.716 1.6-1.6 1.6v0h-8c-5.302 0-9.6-4.298-9.6-9.6s4.298-9.6 9.6-9.6v0h16.938l-3.669-3.669c-0.289-0.29-0.468-0.689-0.468-1.131s0.179-0.842 0.468-1.131v0z"
            ></path>
        </svg>
    );
}
