import { ChangeEvent, memo } from 'react';
import { InputBase, SxProps } from '@mui/material';
import { Border, BorderRadius, Spacing } from '@style/theme';
import { FlexDirections } from '@data/types';
import Label from '@components/wrappers/Label/Label.wrapper';
import { FlexColumn } from '@components/wrappers/Flex/Flex.wrapper';

export interface TextAreaProps {
    label?: string;
    value?: string;
    name?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    startIcon?: React.ReactNode;
    placeholder?: string;
    width?: number | string;
    height?: number;
    alignment?: FlexDirections;
    disabled?: boolean;
    required?: boolean;
    prewrap?: boolean;
    muiStyle?: SxProps;
}

const style = {
    root: {},
    input: {
        minHeight: 100,
        overflow: 'hidden',
        overflowY: 'auto',
        whiteSpace: 'pre-wrap',
    },
    inputWrapper: {
        padding: Spacing.MD,
        borderRadius: BorderRadius.XS,
        border: `1px solid ${Border.MEDIUM}`,
    },
};

export default memo(function TextArea({
    label,
    value,
    name,
    onChange,
    startIcon,
    placeholder = 'Input Placeholder',
    width = 290,
    height = 320,
    alignment = 'column',
    disabled = false,
    required = false,
    prewrap,
    muiStyle,
}: TextAreaProps) {
    return (
        <FlexColumn>
            <Label label={label} width={width} alignment={alignment} required={required} prewrap={prewrap}>
                <InputBase
                    value={value}
                    name={name}
                    onChange={onChange}
                    placeholder={placeholder}
                    disabled={disabled}
                    multiline={true}
                    required={required}
                    startAdornment={startIcon}
                    inputProps={{
                        style: {
                            height: height,
                            maxHeight: height,
                            ...style.input,
                        } as React.CSSProperties,
                    }}
                    sx={{ width: width, ...style.inputWrapper, ...muiStyle }}
                />
            </Label>
        </FlexColumn>
    );
});
