import { memo, useEffect, useMemo, useState } from 'react';
import { SearchInput, Typography } from '@components/index';
import { Spacing, BorderRadius, LightGrey, FontWeight } from '@style/theme';
import { AlignCenter, SpaceBetween } from '@components/wrappers/Flex/Flex.wrapper';
import { IOption } from '@data/interfaces';
import Avatar from '@components/user/Avatar/Avatar';
import { Box, SxProps } from '@mui/material';

const style = {
    root: { display: 'flex', flexDirection: 'column' },
    search: { p: Spacing.MD },
    option: {
        alignItems: 'center',
        padding: Spacing.SM,
        borderRadius: BorderRadius.XS,
        cursor: 'pointer',
        boxSizing: 'border-box',
        overflow: 'hidden',
        '&:hover, &.selected': {
            background: LightGrey.LIGHT,
        },
    },
};

export interface SingleSelectListProps {
    value?: IOption;
    options?: IOption[];
    optionsRender?: (props: OptionProps) => React.ReactNode;
    placeholder?: string;
    width?: number | string;
    height?: number;
    gap?: number;
    onChange?: (value: any) => void;
    onClick?: (value: string) => void;
    isSearchable?: boolean;
    isLoading?: boolean;
    required?: boolean;
    muiStyle?: SxProps;
}
export interface OptionProps {
    value?: IOption;
    options: IOption[];
    onOptionsClick?: (e: any) => void;
}

const DefaultOptionRenderer = ({ value, options, onOptionsClick }: OptionProps) =>
    options?.map((option, i) => {
        return (
            <SpaceBetween
                key={option.value + '-' + i}
                onClick={() => onOptionsClick?.(option)}
                sx={{ ...style.option }}
            >
                <AlignCenter gap={Spacing.SM}>
                    {option.img && <Avatar src={option.img} round={true} size="md" />}
                    <Typography weight={FontWeight.SEMIBOLD}>&#8288;{option.label}</Typography>
                </AlignCenter>
            </SpaceBetween>
        );
    });

export default memo(function SingleSelectList({
    value,
    options = [],
    optionsRender = DefaultOptionRenderer,
    placeholder = 'Select Option',
    width,
    height,
    gap = Spacing.MD,
    onChange = () => {},
    onClick = () => {},
    isSearchable = false,
    isLoading = false,
    required = false,
    muiStyle,
}: SingleSelectListProps) {
    const styles = useMemo(() => ({ ...style.root, ...muiStyle } as SxProps), [muiStyle]);

    const [searchResults, setSearchResults] = useState<IOption[]>([]);

    const onOptionsClick = (option: IOption) => onChange(option);

    useEffect(() => setSearchResults(previousOptions => options), [options]);

    return (
        <Box height={height} width={width} gap={gap} sx={{ ...styles }}>
            {isSearchable && (
                <div onClick={e => e.stopPropagation()}>
                    <SearchInput
                        searchableItems={options}
                        searchFilter={['label']}
                        setSearchResults={setSearchResults}
                        placeholder={placeholder}
                        muiStyle={{ ...style.search }}
                    />
                </div>
            )}

            {optionsRender({ value, options: isSearchable ? searchResults : options, onOptionsClick })}
        </Box>
    );
});
